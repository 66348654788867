define('hc2-ui/helpers/share-url', ['exports', 'hc2-ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var protocol = _environment.default.hc2.protocol;
    var domain = params[0];
    var baseShareUrl = protocol + "://" + domain;
    if (params[1]) {
      baseShareUrl += "/?data-contentId=" + params[1];
    }
    return baseShareUrl;
  });
});