define('hc2-ui/components/apps/custom-contact-form-fields', ['exports', 'hc2-ui/lib/make/init', 'hc2-ui/helpers/input-pattern'], function (exports, _init, _inputPattern) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    i18n: Ember.inject.service(),
    didRender: function didRender() {
      _init.default.material();
    },
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get("prefillFormData")) {
        this.set("prefillFormData", {});
      }
    },

    actions: {
      checkInputForRegex: function checkInputForRegex(regex, event) {
        return (0, _inputPattern.isAllowedChar)(regex, event.key);
      }
    },
    translatedExtraFormElements: Ember.computed("appData.contactData.extraFromElements", "appData.contactData.extraFromElements.@each", "appData.contactData.extraFromElements.@each.type", "appData.contactData.extraFromElements.@each.label", "appData.contactData.extraFromElements.@each.answers", "i18n.appLocale", function () {
      var _this = this;

      var elements = [];
      var extraFormElements = this.get("appData.contactData.extraFromElements");
      if (extraFormElements) {
        extraFormElements.forEach(function (element, elIndex) {
          var clonedElement = Ember.$.extend(true, {}, element);
          clonedElement.answers.forEach(function (answer, anIndex) {
            var translationKey = _this.get("i18n.appLocale") + "_appdata_contactData_extraFromElements_" + elIndex + "_answers_" + anIndex + "_label";
            if (_this.get("appData.translations")) {
              var translation = _this.get("appData.translations")[translationKey];
              if (translation) {
                answer.label = translation;
              }
            }
          });
          elements.pushObject(clonedElement);
        });
      }
      return elements;
    })
  });
});