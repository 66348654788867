define('hc2-ui/components/-el/pagination', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    actions: {
      setPage: function setPage(page) {
        if (page === 'prev') {
          page = this.get("page") - 1;
        }
        if (page === 'next') {
          page = this.get("page") + 1;
        }
        this.set("page", page);
      }
    },
    totalPages: Ember.computed("size", "page", "totalElements", function () {
      var pages = this.get("totalElements") / this.get("size");
      return Math.ceil(pages);
    }),
    pages: Ember.computed('totalPages', 'page', function () {
      var maxPaginationLinks = 9;
      var pages = [];

      var startPage = this.get('page') - (maxPaginationLinks - 1) / 2;
      if (startPage < 1) {
        startPage = 1;
      }
      for (var i = startPage; pages.length < maxPaginationLinks && i <= this.get('totalPages'); i++) {
        pages.push({ number: i });
      }
      return pages;
    }),
    currentFrom: Ember.computed('page', 'entriesPerPage', function () {
      return 1 + this.get('entriesPerPage') * (this.get('page') - 1);
    }),
    currentTill: Ember.computed('currentFrom', function () {
      return Math.min(this.get('entriesPerPage') * this.get('page'), this.get('totalEntries'));
    })
  });
});