define("hc2-ui/templates/components/apps/calendar/door-number", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 79
          }
        },
        "moduleName": "hc2-ui/templates/components/apps/calendar/door-number.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["content", "number", ["loc", [null, [1, 0], [1, 10]]], 0, 0, 0, 0], ["inline", "resize-detector", [["subexpr", "concat", [".", ["get", "doorNumberNumber", ["loc", [null, [2, 30], [2, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 18], [2, 47]]], 0, 0]], ["on-resize", ["subexpr", "action", ["onResize"], [], ["loc", [null, [2, 58], [2, 77]]], 0, 0]], ["loc", [null, [2, 0], [2, 79]]], 0, 0]],
      locals: [],
      templates: []
    };
  }());
});