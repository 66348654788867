define('hc2-ui/components/backend/side-bar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    session: service('session'),

    availableApps: Ember.computed('platform.availableApps', function () {
      var availableAppsString = this.get('platform.availableApps');

      return availableAppsString ? availableAppsString.split(',') : null;
    }),
    createApp: 'createApp',
    actions: {
      createApp: function createApp(appType) {
        this.sendAction("createApp", appType);
      }
    }
  });
});