define("hc2-ui/templates/components/apps/copy-from-apps-selection", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 2
            },
            "end": {
              "line": 17,
              "column": 2
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/copy-from-apps-selection.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col-sm-12 text-success");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["copy.copiedFromAppId"], ["appName", ["subexpr", "@mut", [["get", "copiedFromAppName", ["loc", [null, [16, 73], [16, 90]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [16, 38], [16, 92]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 6
          }
        },
        "moduleName": "hc2-ui/templates/components/apps/copy-from-apps-selection.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode(":\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-sm-6");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-sm-4");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        var el4 = dom.createElement("i");
        dom.setAttribute(el4, "class", "glyphicon glyphicon-duplicate");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(element0, [3, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
        morphs[2] = dom.createAttrMorph(element1, 'class');
        morphs[3] = dom.createAttrMorph(element1, 'onclick');
        morphs[4] = dom.createMorphAt(element1, 2, 2);
        morphs[5] = dom.createMorphAt(element0, 5, 5);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "t", ["copy.fromAppSelection"], [], ["loc", [null, [1, 0], [1, 29]]], 0, 0], ["inline", "select-2", [], ["content", ["subexpr", "@mut", [["get", "appsSelection", ["loc", [null, [5, 14], [5, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "optionValuePath", "id", "optionLabelPath", "title", "optionDescriptionPath", "none", "value", ["subexpr", "@mut", [["get", "selectedAppId", ["loc", [null, [9, 12], [9, 25]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [4, 4], [10, 6]]], 0, 0], ["attribute", "class", ["concat", ["btn btn-primary ", ["subexpr", "unless", [["get", "selectedAppId", ["loc", [null, [13, 39], [13, 52]]], 0, 0, 0, 0], "disabled"], [], ["loc", [null, [13, 30], [13, 65]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["copyAppProperties"], [], ["loc", [null, [null, null], [13, 105]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["copy.now"], [], ["loc", [null, [13, 149], [13, 165]]], 0, 0], ["block", "if", [["get", "copiedFromAppName", ["loc", [null, [15, 8], [15, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [15, 2], [17, 9]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});