define("hc2-ui/components/apps/contest/participation-administration", ["exports", "hc2-ui/lib/make/init", "hc2-ui/helpers/camel-to-dash"], function (exports, _init, _camelToDash) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),

    appData: {},
    participations: [],
    loadingParticipations: false,
    allParticipationsLoaded: false,

    sortValue: "dateCreated",
    sortDirection: "desc",

    entriesPerPage: 10,
    page: 1,
    totalEntries: -1,
    totalPages: Ember.computed('totalEntries', function () {
      return Math.ceil(this.get('totalEntries') / this.get("entriesPerPage"));
    }),
    pages: Ember.computed('totalPages', 'page', function () {
      var maxPaginationLinks = 9;
      var pages = [];

      var startPage = this.get('page') - (maxPaginationLinks - 1) / 2;
      if (startPage < 1) {
        startPage = 1;
      }
      for (var i = startPage; pages.length < maxPaginationLinks && i <= this.get('totalPages'); i++) {
        pages.push({ number: i });
      }
      return pages;
    }),
    currentFrom: Ember.computed('page', 'entriesPerPage', function () {
      return 1 + this.get('entriesPerPage') * (this.get('page') - 1);
    }),
    currentTill: Ember.computed('currentFrom', function () {
      return Math.min(this.get('entriesPerPage') * this.get('page'), this.get('totalEntries'));
    }),
    selectedParticipation: null,
    selectedEditParticipation: null,

    showEditButton: Ember.computed('appData.overlayImageUri', 'appData.backlayImageUri', function () {
      if (this.get("appData.overlayImageUri")) {
        return false;
      }
      if (this.get("appData.backlayImageUri")) {
        return false;
      }
      return true;
    }),

    didRender: function didRender() {
      _init.default.material(this.elementId);
    },

    actions: {
      onSetSort: function onSetSort(sortValue) {
        this.set("page", 1);
        var currentSortValue = this.get('sortValue');
        if (currentSortValue === sortValue) {
          this.set("sortDirection", this.get('sortDirection') === 'desc' ? 'asc' : 'desc');
        } else {
          this.set("sortValue", sortValue);
        }
        this.loadParticipations();
      },
      toggleActivation: function toggleActivation(participation) {
        var action = this.get('store').createRecord("action", { action: "setContestParticipationActivation", context: { id: participation.get('id'), value: !participation.get('active') } });
        action.save().then(function (action) {
          participation.set("active", action.get("context.participation.active"));
        }, function () {
          alert("action failed");
        });
      },
      deleteParticipation: function deleteParticipation(participation) {
        var that = this;
        if (confirm(this.get('i18n').t('participation.delete.confirm'))) {
          participation.destroyRecord().then(function () {
            that.set('totalEntries', that.get('totalEntries') - 1);
            that.loadParticipations();
          });
        }
      },
      viewParticipation: function viewParticipation(participation) {
        var _this = this;

        window.$('#' + this.elementId + ' #participation-view').modal().on('hide.bs.modal', function () {
          _this.set("selectedParticipation", null);
        }).on('shown.bs.modal', function () {
          _this.set('selectedParticipation', participation);
        });
      },
      editParticipation: function editParticipation(participation) {
        var _this2 = this;

        window.$('#' + this.elementId + ' #participation-edit').modal().on('hide.bs.modal', function () {
          _this2.set("selectedEditParticipation", null);
        }).on('shown.bs.modal', function () {
          _this2.set('selectedEditParticipation', participation);
        });
      },
      setPage: function setPage(page) {
        var pageToSet = this.get('page');
        if (page === 'next') {
          pageToSet++;
        } else if (page === 'prev') {
          pageToSet--;
        } else {
          pageToSet = page;
        }
        this.set('page', pageToSet);
        this.loadParticipations();
      },
      closeEditModal: function closeEditModal() {
        window.$('#' + this.elementId + ' #participation-edit').modal('hide');
        this.set('selectedEditParticipation', null);
      }
    },

    loadParticipations: function () {
      if (this.get('totalEntries') === -1) {
        this.set('totalEntries', this.get('appData.totalParticipations'));
      }

      this.set('loadingParticipations', true);
      var that = this;
      var appId = this.get('appData.id');
      var maxPerLoad = this.get('entriesPerPage');
      var offset = this.get('page') * maxPerLoad - maxPerLoad;
      var sort = this.get("sortValue");
      var sortDirection = this.get("sortDirection");
      this.get('store').query(_camelToDash.default.compute([this.get('appData.appType')]) + '-participation', { offset: offset, max: maxPerLoad, sort: sort, order: sortDirection, appId: appId, includeInactive: true }).then(function (loadedParticipations) {
        var pa = loadedParticipations.toArray();
        that.set('participations', pa);
        that.set('loadingParticipations', false);
        if (pa.length < maxPerLoad) {
          that.set('allParticipationsLoaded', true);
        }
      });
    }.on("init")
  });
});