define("hc2-ui/components/apps/app-editor", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    defaults: Ember.inject.service(),
    i18n: Ember.inject.service(),

    appData: {},
    editorResources: {},
    appStates: {},
    editOrientation: "landscape",
    showMailsSettings: true,

    mode: Ember.computed('appData', function () {
      return this.get('appData.isNew') ? 'create' : 'edit';
    }),
    previewInEditMode: Ember.computed('isNew', function () {
      return !this.get('appData.isNew');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get("appData.config")) {
        this.set("appData.config", {});
      }
    },


    subAppModel: null,

    saveApp: "saveApp",
    actions: {
      save: function save(appData) {
        this.sendAction("saveApp", appData.appType, appData);
      },
      editSubApp: function editSubApp(index, initialAppData) {
        var _this = this;

        if (this.get("subAppModel")) {
          return this.set("subAppModel", null);
        }
        var childApps = this.get('appData.childApps');
        var childApp = childApps ? childApps.filterBy("childAppIndex", index) : Ember.A();

        if (childApp.length === 0) {

          if (!initialAppData.appType && !this.get("appData.allowSubAppSelection")) {
            var subAppTypes = this.get("appData.subAppTypes");
            if (subAppTypes && subAppTypes.get('firstObject')) {
              initialAppData.appType = subAppTypes.get('firstObject.value');
            } else {
              initialAppData.appType = "sweepstake";
            }
          }

          if (!initialAppData.appType) {
            initialAppData.childAppIndex = index;
            this.set("appToAdd", initialAppData);
            return;
          } else {
            var childAppModel = this.get("defaults").getDefaultAppEditorModel(initialAppData.appType).appData;
            childAppModel.setProperties(initialAppData);
            childAppModel.set('parentApp', this.get("appData"));
            childAppModel.set('childAppIndex', index);
            childApp.push(childAppModel);
            this.get('appData.childApps').pushObject(childAppModel);
          }
        }
        childApp.objectAt(0).set("parentApp", this.get("appData")); // we do not want to load it again

        if (!childApp.objectAt(0).get("translations")) {
          childApp.objectAt(0).set("translations", {});
        }
        window.$("body").addClass("subAppModal-open");
        this.set("subAppModelEditorLoad", false); // lazy load editor so we se popup faster
        this.set("subAppModel", childApp[0]);
        Ember.run.later(function () {
          _this.set("subAppModelEditorLoad", true);
        }, 50);
      },
      addAppWithType: function addAppWithType(appType) {
        var appToAdd = this.get("appToAdd");
        appToAdd.appType = appType;
        this.set("appToAdd", null);
        this.send("editSubApp", appToAdd.childAppIndex, appToAdd);
      },
      closeAppToAdd: function closeAppToAdd() {
        this.set("appToAdd", null);
      },

      closeSubApp: function closeSubApp() {
        this.set("subAppModel", null);
        window.$("body").removeClass("subAppModal-open");
      },
      saveSubApp: function saveSubApp(appType, appData) {
        var defer = Ember.RSVP.defer(),
            that = this;
        defer.promise.then(function () {
          that.set("subAppModel", null);
          window.$("body").removeClass("subAppModal-open");
        });

        this.sendAction("saveApp", appData.appType, appData, defer);
      },
      deleteSubApp: function deleteSubApp(appData) {
        var _this2 = this;

        if (window.confirm(this.get("i18n").t("editor.deleteAppWarning", { title: appData.get("title") }))) {
          appData.destroyRecord().then(function () {
            _this2.set("subAppModel", null);
            window.$("body").removeClass("subAppModal-open");
          });
        }
      },
      previewResized: function previewResized(sizes) {
        var previewSize = '';
        if (sizes.width < 768) {
          previewSize = 'xs';
        }
        this.set("previewSize", previewSize);
      },
      toggleEditOrientation: function toggleEditOrientation() {
        this.set("editOrientation", this.get("editOrientation") === "landscape" ? "portrait" : "landscape");
      }
    }
  });
});