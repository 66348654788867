define("hc2-ui/templates/components/-el/toggle-button", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 0
          }
        },
        "moduleName": "hc2-ui/templates/components/-el/toggle-button.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        var el2 = dom.createElement("i");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [0]);
        var element2 = dom.childAt(fragment, [2]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element0, 'title');
        morphs[2] = dom.createAttrMorph(element0, 'onclick');
        morphs[3] = dom.createAttrMorph(element1, 'class');
        morphs[4] = dom.createMorphAt(element0, 1, 1);
        morphs[5] = dom.createAttrMorph(element2, 'class');
        morphs[6] = dom.createUnsafeMorphAt(element2, 1, 1);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["btn btn-default btn-rounded ", ["subexpr", "if", [["get", "visible", ["loc", [null, [1, 43], [1, 50]]], 0, 0, 0, 0], "active"], [], ["loc", [null, [1, 38], [1, 61]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "title", ["concat", [["get", "btnTooltip", ["loc", [null, [1, 72], [1, 82]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["toggle"], [], ["loc", [null, [null, null], [1, 113]]], 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", [["get", "iconClass", ["loc", [null, [1, 126], [1, 135]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "btnText", ["loc", [null, [1, 143], [1, 154]]], 0, 0, 0, 0], ["attribute", "class", ["concat", ["toggle-button-content ", ["subexpr", "unless", [["get", "visible", ["loc", [null, [2, 43], [2, 50]]], 0, 0, 0, 0], "hidden"], [], ["loc", [null, [2, 34], [2, 61]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "yield", ["loc", [null, [3, 4], [3, 15]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  }());
});