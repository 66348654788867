define('hc2-ui/models/apps/test', ['exports', 'ember-data', 'hc2-ui/models/apps/sweepstake'], function (exports, _emberData, _sweepstake) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _sweepstake.default.extend({
        results: _emberData.default.attr(),
        showSharingButtonOnResults: _emberData.default.attr('boolean'),
        resultMails: _emberData.default.attr('boolean')
    });
});