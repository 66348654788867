define('hc2-ui/components/apps/sub-app-type-selector', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    appTypes: ['sweepstake', 'quiz', 'test', 'winWheel', 'scratchCard', 'calendar', 'contest', 'directLink', 'memory', 'game', 'pixelGraphic', 'poll', 'packYourBag', 'slotMachine'],
    actions: {
      toggleAppType: function toggleAppType(appType, event) {
        var appTypes = this.get("app.subAppTypes");
        if (!appTypes) {
          appTypes = [];
        }
        if (event.currentTarget.checked) {
          appTypes.pushObject({ value: appType });
        } else {
          for (var i = 0; i < appTypes.length; i++) {
            if (appTypes[i].value === appType) {
              appTypes.removeObject(appTypes[i]);
            }
          }
        }

        this.set("app.appTypes", appTypes);
      }
    }
  });
});