define("hc2-ui/components/backend/backend-wrapper", ["exports", "hc2-ui/lib/make/init"], function (exports, _init) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        didInsertElement: function didInsertElement() {
            _init.default.application();
        },
        didRender: function didRender() {
            _init.default.material();
        },
        createApp: 'createApp',
        actions: {
            createApp: function createApp(appType) {
                this.sendAction("createApp", appType);
            }
        }
    });
});