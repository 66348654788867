define("hc2-ui/helpers/flag", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var filename = "";
    switch (params[0]) {
      case "de":
        filename = "Germany";break;
      case "en":
        filename = "United-Kingdom";break;
      case "es":
        filename = "spanish";break;
      case "fr":
        filename = "french";break;
      case "it":
        filename = "Italy";break;
      case "tr":
        filename = "Turkey";break;
      case "da":
        filename = "Denmark";break;
      case "sv":
        filename = "Sweden";break;
      case "nl":
        filename = "Netherlands";break;
      case "pl":
        filename = "Poland";break;
      case "hu":
        filename = "Hungary";break;
      case "ro":
        filename = "Romania";break;
      case "sk":
        filename = "Slovakia";break;
      case "cs":
        filename = "Czech-Republic";break;
      case "hr":
        filename = "Croatia";break;
      case "sl":
        filename = "Slovenia";break;
      case "sr":
        filename = "Serbia";break;
      case "el":
        filename = "Grecee";break;
      case "pt":
        filename = "Portugal";break;
      case "ja":
        filename = "Japan";break;
      case "ru":
        filename = "Russia";break;
      case "no":
        filename = "Norway";break;
    }
    return "/assets/images/flags/" + filename + ".png";
  });
});