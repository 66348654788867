define("hc2-ui/routes/backend/platforms", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    api: Ember.inject.service(),
    pageTitle: "Platforms",
    model: function model() {
      return this.get("api").requestJSON("/statistics/platforms");
    }
  });
});