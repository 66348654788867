define('hc2-ui/components/controls/g-recaptcha', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tracking: Ember.inject.service(),
    captchaFulfilled: 'captchaFulfilled',
    captchaResponse: null,

    didInsertElement: function didInsertElement() {
      this.initLoop();
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.get("tracking").loadRecaptcha();
    },
    initLoop: function initLoop() {
      var _this = this;

      if (window.grecaptcha === undefined || window.grecaptcha.render === undefined) {
        Ember.run.later(function () {
          _this.initLoop();
        }, 300);
      } else {
        this.initRecaptcha();
      }
    },
    initRecaptcha: function initRecaptcha() {
      var that = this;
      var container = window.$('#' + this.get('elementId') + ' .recaptcha-wrapper').get(0);
      window.grecaptcha.render(container, {
        sitekey: '6LfVnxcTAAAAAGbg3RwCx3Lb0yeoEzeRPiJPZq24',
        callback: function callback(response) {
          that.set("captchaResponse", response);
          that.sendAction('captchaFulfilled', response);
        }
      });
    }
  });
});