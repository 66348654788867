define("hc2-ui/templates/components/controls/video-uploader", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 6
          }
        },
        "moduleName": "hc2-ui/templates/components/controls/video-uploader.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-sm-4");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-sm-1");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-sm-7");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "videoPlayerWrapper");
        dom.setAttribute(el1, "class", "img");
        dom.setAttribute(el1, "style", "display: none;");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("iframe");
        dom.setAttribute(el2, "style", "width:100%");
        dom.setAttribute(el2, "width", "640");
        dom.setAttribute(el2, "height", "360");
        dom.setAttribute(el2, "frameborder", "0");
        dom.setAttribute(el2, "allowfullscreen", "");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
        return morphs;
      },
      statements: [["inline", "input", [], ["id", "usersImageUri", "placeholder", ["subexpr", "ta", ["video.url"], [], ["loc", [null, [2, 63], [2, 79]]], 0, 0], "class", "form-control", "value", ["subexpr", "@mut", [["get", "inputValue", ["loc", [null, [2, 107], [2, 117]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 24], [2, 119]]], 0, 0], ["inline", "ta", ["or"], [], ["loc", [null, [3, 24], [3, 35]]], 0, 0], ["inline", "controls/vimeo-upload-button", [], ["namePrefix", ["subexpr", "@mut", [["get", "namePrefix", ["loc", [null, [4, 66], [4, 76]]], 0, 0, 0, 0]], [], [], 0, 0], "uploadFinished", ["subexpr", "action", ["vimeoUploadFinished"], [], ["loc", [null, [4, 92], [4, 122]]], 0, 0]], ["loc", [null, [4, 24], [4, 124]]], 0, 0]],
      locals: [],
      templates: []
    };
  }());
});