define("hc2-ui/components/apps/body-challenge/participate-form", ["exports", "hc2-ui/lib/make/init"], function (exports, _init) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),

    appData: {},
    participation: null,
    showThankYouMessage: false,

    actions: {
      setParticipationMediaFromUpload: function setParticipationMediaFromUpload(url) {
        this.set('participation.mediaUrl', url);
      },
      save: function save() {
        this.submitForm();
      }
    },

    conditionsAccepted: false,
    showNoConditionsMessage: false,
    participationSubmitted: 'participationSubmitted',

    submitForm: function submitForm() {
      if (window.$('#' + this.elementId + ' form').parsley().validate()) {
        var that = this;
        var wasNew = this.get('participation.isNew');
        this.get('participation').save().then(function (participation) {
          if (wasNew) {
            that.set('showThankYouMessage', true);
          }
          that.sendAction('participationSubmitted', participation);
        });
      }
    },

    didRender: function didRender() {
      _init.default.material(this.elementId);
      setTimeout(function () {
        window.$(window).trigger('resize');
      }, 500);
    },
    setupModel: function () {
      if (this.get('participation') == null) {
        this.set('participation', this.get('store').createRecord("body-challenge-participation", { app: this.get('appData'),
          mediaUrl: '/assets/uploads/platforms/gp/Defaultgrafik-Teilnahmeformular-BC.jpg',
          formData: {}
        }));
      }
    }.on("init")

  });
});