define('hc2-ui/components/controls/win-wheel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    width: 1000,
    autoSize: false,

    segments: 8,
    lineWidth: 4,
    drawText: false,
    fillStyle: 'transparent',
    strokeStyle: "rgb(222, 222, 222)",
    textFillStyle: 'rgb(222, 222, 222)',
    textOrientation: 'curved',
    textAlignment: 'outer',
    showImage: true,

    image: "/assets/images/winWheel/angle_guide.png",
    imageOverlay: true,
    started: false,
    duration: 5,

    redraw: function () {
      this.didInsertElement();
    }.observes("segmentsDef", "segmentsDef.@each", "segmentsDef.@each.text", "segmentsDef.@each.fillStyle", "segmentsDef.@each.textFillStyle", "showImage", "drawText", "imageOverlay", "image"),

    // Todo: pins, pointer, segmentsDescription

    actions: {
      startWheel: function startWheel() {
        this.startWheel();
      },
      stopWheel: function stopWheel() {
        this.stopWheel();
      },
      resetWheel: function resetWheel() {
        this.resetWheel();
      }
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      // register public api
      var publicApi = this.get("publicApi");
      if (publicApi) {
        publicApi.actions = {
          startWheel: function startWheel() {
            _this.startWheel();
          },
          stopWheel: function stopWheel(segmentNr) {
            _this.stopWheel(segmentNr);
          }
        };
      }
      this.set("publicApi", publicApi);
    },
    startWheel: function startWheel() {
      if (this.get("started")) {
        return false;
      }
      this.resetWheel();
      this.set("started", true);
      // start wheel with ongoing endless spin
      var wheel = this.get("wheel");
      wheel.animation.rotationAngle = 0;
      wheel.draw(true);
      wheel.animation = {
        'type': 'spinOngoing',
        'easing': 'Linear.easeNone', // constant rotation
        'repeat': -1, // endless
        'duration': parseInt(this.duration),
        'spins': 10
      };
      wheel.startAnimation();
    },
    stopWheel: function stopWheel(segmentNumber) {
      segmentNumber = segmentNumber || this.get("segments") / 2; // todo remove segmentNumber

      window.stoppingWinWeehl = this;

      // run endspin and stop at segment
      var wheel = this.get("wheel");
      var stopAt = wheel.getRandomForSegment(segmentNumber);
      wheel.animation = {
        stopAngle: stopAt,
        type: 'spinToStop',
        easing: 'Power2.easeOut',
        repeat: 0,
        duration: parseInt(this.duration),
        spins: 3, // 30% of spins seem to be good transition between animations with easeOut using same duration
        direction: 'clockwise',
        'callbackFinished': 'window.sendStoppingWinWheelAction()'
      };
      wheel.stopAnimation(false);
      wheel.rotationAngle = wheel.rotationAngle % 360;
      wheel.startAnimation();
    },
    resetWheel: function resetWheel() {
      this.get("wheel").stopAnimation(false); // Stop the animation, false as param so does not call callback function.
      this.get("wheel").rotationAngle = 0; // Re-set the wheel angle to 0 degrees.
      this.get("wheel").draw();
      this.set("started", false);
    },
    getSegments: function getSegments() {
      var segmentsDefProperty = this.get("segmentsDef");
      if (segmentsDefProperty) {
        var _segmentsDef = [];
        var _segmentsCount = segmentsDefProperty.length;
        for (var i = 0; i < _segmentsCount; i++) {
          _segmentsDef.push({
            text: segmentsDefProperty[i].text
          });
        }
        return _segmentsDef;
      }
      var segmentsDef = [];
      var segmentsCount = this.get("segments");
      for (var _i = 0; _i < segmentsCount; _i++) {
        segmentsDef.push({ text: _i + 1 + "" });
      }
      return segmentsDef;
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      // set size according to parent
      if (this.get("autoSize")) {
        this.set("width", this.$().css('width').replace("px", ""));
      }
      Ember.run.scheduleOnce('afterRender', function () {
        var image = _this2.get("image");
        var segments = _this2.getSegments();

        var theWheel = new window.Winwheel({
          'canvasId': 'win-wheel',
          'numSegments': segments.length,
          'lineWidth': _this2.get("lineWidth"),
          'fillStyle': _this2.get("fillStyle"),
          'drawText': _this2.get("drawText"),
          'textFillStyle': _this2.get("textFillStyle"),
          'textOrientation': _this2.get("textOrientation"),
          'textAlignment': _this2.get("textAlignment"),
          'strokeStyle': _this2.get("strokeStyle"),
          'drawMode': image ? 'image' : 'code',
          'imageOverlay': _this2.get("imageOverlay"),
          'segments': segments
        });
        _this2.set("wheel", theWheel);

        if (image) {
          // Create new image object in memory.
          var loadedImg = new Image();

          // Create callback to execute once the image has finished loading.
          loadedImg.onload = function () {
            theWheel.wheelImage = loadedImg; // Make wheelImage equal the loaded image object.
            theWheel.draw(); // Also call draw function to render the wheel.
          };

          // Set the image source, once complete this will trigger the onLoad callback (above).
          loadedImg.src = image;
        }
      });
    }
  });


  window.sendStoppingWinWheelAction = function () {
    var wheelComponent = window.stoppingWinWeehl;
    if (wheelComponent) {
      var segment = wheelComponent.get("wheel").getIndicatedSegmentNumber();
      wheelComponent.set("started", false);
      wheelComponent.sendAction("wheelStopped", segment);
    }
  };
});