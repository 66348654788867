define("hc2-ui/components/apps/app-mailings-editor", ["exports", "hc2-ui/lib/make/init"], function (exports, _init) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),
    api: Ember.inject.service(),
    session: Ember.inject.service(),

    classNames: ['app-mailings-editor'],

    editableMailingDOIEmailLabels: ["emails_mailingDOI_subject", "emails_mailingDOI_text", "emails_mailingDOI_landingPage_text", "emails_mailingDOI_optOutPage_text"],
    editableMailingDOIEmailLabelsWithEditor: ["emails_mailingDOI_text", "emails_mailingDOI_landingPage_text", "emails_mailingDOI_optOutPage_text"],

    app: null,
    mailings: null,

    actions: {
      sendMailingNow: function sendMailingNow(mailing) {
        var _this = this;

        if (window.confirm(this.get("i18n").t("confirm"))) {
          this.get('store').createRecord("action", { action: "sendMailingNow", context: { id: mailing.get('id') } }).save().then(function () {
            window.alert(_this.get("i18n").t("mailings.sentMailing"));
          });
        }
      },
      sendTestMailing: function sendTestMailing(mailing, locale) {
        var _this2 = this;

        this.get('store').createRecord("action", { action: "sendTestMailing",
          context: { id: mailing.get('id'), locale: locale, recipient: this.get("currentTestEmailRecipient") } }).save().then(function (action) {
          window.alert(_this2.get("i18n").t("mailings.sentTestMailTo", { to: action.get("context.recipient") }));
        });
      },
      addMailing: function addMailing() {
        var mailing = this.get("store").createRecord("app-mailing", {
          app: this.get("app"),
          name: this.get("i18n").t("mailings.label") + " " + (this.get("mailings.length") + 1),
          _showText: true
        });

        this.get("mailings").pushObject(mailing);
      },
      toggleMailText: function toggleMailText(mailing) {
        mailing.set("_showText", !mailing.get("_showText"));
      },
      saveMailing: function saveMailing(mailing) {
        mailing.save();
      },
      deleteMailing: function deleteMailing(mailing, index) {
        var _this3 = this;

        var targetName = this.get("i18n").t("mailings.label") + " " + (index + 1);
        if (mailing.get("name")) {
          targetName = mailing.get("name");
        }
        if (window.confirm(this.get("i18n").t("deleteConfirm", { target: targetName }))) {
          mailing.destroyRecord().finally(function () {
            _this3.get("mailings").removeObject(mailing);
          });
        }
      }
    },

    loadMailings: function loadMailings() {
      var _this4 = this;

      this.get('store').query('app-mailing', { appId: this.get('app.id') }).then(function (mailings) {
        _this4.set('mailings', mailings.toArray());
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.loadMailings();
      this.set("currentTestEmailRecipient", this.get("session.data.authenticated.userAuthorityInfo.email"));
    },
    didRender: function didRender() {
      _init.default.material(this.elementId);
    }
  });
});