define('hc2-ui/models/apps/sweepstake', ['exports', 'ember-data', 'hc2-ui/models/apps/app'], function (exports, _emberData, _app) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _app.default.extend({
        noForm: _emberData.default.attr('boolean'),
        questions: _emberData.default.attr(),
        showQuestionSteps: _emberData.default.attr('boolean'),
        contactData: _emberData.default.attr(),
        contactFormText: _emberData.default.attr('string'),
        headerImageUri: _emberData.default.attr("string"),
        thanksText: _emberData.default.attr("string"),
        allowMultipleParticipations: _emberData.default.attr('boolean', { defaultValue: false }),

        lotSaleActive: _emberData.default.attr('boolean', { defaultValue: false }),
        lotSaleLive: _emberData.default.attr('boolean', { defaultValue: false }),
        lotSaleText: _emberData.default.attr('string'),
        lotLabel: _emberData.default.attr("string"),
        checkoutNote: _emberData.default.attr("string"),
        lotPrice: _emberData.default.attr('number', { defaultValue: 5.00 }),
        lotAllowMultiPurchase: _emberData.default.attr('boolean', { defaultValue: true }),

        lotMailEnabled: _emberData.default.attr('boolean', { defaultValue: false }),
        lotMailSubject: _emberData.default.attr('string'),
        lotMailText: _emberData.default.attr('string'),
        headerContent: _emberData.default.attr('string'),
        countDownDuration: _emberData.default.attr('number')
    });
});