define("hc2-ui/services/tracking", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    headData: Ember.inject.service(),

    // public tracking functions
    trackPage: function trackPage() {
      if (this.get("trackingEnabled")) {
        this.sendGaPage();
        this.sendFacebookPageView();
      }
    },
    sendExternalLinkEvent: function sendExternalLinkEvent(url) {
      if (this.get("trackingEnabled")) {
        this.sendGaExternalLinkEvent(url);
      }
    },
    sendLeadEvent: function sendLeadEvent(label) {
      if (this.get("trackingEnabled")) {
        this.sendFacebookLead();
        this.sendGaLeadEvent(label);
      }
    },
    initLinkTracking: function initLinkTracking() {
      var _this = this;

      Ember.$(".track-links a").each(function (i, a) {
        if (!a.onclick) {
          a.onclick = function () {
            _this.sendExternalLinkEvent(a.href);
          };
        }
      });
    },


    // Google Analytics implementations
    sendGaExternalLinkEvent: function sendGaExternalLinkEvent(url) {
      if (window.ga !== undefined && this.get("trackingEnabled")) {
        window.ga('clientTracker.send', 'event', 'outbound', 'click', url);
        window.ga('send', 'event', 'outbound', 'click', url);
      }
    },

    // Google Analytics implementations
    sendGaLeadEvent: function sendGaLeadEvent(label) {
      if (window.ga !== undefined && this.get("trackingEnabled")) {
        if (label) {
          window.ga('clientTracker.send', 'event', 'Forms', 'Lead', label);
          window.ga('send', 'event', 'Forms', 'Lead', label);
        } else {
          window.ga('clientTracker.send', 'event', 'Forms', 'Lead');
          window.ga('send', 'event', 'Forms', 'Lead');
        }
      }
    },
    getPageTitle: function getPageTitle() {
      var title = this.get("headData.title") || window.hc2InitialAppTitle;
      if (this.get("appendToTitle")) {
        title += this.get("appendToTitle");
      }
      return title;
    },
    sendGaPage: function sendGaPage() {
      if (window.ga !== undefined && this.get("trackingEnabled")) {
        var page = {
          'location': location.href,
          'title': this.getPageTitle()
        };

        window.ga('clientTracker.send', 'pageview', page);
        return window.ga('send', 'pageview', page);
      }
    },
    addGaClientTracker: function addGaClientTracker(trackingId) {
      window.ga('create', trackingId, 'auto', "clientTracker", { allowLinker: true });
      window.ga('clientTracker.set', 'anonymizeIp', true);
    },


    // Facebook Pixel implementation
    initFacebookPixel: function initFacebookPixel(pixelId) {
      !function (win, doc, tagName, src, n, t, s) {
        if (win.fbq) return;

        n = win.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };

        if (!win._fbq) win._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = doc.createElement(tagName);
        t.async = !0;
        t.src = src;
        s = doc.getElementsByTagName(tagName)[0];
        s.parentNode.insertBefore(t, s);
      }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

      fbq('init', pixelId);
    },
    sendFacebookPageView: function sendFacebookPageView() {
      if (window.fbq && this.get("trackingEnabled")) window.fbq('track', 'PageView');
    },
    sendFacebookLead: function sendFacebookLead() {
      if (window.fbq && this.get("trackingEnabled")) window.fbq('track', 'Lead');
    },


    clientGaTracker: null,
    clientFacebookPixelId: null,
    optInNeeded: false,
    trackingEnabled: false,

    trackingOptOut: function trackingOptOut() {
      window.gaOptout();
      this.set("trackingEnabled", false);
    },
    trackingOptIn: function trackingOptIn() {
      window.gaOptin();
      this.initTracking();
    },
    initThirdPartyScripts: function initThirdPartyScripts(tracking, addToAny, facebook, recaptcha) {
      if (tracking) {
        this.initTracking();
      }
      if (addToAny) {
        this.loadAddToAny();
      }
      if (facebook) {
        this.loadFacebookSDK();
      }
      if (recaptcha) {
        this.loadRecaptcha();
      }
    },
    initTracking: function initTracking() {

      if (this.get("optInNeeded") && !window["trackingOptedIn"]) {
        return false;
      } else {
        if (!window.ga && !window.gaDisabled()) {
          (function (i, s, o, g, r, a, m) {
            i['GoogleAnalyticsObject'] = r;
            i[r] = i[r] || function () {
              (i[r].q = i[r].q || []).push(arguments);
            }, i[r].l = 1 * new Date();
            a = s.createElement(o), m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m);
          })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

          ga('create', window.gaProperty, 'auto', { allowLinker: true });
          ga('set', 'anonymizeIp', true);

          if (this.get("clientGaTracker")) {
            this.addGaClientTracker(this.get("clientGaTracker"));
          }
          // set up client Google Analytics Tracker
          if (this.get("clientFacebookPixelId")) {
            this.initFacebookPixel(this.get("clientFacebookPixelId"));
          }

          this.set("trackingEnabled", true);
        }
      }
    },
    loadAddToAny: function loadAddToAny() {
      this.loadExternalScript("//static.addtoany.com/menu/page.js", "addToAny");
    },
    loadRecaptcha: function loadRecaptcha() {
      this.loadExternalScript("https://www.google.com/recaptcha/api.js?render=explicit", "recaptcha");
    },
    loadFacebookSDK: function loadFacebookSDK() {
      this.loadExternalScript("//connect.facebook.net/de_DE/sdk.js#xfbml=1&version=v2.5&appId=519321034913139", 'facebook-jssdk');
    },
    loadExternalScript: function loadExternalScript(src, id) {
      console.log("loading " + src);
      (function (d, s, id) {
        var js,
            fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = src;
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', id);
    }
  });
});