define("hc2-ui/helpers/input-pattern", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.patternList = patternList;
  exports.isAllowedChar = isAllowedChar;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var pattern = exports.pattern = {
    email: "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+ ?$",
    phone: "^[ +0-9/()-]+ ?$",
    streetNr: "^[0-9]+[ 0-9a-zA-Z/-]* ?$",
    numbers: "^[0-9]+ ?$",
    price: "^[0-9]+,?[0-9]{0,2} ?$"
  };

  function patternList() {
    var patternNames = Object.keys(pattern);
    //patternNames.addObject('regex');
    return patternNames;
  }

  /**
   * checks if char is allowed in regex
   * @param regex
   * @param character
   * @returns {boolean}
   */
  function isAllowedChar(regex, character) {
    if (!character || character.length !== 1) {
      return true;
    }
    if (!regex) {
      return true;
    }
    var isAllowedChar = false;
    var characterGroups = [].concat(_toConsumableArray(regex.matchAll(/(\[.*?\])/g)));
    var singleChars = regex;
    characterGroups.forEach(function (g) {
      if (character.match(g[0])) {
        isAllowedChar = true;
        console.log('!!!', character, 'in', g[0]);
      }
      singleChars = singleChars.replace(g[0], '');
      console.log(singleChars);
    });

    singleChars = "[" + singleChars.replaceAll(/[\^+*?$]/g, '') + "]";
    if (singleChars && character.match(singleChars)) {
      isAllowedChar = true;
      console.log('!!!', character, 'in', singleChars);
    }
    console.log(singleChars);

    return isAllowedChar;
  }

  exports.default = Ember.Helper.helper(function (params) {
    if (params[0] && pattern[params[0]]) {
      return pattern[params[0]];
    }
    if (params[0] === 'regex') {
      return params[1];
    }
    return null;
  });
});