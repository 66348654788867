define("hc2-ui/components/apps/contest/participate-form", ["exports", "hc2-ui/lib/make/init"], function (exports, _init) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    tracking: Ember.inject.service(),
    session: Ember.inject.service(),

    mediaWidth: 100,
    mediaLeft: 0,
    mediaTop: 0,
    rotation: 0,
    horizontalFlip: false,

    onMediaStylesChange: Ember.observer("mediaWidth", "mediaLeft", "mediaTop", "rotation", "horizontalFlip", function () {
      this.set("participation.mediaStyles", "width:" + this.get("mediaWidth") + "%;" + "left:" + this.get("mediaLeft") + "%;" + "top:" + this.get("mediaTop") + "%;" + (this.get("rotation") || this.get("horizontalFlip") ? "transform:" + (this.get("rotation") ? "rotate(" + this.get("rotation") + "deg)" : "") + (this.get("horizontalFlip") ? " scaleX(-1)" : "") : ""));
    }),

    appData: {},
    participation: null,
    wizardReset: function () {
      var sfw = this.get('wizard');
      if (sfw) {
        while (sfw.stepActive > 0) {
          sfw.prev();
        }
      }
    }.observes('participation'),
    showThankYouMessage: false,

    save: "save",
    overlayTextResizer: Ember.observer('participation.title', function () {
      if (this.get("appData.backlayImageUri")) {
        Ember.run.debounce(this, this.resizeOverlayText, 100);
      }
    }),
    resizeOverlayText: function resizeOverlayText() {
      window.$('.overlay-text').textfill({ maxFontPixels: 1500 });
      var textHeight = this.$('.overlay-text').outerHeight();
      var textTop = parseInt(this.$('.overlay-text').css('top'), 10);
      var parentHeight = this.$('.overlay-text').parent().height();
      var textHeightPercent = Math.round(100 * textHeight / parentHeight);
      var textTopPercent = Math.round(100 * textTop / parentHeight);

      var width = this.$('.overlay-text').width();
      var parentWidth = this.$('.overlay-text').parent().width();
      var widthPercent = Math.round(100 * width / parentWidth);
      var padding = Math.round((100 - widthPercent) / 2);

      this.set("participation.mediaStyles", "top:" + textTopPercent + "%;" + "height:" + textHeightPercent + "%;" + "padding:" + padding + "%;");
    },

    actions: {
      setParticipationMediaFromUpload: function setParticipationMediaFromUpload(url) {
        this.set('participation.mediaUrl', url);
        this.set('showNoUploadMessage', false);
      },
      addParticipationMediaFromUpload: function addParticipationMediaFromUpload(url) {
        var participation = this.get("participation");
        var mediaObject = { url: url };
        if (!participation.get("extraMedia")) {
          participation.set("extraMedia", [mediaObject]);
        } else {
          participation.get("extraMedia").addObject(mediaObject);
        }
      },
      removeExtraParticipationMedia: function removeExtraParticipationMedia(mediaObject) {
        this.get("participation.extraMedia").removeObject(mediaObject);
      },
      resizeImageSize: function resizeImageSize(action) {
        var rotation = this.get("rotation");
        switch (action) {
          case '+':
            this.set("mediaWidth", this.get("mediaWidth") + 1);break;
          case '-':
            this.set("mediaWidth", this.get("mediaWidth") - 1);break;
          case 'b':
            this.set("mediaTop", this.get("mediaTop") + 1);break;
          case 't':
            this.set("mediaTop", this.get("mediaTop") - 1);break;
          case 'r':
            this.set("mediaLeft", this.get("mediaLeft") + 1);break;
          case 'l':
            this.set("mediaLeft", this.get("mediaLeft") - 1);break;
          case 'fh':
            this.set("horizontalFlip", !this.get("horizontalFlip"));break;
          case 'fr':
            rotation += 90;break;
          case 'fl':
            rotation -= 90;break;
        }
        this.set("rotation", rotation % 360);
      }
    },
    showNoUploadMessage: false,
    conditionsAccepted: false,
    showNoConditionsMessage: false,
    participationSubmitted: 'participationSubmitted',
    wizard: null,
    submitForm: function submitForm() {
      var _this = this;

      if (!this.get("savingParticipation", true)) {
        this.set("savingParticipation", true);
        var that = this;
        this.syncFormDataWithModel();
        var wasNew = this.get('participation.isNew');
        this.get('participation').save().then(function (participation) {
          that.set('showThankYouMessage', wasNew);
          that.set('currentStep', "done");
          that.sendAction('participationSubmitted', participation);
          that.get("tracking").sendLeadEvent(that.get("appData.title"));
        }).finally(function () {
          _this.set("savingParticipation", false);
        });
      }
    },
    syncFormDataWithModel: function syncFormDataWithModel() {

      var o = {};
      var a = window.$('#' + this.get('elementId') + ' form').serializeArray();
      window.$.each(a, function () {
        if (['mediaUrl', 'participationTitle'].indexOf(this.name) > -1) {
          return;
        }
        if (o[this.name] !== undefined) {
          if (!o[this.name].push) {
            o[this.name] = [o[this.name]];
          }
          o[this.name].push(this.value || '');
        } else {
          o[this.name] = this.value || '';
        }
      });
      o.locale = this.get("i18n.appLocale");
      o.source = this.get("session.source");
      if (window.hc2_ad && window.hc2_ad.form) Ember.$.extend(o, window.hc2_ad.form);
      this.set('participation.formData', o);

      var username = this.get('participation.formData.name');
      if (!username) {
        username = this.get('participation.formData.firstName') || "";
        username += this.get('participation.formData.lastName') ? " " + this.get('participation.formData.lastName').charAt(0) + "." : "";
      }
      if (username) this.set('participation.username', username);
    },
    resizeActiveImages: function resizeActiveImages() {
      window.$(window).trigger('resize');
      //window.$('.sf-step-active img').css('max-width', (window.$('.sf-step-active').width() - 10) + 'px' );
    },

    didRender: function didRender() {
      var that = this;
      _init.default.material(this.elementId);
      this.resizeActiveImages();
      if (window.$(".participate-wizard").closest(".sf-wizard").size() === 0) {
        var wizard = window.$(".participate-wizard").stepFormWizard({
          disableEnter: true,
          nextBtn: window.$('<a class="next-btn sf-right sf-btn" href="#">' + this.get('i18n').ta('forward') + '</a>'),
          prevBtn: window.$('<a class="prev-btn sf-left sf-btn" href="#">' + this.get('i18n').ta('back') + '</a>'),
          finishBtn: window.$('<a class="finish-btn sf-right sf-btn" type="submit" >' + '<i class="fa fa-spinner fa-spin" style="display:none"></i><span class="label">' + this.get('i18n').ta(this.get('participation.isNew') ? 'participate' : 'save') + '</span></a>'),
          height: 'auto',

          onNext: function onNext(i, wizard) {
            that.syncFormDataWithModel();
            var videoIFrames = Ember.$('.contest-participation-view iframe.contest-medium');
            if (videoIFrames.size() > 0 && videoIFrames[0] && videoIFrames[0].src) {
              videoIFrames[0].src = videoIFrames[0].src;
            }
            return that.isActiveTabValid();
          },
          onFinish: function onFinish(i, wizard) {
            //if(window.$('form', wizard).parsley().validate()) {
            that.submitForm(i);
            //}
            return false;
          },
          onSlideChanged: function onSlideChanged(oldStep, newStep) {
            window.$(window).trigger('resize');
            if (that.get("appData.backlayImageUri")) {
              window.$('.overlay-text').textfill({ maxFontPixels: 1500 });
            }
            that.resizeActiveImages();

            // send current slide
            var sfw = that.get('wizard');
            if (sfw && oldStep !== newStep) {
              that.set("currentStep", newStep + 1);
            }
          }
        });
        window.$(".participate-wizard").on('sf-loaded', function () /*e, from, to*/{
          window.$(window).trigger('resize');
        });
        this.set('wizard', wizard);
      }
      setTimeout(function () {
        window.$(window).trigger('resize');
      }, 500);
    },
    didDestroyElement: function didDestroyElement() {
      this.set("currentStep", 1);
    },

    setupModel: function () {
      this.set("currentStep", 1);
      if (this.get('participation') == null) {
        var mediaType = void 0;
        if (this.get("appData.mediaTypes.length") === 1) {
          mediaType = this.get("appData.mediaTypes")[0];
        }
        this.set('participation', this.get('store').createRecord("contest-participation", {
          app: this.get('appData'), mediaType: mediaType, formData: { locale: this.get("i18n.appLocale") }, domain: window.location.hostname,
          extraMedia: []
        }));
      }
      setInterval(this.resizeActiveImages, 500);
    }.on("init"),

    resetMediaUrl: Ember.observer("participation.mediaType", function () {
      if (this.get('participation.isNew')) {
        this.set("participation.mediaUrl", null);
      }
    }),
    translatedExtraFormElements: Ember.computed("appData.contactData.extraFromElements", "appData.contactData.extraFromElements.@each", "appData.contactData.extraFromElements.@each.type", "i18n.appLocale", function () {
      var _this2 = this;

      var elements = [];
      var extraFormElements = this.get("appData.contactData.extraFromElements");
      if (extraFormElements) {
        extraFormElements.forEach(function (element, elIndex) {
          var clonedElement = Ember.$.extend(true, {}, element);
          clonedElement.answers.forEach(function (answer, anIndex) {
            var translationKey = _this2.get("i18n.appLocale") + "_appdata_contactData_extraFromElements_" + elIndex + "_answers_" + anIndex + "_label";
            if (_this2.get("appData.translations")) {
              var translation = _this2.get("appData.translations")[translationKey];
              if (translation) {
                answer.label = translation;
              }
            }
          });
          elements.pushObject(clonedElement);
        });
      }
      return elements;
    }),
    isActiveTabValid: function isActiveTabValid() {
      // check required fields on current page
      var requiredInputs = window.$('.sf-step-active input[required]');
      var requiredPatternInputs = window.$('.sf-step-active input[data-required-pattern]');
      var inputsToCheck = window.$.merge(requiredInputs, requiredPatternInputs);
      var allRequiredSet = true;
      for (var i = 0; i < inputsToCheck.length; i++) {
        var type = requiredInputs[i].type;
        var val = null;
        var field = window.$('.sf-step-active input[name="' + requiredInputs[i].name + '"]');
        if ('radio' === type || 'checkbox' === type) {
          val = window.$('.sf-step-active input[name="' + requiredInputs[i].name + '"]:checked').val();
        } else {
          val = field.val();
        }

        var required = field.prop("required");
        var requiredPattern = field.attr("data-required-pattern");

        var requiredValid = required ? val : true;
        var patternValid = true;
        if (requiredPattern && val) {
          patternValid = val.match(requiredPattern);
        }

        if (!requiredValid || !patternValid) {
          var fg = field.closest('.form-group');
          fg.addClass('has-error');
          allRequiredSet = false;
          var msgBox = fg.find('.mandetoryFieldMessage');
          if (msgBox.length === 0) {
            msgBox = window.$('<div class="mandetoryFieldMessage"><span class="text-danger"></span></div>');
            fg.append(msgBox);
          }
          var msg = field.attr("data-error-message");
          if (!msg) {
            msg = !requiredValid ? this.get('i18n').ta('mandetory.field.hint.' + type) : this.get('i18n').ta('mandetory.field.patternHint.' + type);
          }
          msgBox.find('span').text(msg);
          msgBox.show().delay(5000).fadeOut();
        } else {
          field.closest('.form-group').removeClass('has-error');
        }
      }
      return allRequiredSet;
    }

  });
});