define("hc2-ui/components/apps/contest/editor-form", ["exports", "hc2-ui/lib/make/init"], function (exports, _init) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    appData: {},
    editorResources: {},

    appStates: {
      showWelcomePage: false
    },
    editableLabels: ["impressum", "overview", "backToOverview", "participate", "appdata_participate_nav_label", "search", "category_select", "sortProperty_title", "sortProperty_rating", "sortProperty_views", "sortProperty_dateCreated", "participations_none_yet", "participations_all_loaded", "preview", "close", "forward", "back", "yes", "no", "save", "contestParticipation_step_commons", "contestParticipation_step_profile", "contestParticipation_step_save", "mediaType_select", "mediaType_single_photo", "mediaType_single_video", "mediaType_single_text", "image_select", "image_selectExtra", "upload_action", "image_mandetory_message", "video_selectUrl", "video_url", "upload_video", "video_mandetory_message", "title_enter", "title", "description_add", "category_select", "sweepstake_contact_firstName", "sweepstake_contact_lastName", "sweepstake_contact_name", "sweepstake_contact_email", "sweepstake_contact_salutation", "sweepstake_contact_phone", "sweepstake_contact_mobile", "sweepstake_contact_street", "sweepstake_contact_streetNr", "sweepstake_contact_zip", "sweepstake_contact_city", "sweepstake_contact_country", "sweepstake_contact_doiLabel", "conditionsAgree_before", "conditions", "conditionsAgree_middle", "privacyPolicy", "conditionsAgree_after", "mandetory_field_hint_checkbox", "mandetory_field_hint_text", "mandetory_field_hint_radio", "mandetory_field_hint_hidden", "mandetory_field", "vote_plural", "views", "vote_action", "vote_emailActivation_action", "vote_already_voted", 'vote_thanks_header', 'vote_thanks_text', 'vote_emailActivation_header', 'vote_emailActivation_text', 'vote_activate_header', 'vote_activate_text', 'vote_activated_header', 'vote_activated_text', "participation_error_invalidEmail_sweepstake_participation", "mandetory_field_patternHint_text"],
    editableLabelsWithEditor: ['vote_emailActivation_text', 'vote_activated_text', 'vote_activate_text', 'vote_thanks_text'],
    editableLabelsWithCustomText: ['vote_emailActivation_header', 'vote_emailActivation_text', 'vote_activate_header', 'vote_activate_text', 'vote_activated_header', 'vote_activated_text', 'vote_thanks_header', 'vote_thanks_text'],

    editableVoteActivationEmailLabels: ["emails_voteActivation_subject", "emails_voteActivation_text"],
    editableVoteActivationEmailLabelsWithEditor: ["emails_voteActivation_text"],

    selectedExtraFromElement: 0,

    save: "save",
    actions: {
      addCategory: function addCategory() {
        this.get("appData.categories").pushObject({ value: "" });
      },
      removeCategory: function removeCategory(index) {
        this.get("appData.categories").removeAt(index);
      },
      save: function save() {
        this.sendAction('save', this.get('appData'));
      },
      setHeaderImageFromUpload: function setHeaderImageFromUpload(url) {
        this.set('appData.headerImageUri', url);
      },
      setOverlayImageFromUpload: function setOverlayImageFromUpload(url) {
        this.set('appData.overlayImageUri', url);
      },
      setBacklayImageFromUpload: function setBacklayImageFromUpload(url) {
        this.set('appData.backlayImageUri', url);
      },
      handleError: function handleError(err) {
        alert("Error:\n" + err);
      },
      toggleWelcomePageIfActive: function toggleWelcomePageIfActive(display) {
        this.switchWelcomePageIfActive(display);
        _init.default.material();
      },
      selectParticipationPage: function selectParticipationPage() {
        this.switchWelcomePageIfActive(false);
        this.set("selectedQuestion", this.get('appData.questions.length'));
        this.set("appStates.selectedTab", "participate");
      },
      addAnswer: function addAnswer(question) {
        question.answers.pushObject({ label: "", correct: false });
      },
      removeAnswer: function removeAnswer(question, answerIndex) {
        question.answers.removeAt(answerIndex);
      },
      setHighlightTop: function setHighlightTop(on) {
        this.set("appData.highlightTop", on ? 10 : 0);
      },

      setHighlightImageFromUpload: function setHighlightImageFromUpload(url) {
        this.set('appData.highlightImageUri', url);
      }
    },

    observeWelcomePage: function () {
      this.switchWelcomePageIfActive(true);
    }.observes("appData.welcomePage"),
    switchWelcomePageIfActive: function switchWelcomePageIfActive(display) {
      this.set("appStates.showWelcomePage", this.get("appData.welcomePage") && display);
    },

    didRender: function didRender() {
      //MakeInit.material(this.elementId);
    },
    didInsertElement: function didInsertElement() {
      //this.$(".contest-editor-form > .nav li:nth-child(5) a").click();
    }
  });
});