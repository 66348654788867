define('hc2-ui/components/controls/vimeo-upload-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    config: Ember.inject.service(),
    session: Ember.inject.service(),
    extentionType: 'video',
    uploadTarget: null,

    processing: false,

    actions: {
      uploadFinished: function uploadFinished(url, localFileUrl) {
        var _this = this;

        //this.sendAction("uploadFinished", localFileUrl, url, this.get("uploadTarget"));
        // Loop that waits for video fully published
        var uploadHost = this.get('config').getENV().oauth.host;
        this.set("processing", true);

        var vimeoUrlParts = url.split("/");
        var vimeoId = vimeoUrlParts[vimeoUrlParts.length - 1];

        Ember.$.get(uploadHost + "/upload/vimeoStatus/" + vimeoId).then(function (response) {
          if (response.status === 200) {
            _this.set("processing", false);
            _this.sendAction("uploadFinished", localFileUrl, url, _this.get("uploadTarget"));
          } else {
            Ember.run.later(function () {
              _this.send("uploadFinished", url, localFileUrl);
            }, 2000);
          }
        });
      },
      uploadFailed: function uploadFailed(err) {
        this.sendAction("uploadFailed", err);
      }
    }
  });
});