define("hc2-ui/templates/components/apps/welcome-page", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 8
              },
              "end": {
                "line": 11,
                "column": 8
              }
            },
            "moduleName": "hc2-ui/templates/components/apps/welcome-page.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "nextButton");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "class", "btn btn-primary");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element3);
            morphs[1] = dom.createMorphAt(element3, 1, 1);
            return morphs;
          },
          statements: [["element", "action", ["hideWelcomePage"], [], ["loc", [null, [7, 39], [7, 67]]], 0, 0], ["inline", "is-or", [["subexpr", "tu", ["welcomePageNextBtnText", ["get", "appData.welcomePageNextBtnText", ["loc", [null, [8, 51], [8, 81]]], 0, 0, 0, 0], ["get", "appData", ["loc", [null, [8, 82], [8, 89]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 22], [8, 90]]], 0, 0], ["subexpr", "ta", ["forward"], [], ["loc", [null, [8, 91], [8, 105]]], 0, 0]], [], ["loc", [null, [8, 14], [8, 107]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 13,
              "column": 2
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/welcome-page.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "welcomePageImg");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var element5 = dom.childAt(element4, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element5, 'src');
          morphs[1] = dom.createMorphAt(element4, 3, 3);
          return morphs;
        },
        statements: [["attribute", "src", ["concat", [["subexpr", "tu", ["welcomePageImgUri", ["get", "appData.welcomePageImgUri", ["loc", [null, [4, 45], [4, 70]]], 0, 0, 0, 0], ["get", "appData", ["loc", [null, [4, 71], [4, 78]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 20], [4, 80]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["subexpr", "is-or", [["get", "fromNow.started", ["loc", [null, [5, 21], [5, 36]]], 0, 0, 0, 0], ["subexpr", "is-or", [["get", "editMode", ["loc", [null, [5, 44], [5, 52]]], 0, 0, 0, 0], ["get", "appData.demo", ["loc", [null, [5, 53], [5, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 37], [5, 66]]], 0, 0]], [], ["loc", [null, [5, 14], [5, 67]]], 0, 0]], [], 0, null, ["loc", [null, [5, 8], [11, 15]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 8
              },
              "end": {
                "line": 18,
                "column": 8
              }
            },
            "moduleName": "hc2-ui/templates/components/apps/welcome-page.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "controls/facebook-like", [], ["url", ["subexpr", "@mut", [["get", "appData.facebookLikeUrl", ["loc", [null, [17, 39], [17, 62]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [17, 10], [17, 64]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 6
            },
            "end": {
              "line": 19,
              "column": 6
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/welcome-page.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "appData.facebookLikeUrl", ["loc", [null, [16, 14], [16, 37]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [16, 8], [18, 15]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 2
            },
            "end": {
              "line": 26,
              "column": 2
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/welcome-page.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "welcomePageText");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "tu", ["welcomePageText", ["get", "appData.welcomePageText", ["loc", [null, [24, 32], [24, 55]]], 0, 0, 0, 0], ["get", "appData", ["loc", [null, [24, 56], [24, 63]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 8], [24, 66]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child3 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 37,
                    "column": 47
                  },
                  "end": {
                    "line": 39,
                    "column": 10
                  }
                },
                "moduleName": "hc2-ui/templates/components/apps/welcome-page.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "error-message text-danger");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n          ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                return morphs;
              },
              statements: [["inline", "ta", ["codePromotion.invalidCode"], [], ["loc", [null, [38, 51], [38, 85]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 37,
                  "column": 10
                },
                "end": {
                  "line": 39,
                  "column": 17
                }
              },
              "moduleName": "hc2-ui/templates/components/apps/welcome-page.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "codeInput", ["loc", [null, [37, 53], [37, 62]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [37, 47], [39, 17]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 8
              },
              "end": {
                "line": 40,
                "column": 8
              }
            },
            "moduleName": "hc2-ui/templates/components/apps/welcome-page.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "codeInputContainer");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("form");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element2, 'onsubmit');
            morphs[1] = dom.createMorphAt(element2, 1, 1);
            morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["attribute", "onsubmit", ["subexpr", "action", ["hideWelcomePage"], [], ["loc", [null, [null, null], [33, 55]]], 0, 0], 0, 0, 0, 0], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "codeInput", ["loc", [null, [34, 26], [34, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "autocomplete", "off", "placeholder", ["subexpr", "ta", ["welcomePage.accessCode.code"], [], ["loc", [null, [34, 67], [34, 101]]], 0, 0], "class", "form-control floating-label"], ["loc", [null, [34, 12], [34, 139]]], 0, 0], ["block", "if", [["subexpr", "is-eq", [["get", "invalidCode", ["loc", [null, [37, 23], [37, 34]]], 0, 0, 0, 0], ["get", "codeInput", ["loc", [null, [37, 35], [37, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 16], [37, 45]]], 0, 0]], [], 0, null, ["loc", [null, [37, 10], [39, 24]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 6
            },
            "end": {
              "line": 42,
              "column": 6
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/welcome-page.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col-xs-12");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "is-or", [["get", "fromNow.started", ["loc", [null, [31, 21], [31, 36]]], 0, 0, 0, 0], ["subexpr", "is-or", [["get", "editMode", ["loc", [null, [31, 44], [31, 52]]], 0, 0, 0, 0], ["get", "appData.demo", ["loc", [null, [31, 53], [31, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [31, 37], [31, 66]]], 0, 0]], [], ["loc", [null, [31, 14], [31, 67]]], 0, 0]], [], 0, null, ["loc", [null, [31, 8], [40, 15]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child4 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 10
            },
            "end": {
              "line": 53,
              "column": 10
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/welcome-page.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "nextButton");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "class", "btn btn-primary");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(element1, 1, 1);
          return morphs;
        },
        statements: [["element", "action", ["hideWelcomePage"], [], ["loc", [null, [49, 45], [49, 73]]], 0, 0], ["inline", "is-or", [["subexpr", "tu", ["welcomePageNextBtnText", ["get", "appData.welcomePageNextBtnText", ["loc", [null, [50, 57], [50, 87]]], 0, 0, 0, 0], ["get", "appData", ["loc", [null, [50, 88], [50, 95]]], 0, 0, 0, 0]], [], ["loc", [null, [50, 28], [50, 96]]], 0, 0], ["subexpr", "ta", ["forward"], [], ["loc", [null, [50, 97], [50, 111]]], 0, 0]], [], ["loc", [null, [50, 20], [50, 113]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child5 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 58,
                "column": 4
              },
              "end": {
                "line": 70,
                "column": 4
              }
            },
            "moduleName": "hc2-ui/templates/components/apps/welcome-page.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-sm-12 text-center");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("strong");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4, "class", "val");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4, "class", "unit");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4, "class", "val");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4, "class", "unit");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4, "class", "val");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4, "class", "unit");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4, "class", "val");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4, "class", "unit");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1, 1]);
            var morphs = new Array(9);
            morphs[0] = dom.createMorphAt(element0, 1, 1);
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
            morphs[3] = dom.createMorphAt(dom.childAt(element0, [7]), 0, 0);
            morphs[4] = dom.createMorphAt(dom.childAt(element0, [9]), 0, 0);
            morphs[5] = dom.createMorphAt(dom.childAt(element0, [11]), 0, 0);
            morphs[6] = dom.createMorphAt(dom.childAt(element0, [13]), 0, 0);
            morphs[7] = dom.createMorphAt(dom.childAt(element0, [15]), 0, 0);
            morphs[8] = dom.createMorphAt(dom.childAt(element0, [17]), 0, 0);
            return morphs;
          },
          statements: [["inline", "ta", ["start.in"], [], ["loc", [null, [62, 18], [62, 35]]], 0, 0], ["content", "fromNow.days", ["loc", [null, [63, 38], [63, 54]]], 0, 0, 0, 0], ["inline", "ta", ["days"], [], ["loc", [null, [63, 81], [63, 94]]], 0, 0], ["content", "fromNow.hours", ["loc", [null, [64, 38], [64, 55]]], 0, 0, 0, 0], ["inline", "ta", ["hours"], [], ["loc", [null, [64, 82], [64, 96]]], 0, 0], ["content", "fromNow.minutes", ["loc", [null, [65, 38], [65, 57]]], 0, 0, 0, 0], ["inline", "ta", ["minutes"], [], ["loc", [null, [65, 84], [65, 100]]], 0, 0], ["content", "fromNow.seconds", ["loc", [null, [66, 38], [66, 57]]], 0, 0, 0, 0], ["inline", "ta", ["seconds"], [], ["loc", [null, [66, 84], [66, 100]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 57,
              "column": 2
            },
            "end": {
              "line": 71,
              "column": 2
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/welcome-page.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "unless", [["get", "fromNow.started", ["loc", [null, [58, 14], [58, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [58, 4], [70, 15]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child6 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 73,
              "column": 2
            },
            "end": {
              "line": 75,
              "column": 2
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/welcome-page.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "ml/app-locale-switcher", [], ["locales", ["subexpr", "@mut", [["get", "appData.localesParent", ["loc", [null, [74, 35], [74, 56]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [74, 2], [74, 58]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 77,
            "column": 6
          }
        },
        "moduleName": "hc2-ui/templates/components/apps/welcome-page.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "welcomePage");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "m-t-5");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row welcomePageButtons");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-xs-7");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-xs-5");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0]);
        var element7 = dom.childAt(element6, [7]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(element6, 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(element6, 5, 5);
        morphs[3] = dom.createMorphAt(element7, 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(element7, [3]), 1, 1);
        morphs[5] = dom.createMorphAt(dom.childAt(element7, [5]), 1, 1);
        morphs[6] = dom.createMorphAt(element6, 9, 9);
        morphs[7] = dom.createMorphAt(element6, 11, 11);
        return morphs;
      },
      statements: [["block", "if", [["get", "appData.welcomePageImgUri", ["loc", [null, [2, 8], [2, 33]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 2], [13, 9]]]], ["block", "if", [["get", "appData.fangateActive", ["loc", [null, [15, 12], [15, 33]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [15, 6], [19, 13]]]], ["block", "if", [["get", "appData.welcomePageText", ["loc", [null, [22, 8], [22, 31]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [22, 2], [26, 9]]]], ["block", "if", [["get", "appData.accessCodeActive", ["loc", [null, [29, 12], [29, 36]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [29, 6], [42, 13]]]], ["inline", "controls/add-to-any", [], ["app", ["subexpr", "@mut", [["get", "appData", ["loc", [null, [44, 36], [44, 43]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [44, 10], [44, 45]]], 0, 0], ["block", "if", [["subexpr", "is-or", [["get", "fromNow.started", ["loc", [null, [47, 23], [47, 38]]], 0, 0, 0, 0], ["subexpr", "is-or", [["get", "editMode", ["loc", [null, [47, 46], [47, 54]]], 0, 0, 0, 0], ["get", "appData.demo", ["loc", [null, [47, 55], [47, 67]]], 0, 0, 0, 0]], [], ["loc", [null, [47, 39], [47, 68]]], 0, 0]], [], ["loc", [null, [47, 16], [47, 69]]], 0, 0]], [], 4, null, ["loc", [null, [47, 10], [53, 17]]]], ["block", "if", [["get", "appData.showCountdown", ["loc", [null, [57, 8], [57, 29]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [57, 2], [71, 9]]]], ["block", "if", [["get", "renderLocaleSwitcher", ["loc", [null, [73, 8], [73, 28]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [73, 2], [75, 9]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  }());
});