define("hc2-ui/helpers/strip-tags", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    if (params[0] && params[0].includes("<")) {
      return Ember.$(params[0]).text();
    }
    return params[0];
  });
});