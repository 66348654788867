define('hc2-ui/helpers/contains-obj-with-prop', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    for (var i = 0; i < params[0].length; i++) {
      if (params[0][i][params[1]] === params[2]) {
        return true;
      }
    }
    return false;
  });
});