define("hc2-ui/components/apps/slot-machine/combinations", ["exports", "hc2-ui/lib/make/init", "hc2-ui/helpers/has-html-content"], function (exports, _init, _hasHtmlContent) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),

    items: Ember.computed.alias('appData.pairs'),
    prizes: Ember.computed.alias('appData.prizes'),
    slotCount: Ember.computed.alias('appData.maxItems'),
    combinations: Ember.computed.alias('appData.questions'),

    total: Ember.computed('items.[]', 'slotCount', function () {
      var itemCount = this.get('items.length');
      var slotCount = parseInt(this.get('slotCount'));
      return Math.pow(itemCount, slotCount);
    }),
    actions: {
      add: function add() {
        this.get('combinations').pushObject({});
      },
      remove: function remove(index) {
        this.get('combinations').removeAt(index);
      },
      reassignCombinations: function reassignCombinations() {
        this.notifyPropertyChange('assignedCombinations');
      },
      calculateCombosManually: function calculateCombosManually() {
        var _this = this;

        this.set('calculateCombosOverMax', true);
        this.set('calculateAssignmentsOverMax', true);
        Ember.run.later(function () {
          _this.notifyPropertyChange('allCombinations');
        });
      }
    },
    prizeMap: Ember.computed('prizes.[]', 'combinations.[]', function () {
      var prizes = this.get('prizes');

      var prizeMap = prizes.map(function (v, i) {
        return {
          id: i,
          text: v.text,
          prize: v
        };
      });
      return prizeMap;
    }),
    unassignedPrizes: Ember.computed('prizeMap.[]', 'combinations.@each.prizes', function () {
      var prizeMap = this.get('prizeMap');
      var combinations = this.get('combinations');

      var assignedPrizes = new Set();
      combinations.forEach(function (combo) {
        if (combo.prizes) {
          combo.prizes.forEach(function (v) {
            assignedPrizes.add(v);
          });
        }
      });
      return prizeMap.filter(function (v, i) {
        return !assignedPrizes.has(v.id);
      });
    }),
    usedCombinationCount: Ember.computed('assignedCombinations', 'combinations.@each.prizes', function () {
      var count = 0;
      var assignedCombinations = this.get('assignedCombinations');
      assignedCombinations.forEach(function (combo) {
        if (combo.comboSetting && combo.comboSetting.prizes && combo.comboSetting.prizes.length > 0) {
          count += combo.assignedCount;
        }
      });
      return count;
    }),
    unusedCombinationCount: Ember.computed('total', 'usedCombinationCount', function () {
      return this.get('total') - this.get('usedCombinationCount');
    }),
    maxCombosForAutoAssign: 250000,
    calculateCombosOverMax: false,
    calculateAssignmentsOverMax: false,
    allCombinations: Ember.computed('items.[]', 'slotCount', function () {
      var items = this.get('items');
      var slotCount = parseInt(this.get('slotCount'));

      var doCalc = this.get('calculateCombosOverMax') || this.get('total') < this.get('maxCombosForAutoAssign');

      if (!doCalc) {
        return new Array();
      }

      var combinations = this.combinationsCalc(items, slotCount);
      this.set('calculateCombosOverMax', false);
      return combinations;
    }),
    combinationsCalc: function combinationsCalc(items, slotCount) {
      var currentSlot = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

      var _this2 = this;

      var currentCombi = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      var combinations = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];

      if (currentCombi === null) {
        currentCombi = new Array(slotCount);
      }
      items.forEach(function (v, i) {
        currentCombi[currentSlot] = i;
        if (currentSlot === slotCount - 1) {
          combinations.push(Array.from(currentCombi));
        } else {
          _this2.combinationsCalc(items, slotCount, currentSlot + 1, currentCombi, combinations);
        }
      });
      return combinations;
    },

    unassignedCombinations: Ember.computed('assignedCombinations', function () {
      return this.get('assignedCombinations').filterBy('comboSetting', null)[0];
    }),
    assignedCombinations: Ember.computed('allCombinations', 'combinations.@each.type', 'combinations.@each.count', 'combinations.@each.items.[]', function () {
      var _this3 = this;

      var all = this.get('allCombinations');
      var autoAssign = all.length !== 0;

      var max = this.get('maxCombosForAutoAssign');
      var doOverMax = this.get('calculateAssignmentsOverMax');
      if (autoAssign && all.length > max && !doOverMax) {
        autoAssign = false;
      }

      var notAssignedIndex = this.get('combinations.length');
      var comboMap = this.get('combinations').map(function (comboSetting) {
        comboSetting.combos = [];
        return {
          comboSetting: comboSetting,
          matchingCount: 0,
          assignedCount: 0,
          combinations: [],
          autoAssign: autoAssign
        };
      });
      comboMap.push({
        comboSetting: null,
        matchingCount: all.length,
        assignedCount: 0,
        combinations: [],
        autoAssign: autoAssign
      });
      if (!autoAssign) {
        return comboMap;
      }

      // build assignment
      var comboAssignments = [];
      all.forEach(function (v, i) {
        var comboAssignment = {
          combo: v,
          matching: [],
          assigned: null
          // find matching combos
        };comboMap.forEach(function (cmi, i) {
          if (cmi.comboSetting && _this3.equalsCombo(v, cmi.comboSetting)) {
            comboAssignment.matching.push(i);
            cmi.matchingCount++;
            cmi.combinations.push(comboAssignment);
          }
        });
        if (comboAssignment.matching.length > 0) {
          comboAssignments.push(comboAssignment);
        } else {
          comboMap[notAssignedIndex].assignedCount++;
          comboMap[notAssignedIndex].combinations.push(comboAssignment);
        }
      });

      // determine assigned combo
      comboAssignments.forEach(function (ca) {
        var lastMatchingCount = all.length + 1;
        var assignedIndex = null;
        ca.matching.forEach(function (ci) {
          var mc = comboMap[ci].matchingCount;
          if (mc < lastMatchingCount) {
            lastMatchingCount = mc;
            assignedIndex = ci;
          }
        });
        ca.assigned = assignedIndex;
        comboMap[assignedIndex].assignedCount++;
        comboMap[assignedIndex].comboSetting.combos.push(ca.combo);
      });

      this.set('calculateAssignmentsOverMax', false);
      return comboMap;
    }),
    equalsCombo: function equalsCombo(combination, comboSettings) {

      var type = Ember.get(comboSettings, 'type');
      var count = parseInt(Ember.get(comboSettings, 'count'));

      if (type === 'same' || type === 'diff') {
        var itemCountMap = new Map();
        combination.forEach(function (v, i) {
          var c = itemCountMap.has(v) ? itemCountMap.get(v) + 1 : 1;
          itemCountMap.set(v, c);
        });

        if (type === 'diff') {
          if (itemCountMap.size === count) {
            return true;
          } else {
            return false;
          }
        }

        var itemsWithMatchingCount = [];
        itemCountMap.forEach(function (v, k) {
          if (v === count) {
            itemsWithMatchingCount.push(k);
          }
        });

        var isMatching = false;

        if (itemsWithMatchingCount.length > 0) {
          var item = parseInt(Ember.get(comboSettings, 'items.0'));
          if (item >= 0) {
            itemsWithMatchingCount.forEach(function (v) {
              if (v === item) {
                isMatching = true;
              }
            });
          } else {
            isMatching = true;
          }
        }
        return isMatching;
      } else if (type === 'free') {
        var _isMatching = true;
        Ember.get(comboSettings, 'items').forEach(function (v, i) {
          var item = parseInt(v);
          if (v >= 0) {
            if (combination[i] !== item) {
              _isMatching = false;
            }
          }
        });
        return _isMatching;
      }
      return false;
    }
  });
});