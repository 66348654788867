define("hc2-ui/routes/backend/user", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    api: Ember.inject.service(),
    pageTitle: "User",
    model: function model(params) {
      return Ember.RSVP.hash({
        userData: this.get("api").requestJSON("/statistics/user/" + params.user_id),
        platforms: this.get("api").requestJSON("/statistics/allPlatforms")
      });
    },
    afterModel: function afterModel(model) {
      this.set("pageTitle", 'User ' + model.userData.username);
    }
  });
});