define('hc2-ui/models/apps/poll', ['exports', 'ember-data', 'hc2-ui/models/apps/sweepstake'], function (exports, _emberData, _sweepstake) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _sweepstake.default.extend({
    showResultsAtTheEnd: _emberData.default.attr('boolean', { defaultValue: true }),
    showResultsAfterEachQuestion: _emberData.default.attr('boolean', { defaultValue: false })
  });
});