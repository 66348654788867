define('hc2-ui/authenticators/hc2-restapi-oauth', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'hc2-ui/config/environment'], function (exports, _oauth2PasswordGrant, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _oauth2PasswordGrant.default.extend({
        clientId: _environment.default.oauth.clientId,
        serverTokenEndpoint: _environment.default.oauth.host + _environment.default.oauth.serverTokenEndpoint,
        serverTokenRevocationEndpoint: null,
        store: Ember.inject.service(),
        session: Ember.inject.service(),

        authenticate: function authenticate(options) {
            var store = this.get('store');
            var session = this.get('session');

            // first authenticate regularily then retrieve addition user infos
            var promise = this._super(options.identification, options.password).then(function (authData) {
                // set access_token so we have a authenticated rest equest
                session.set('session.authenticated.access_token', authData.access_token);
                // get additional userInfo
                return store.findRecord('userAuthorityInfo', authData.access_token).then(function (userAuthorityInfo) {
                    authData.userAuthorityInfo = userAuthorityInfo;
                    return authData;
                }, function (err) {
                    console.log(err);
                    promise.reject();
                });
            });
            return promise;
        }
    });
});