define("hc2-ui/templates/components/controls/css-editor", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 12,
              "column": 0
            }
          },
          "moduleName": "hc2-ui/templates/components/controls/css-editor.hbs"
        },
        isEmpty: true,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: ["editor"],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 12,
            "column": 14
          }
        },
        "moduleName": "hc2-ui/templates/components/controls/css-editor.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "ember-ace", [], ["lines", 50, "enableDefaultAutocompletion", true, "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [4, 8], [4, 13]]], 0, 0, 0, 0]], [], [], 0, 0], "useWrapMode", true, "mode", "ace/mode/css", "theme", "ace/theme/xcode", "update", ["subexpr", "action", [["subexpr", "mut", [["get", "value", ["loc", [null, [8, 22], [8, 27]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 17], [8, 28]]], 0, 0]], [], ["loc", [null, [8, 9], [8, 29]]], 0, 0], "suggestCompletions", ["subexpr", "action", ["suggestCompletions"], [], ["loc", [null, [9, 21], [9, 50]]], 0, 0]], 0, null, ["loc", [null, [1, 0], [12, 14]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});