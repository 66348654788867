define('hc2-ui/helpers/list-length', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var list = params[0];
    return list.get('length');
  });
});