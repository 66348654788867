define('hc2-ui/helpers/current-url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    if (params[0]) {
      return encodeURIComponent(location.href);
    }
    return location.href;
  });
});