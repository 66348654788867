define("hc2-ui/templates/components/apps/email-placeholder-info", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 8
            },
            "end": {
              "line": 13,
              "column": 8
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/email-placeholder-info.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("th");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("th");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["active"], [], ["loc", [null, [11, 12], [11, 26]]], 0, 0], ["inline", "t", ["emails.placeholderMandetory"], [], ["loc", [null, [12, 12], [12, 47]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 28,
                    "column": 12
                  },
                  "end": {
                    "line": 30,
                    "column": 12
                  }
                },
                "moduleName": "hc2-ui/templates/components/apps/email-placeholder-info.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("i");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element6 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createAttrMorph(element6, 'class');
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["glyphicon glyphicon-", ["subexpr", "if", [["subexpr", "get", [["get", "this.appData.contactData", ["loc", [null, [29, 56], [29, 80]]], 0, 0, 0, 0], ["subexpr", "concat", [["get", "placeholder", ["loc", [null, [29, 89], [29, 100]]], 0, 0, 0, 0], "Mandetory"], [], ["loc", [null, [29, 81], [29, 113]]], 0, 0]], [], ["loc", [null, [29, 51], [29, 114]]], 0, 0], "ok", "remove"], [], ["loc", [null, [29, 46], [29, 130]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 8
                },
                "end": {
                  "line": 32,
                  "column": 8
                }
              },
              "moduleName": "hc2-ui/templates/components/apps/email-placeholder-info.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("td");
              var el2 = dom.createElement("i");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("td");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1, 0]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element7, 'class');
              morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["glyphicon glyphicon-", ["subexpr", "if", [["subexpr", "get", [["get", "this.appData.contactData", ["loc", [null, [26, 52], [26, 76]]], 0, 0, 0, 0], ["get", "placeholder", ["loc", [null, [26, 77], [26, 88]]], 0, 0, 0, 0]], [], ["loc", [null, [26, 47], [26, 89]]], 0, 0], "ok", "remove"], [], ["loc", [null, [26, 42], [26, 105]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["subexpr", "get", [["get", "this.appData.contactData", ["loc", [null, [28, 23], [28, 47]]], 0, 0, 0, 0], ["get", "placeholder", ["loc", [null, [28, 48], [28, 59]]], 0, 0, 0, 0]], [], ["loc", [null, [28, 18], [28, 60]]], 0, 0]], [], 0, null, ["loc", [null, [28, 12], [30, 19]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 8
              },
              "end": {
                "line": 34,
                "column": 8
              }
            },
            "moduleName": "hc2-ui/templates/components/apps/email-placeholder-info.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createElement("code");
            var el4 = dom.createTextNode("{");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("}");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element8, 'class');
            morphs[1] = dom.createMorphAt(dom.childAt(element8, [1]), 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element8, [3, 0]), 1, 1);
            morphs[3] = dom.createMorphAt(element8, 5, 5);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", [["subexpr", "if", [["subexpr", "is-and", [["subexpr", "get", [["get", "this.appData.contactData", ["loc", [null, [20, 25], [20, 49]]], 0, 0, 0, 0], ["get", "placeholder", ["loc", [null, [20, 50], [20, 61]]], 0, 0, 0, 0]], [], ["loc", [null, [20, 20], [20, 62]]], 0, 0], ["subexpr", "get", [["get", "this.appData.contactData", ["loc", [null, [20, 68], [20, 92]]], 0, 0, 0, 0], ["subexpr", "concat", [["get", "placeholder", ["loc", [null, [20, 101], [20, 112]]], 0, 0, 0, 0], "Mandetory"], [], ["loc", [null, [20, 93], [20, 125]]], 0, 0]], [], ["loc", [null, [20, 63], [20, 126]]], 0, 0]], [], ["loc", [null, [20, 12], [20, 127]]], 0, 0], "text-success"], [], ["loc", [null, [19, 15], [21, 36]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", [["subexpr", "concat", ["sweepstake.contact.", ["get", "placeholder", ["loc", [null, [22, 46], [22, 57]]], 0, 0, 0, 0]], [], ["loc", [null, [22, 16], [22, 58]]], 0, 0]], [], ["loc", [null, [22, 12], [22, 60]]], 0, 0], ["content", "placeholder", ["loc", [null, [23, 24], [23, 39]]], 0, 0, 0, 0], ["block", "unless", [["get", "showAll", ["loc", [null, [25, 18], [25, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [25, 8], [32, 19]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 4
            },
            "end": {
              "line": 35,
              "column": 4
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/email-placeholder-info.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "is-or", [["subexpr", "get", [["get", "this.appData.contactData", ["loc", [null, [18, 26], [18, 50]]], 0, 0, 0, 0], ["get", "placeholder", ["loc", [null, [18, 51], [18, 62]]], 0, 0, 0, 0]], [], ["loc", [null, [18, 21], [18, 63]]], 0, 0], ["get", "showAll", ["loc", [null, [18, 64], [18, 71]]], 0, 0, 0, 0]], [], ["loc", [null, [18, 14], [18, 72]]], 0, 0]], [], 0, null, ["loc", [null, [18, 8], [34, 15]]]]],
        locals: ["placeholder"],
        templates: [child0]
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 39,
              "column": 8
            },
            "end": {
              "line": 42,
              "column": 8
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/email-placeholder-info.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("td");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "glyphicon glyphicon-ok");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("td");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child3 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 8
              },
              "end": {
                "line": 51,
                "column": 8
              }
            },
            "moduleName": "hc2-ui/templates/components/apps/email-placeholder-info.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "glyphicon glyphicon-ok");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 44,
              "column": 4
            },
            "end": {
              "line": 53,
              "column": 4
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/email-placeholder-info.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createElement("code");
          var el4 = dom.createTextNode("{activationLink}");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var element5 = dom.childAt(element4, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element4, 'class');
          morphs[1] = dom.createMorphAt(element5, 0, 0);
          morphs[2] = dom.createMorphAt(element5, 2, 2);
          morphs[3] = dom.createMorphAt(element4, 5, 5);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["subexpr", "unless", [["get", "showAll", ["loc", [null, [45, 24], [45, 31]]], 0, 0, 0, 0], "text-success"], [], ["loc", [null, [45, 15], [45, 48]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["link"], [], ["loc", [null, [46, 12], [46, 24]]], 0, 0], ["inline", "t", ["emails.voteActivation.subject"], [], ["loc", [null, [46, 25], [46, 62]]], 0, 0], ["block", "unless", [["get", "showAll", ["loc", [null, [48, 18], [48, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [48, 8], [51, 19]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child4 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 58,
                "column": 12
              },
              "end": {
                "line": 61,
                "column": 12
              }
            },
            "moduleName": "hc2-ui/templates/components/apps/email-placeholder-info.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "glyphicon glyphicon-ok");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 54,
              "column": 4
            },
            "end": {
              "line": 63,
              "column": 4
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/email-placeholder-info.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(": ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createElement("code");
          var el4 = dom.createTextNode("{optOutLink}");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element2, 'class');
          morphs[1] = dom.createMorphAt(element3, 0, 0);
          morphs[2] = dom.createMorphAt(element3, 2, 2);
          morphs[3] = dom.createMorphAt(element2, 5, 5);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["subexpr", "unless", [["get", "showAll", ["loc", [null, [55, 28], [55, 35]]], 0, 0, 0, 0], "text-success"], [], ["loc", [null, [55, 19], [55, 52]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["mailings.header"], [], ["loc", [null, [56, 16], [56, 39]]], 0, 0], ["inline", "t", ["mailings.optOutLink"], [], ["loc", [null, [56, 41], [56, 68]]], 0, 0], ["block", "unless", [["get", "showAll", ["loc", [null, [58, 22], [58, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [58, 12], [61, 23]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child5 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 68,
                "column": 8
              },
              "end": {
                "line": 71,
                "column": 8
              }
            },
            "moduleName": "hc2-ui/templates/components/apps/email-placeholder-info.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "glyphicon glyphicon-ok");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 76,
                "column": 8
              },
              "end": {
                "line": 79,
                "column": 8
              }
            },
            "moduleName": "hc2-ui/templates/components/apps/email-placeholder-info.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "glyphicon glyphicon-ok");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 64,
              "column": 4
            },
            "end": {
              "line": 81,
              "column": 4
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/email-placeholder-info.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createElement("code");
          var el4 = dom.createTextNode("{prizeText}");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createElement("code");
          var el4 = dom.createTextNode("{prizeDescription}");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(fragment, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          morphs[2] = dom.createMorphAt(element0, 5, 5);
          morphs[3] = dom.createAttrMorph(element1, 'class');
          morphs[4] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
          morphs[5] = dom.createMorphAt(element1, 5, 5);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["subexpr", "unless", [["get", "showAll", ["loc", [null, [65, 26], [65, 33]]], 0, 0, 0, 0], "text-success"], [], ["loc", [null, [65, 17], [65, 50]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["winWheel.prize.text"], [], ["loc", [null, [66, 12], [66, 39]]], 0, 0], ["block", "unless", [["get", "showAll", ["loc", [null, [68, 18], [68, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [68, 8], [71, 19]]]], ["attribute", "class", ["concat", [["subexpr", "unless", [["get", "showAll", ["loc", [null, [73, 26], [73, 33]]], 0, 0, 0, 0], "text-success"], [], ["loc", [null, [73, 17], [73, 50]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["winWheel.prize.description"], [], ["loc", [null, [74, 12], [74, 46]]], 0, 0], ["block", "unless", [["get", "showAll", ["loc", [null, [76, 18], [76, 25]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [76, 8], [79, 19]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child6 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 85,
              "column": 6
            },
            "end": {
              "line": 88,
              "column": 6
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/email-placeholder-info.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("td");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "glyphicon glyphicon-ok");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("td");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 91,
            "column": 8
          }
        },
        "moduleName": "hc2-ui/templates/components/apps/email-placeholder-info.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("hr");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "class", "glyphicon glyphicon-info-sign text-success");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("table");
        dom.setAttribute(el1, "class", "table");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("thead");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("tbody");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createElement("code");
        var el6 = dom.createTextNode("{0}");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createElement("code");
        var el6 = dom.createTextNode("{participationId}");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [4]);
        var element10 = dom.childAt(element9, [1, 1]);
        var element11 = dom.childAt(element9, [3]);
        var element12 = dom.childAt(element11, [3]);
        var element13 = dom.childAt(element11, [9]);
        var morphs = new Array(14);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]), 2, 2);
        morphs[1] = dom.createMorphAt(dom.childAt(element10, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element10, [3]), 0, 0);
        morphs[3] = dom.createMorphAt(element10, 5, 5);
        morphs[4] = dom.createMorphAt(element11, 1, 1);
        morphs[5] = dom.createAttrMorph(element12, 'class');
        morphs[6] = dom.createMorphAt(dom.childAt(element12, [1]), 0, 0);
        morphs[7] = dom.createMorphAt(element12, 5, 5);
        morphs[8] = dom.createMorphAt(element11, 5, 5);
        morphs[9] = dom.createMorphAt(element11, 6, 6);
        morphs[10] = dom.createMorphAt(element11, 7, 7);
        morphs[11] = dom.createAttrMorph(element13, 'class');
        morphs[12] = dom.createMorphAt(dom.childAt(element13, [1]), 0, 0);
        morphs[13] = dom.createMorphAt(element13, 5, 5);
        return morphs;
      },
      statements: [["inline", "t", ["emails.placeholderInfo"], [], ["loc", [null, [3, 59], [3, 89]]], 0, 0], ["inline", "t", ["emails.placeholder"], [], ["loc", [null, [8, 12], [8, 38]]], 0, 0], ["inline", "t", ["emails.placeholderCode"], [], ["loc", [null, [9, 12], [9, 42]]], 0, 0], ["block", "unless", [["get", "showAll", ["loc", [null, [10, 18], [10, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [10, 8], [13, 19]]]], ["block", "each", [["get", "placeholders", ["loc", [null, [17, 12], [17, 24]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [17, 4], [35, 13]]]], ["attribute", "class", ["concat", [["subexpr", "unless", [["get", "showAll", ["loc", [null, [36, 24], [36, 31]]], 0, 0, 0, 0], "text-success"], [], ["loc", [null, [36, 15], [36, 48]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["emails.placeholderLink"], [], ["loc", [null, [37, 12], [37, 42]]], 0, 0], ["block", "unless", [["get", "showAll", ["loc", [null, [39, 18], [39, 25]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [39, 8], [42, 19]]]], ["block", "if", [["get", "appData.votesNeedEmailActivation", ["loc", [null, [44, 10], [44, 42]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [44, 4], [53, 11]]]], ["block", "if", [["get", "appData.hasMailings", ["loc", [null, [54, 10], [54, 29]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [54, 4], [63, 11]]]], ["block", "if", [["get", "appData.prizes.length", ["loc", [null, [64, 10], [64, 31]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [64, 4], [81, 11]]]], ["attribute", "class", ["concat", [["subexpr", "unless", [["get", "showAll", ["loc", [null, [82, 24], [82, 31]]], 0, 0, 0, 0], "text-success"], [], ["loc", [null, [82, 15], [82, 48]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["participation.id"], [], ["loc", [null, [83, 10], [83, 34]]], 0, 0], ["block", "unless", [["get", "showAll", ["loc", [null, [85, 16], [85, 23]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [85, 6], [88, 17]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  }());
});