define('hc2-ui/routes/backend/apps/details', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    i18n: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord('apps/' + params.app_type, params.app_id);
    },
    afterModel: function afterModel(model) {
      if (!model.get("editable")) {
        this.transitionTo("backend.apps.mine");
      }
      this.set("pageTitle", [model.get("title"), model.get("id"), this.get("i18n").t('app.details')]);
    },

    actions: {
      invokeWindowResize: function invokeWindowResize() {
        window.$(window).trigger('resize');
        window.$(window).trigger('resize');
      },
      setSharingImgUriFromUpload: function setSharingImgUriFromUpload(url) {
        this.currentModel.set('sharingImageUri', url);
      },
      saveAppAndStay: function saveAppAndStay() {
        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          // do nothing
        });

        this.send("saveApp", this.currentModel.get('appType'), this.currentModel, defer);
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set("model", model);
      controller.set("userIntegrations", this.modelFor("backend").userIntegrations);

      var domains = this.modelFor("backend").domains;
      var defaultDomains = domains.filterBy("preferredDefault", true);
      var defaultAppTypeDomains = defaultDomains.filterBy("appType", model.get("appType"));
      var defaultDomain = defaultAppTypeDomains.get("length") > 0 ? defaultAppTypeDomains.get("firstObject") : defaultDomains.get("length") > 0 ? defaultDomains.get("firstObject") : null;

      controller.set("domains", domains);
      controller.set("selectedBaseDomain", defaultDomain.get("domain"));
    }
  });
});