define('hc2-ui/components/apps/integrations/emarsys-field-mapping-control', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    api: Ember.inject.service(),
    i18n: Ember.inject.service(),
    fieldOptions: Ember.computed('mapping.appField', 'emarsysFieldChoices', function () {
      var options = this.get("emarsysFieldChoices").map(function (i) {
        return {
          id: i.id,
          choice: i.choice,
          description: "[" + i.id + "]"
        };
      });
      return options;
    }),
    appField: Ember.computed('mapping.appField', 'rawAppFields', function () {
      var _this = this;

      var appFields = this.get("rawAppFields");
      var appField = null;
      if (appFields) {
        appFields.forEach(function (f) {
          if (f.id == _this.get("mapping.appField.id")) {
            appField = f.field;
          }
        });
      }
      return appField;
    }),
    emarsysFieldChoices: [],
    actions: {
      removeMapping: function removeMapping() {
        this.get("mappings").removeObject(this.get("mapping"));
      }
    },
    hasChoices: Ember.computed("mapping.emarsysField.fieldType", function () {
      var appType = this.get("mapping.emarsysField.fieldType");
      return appType && appType.indexOf("choice") > -1;
    }),
    isText: Ember.computed("mapping.emarsysField.fieldType", function () {
      var appType = this.get("mapping.emarsysField.fieldType");
      return appType && appType.indexOf("text") > -1;
    }),
    updateFieldChoices: function () {
      var _this2 = this;

      var fieldId = this.get("mapping.emarsysField.id");
      if (fieldId && this.get("hasChoices")) {
        this.get("api").request("/emarsys/fieldChoices", { fieldId: fieldId, locale: this.get("i18n.locale") }).then(function (choicesResult) {
          var choices = choicesResult[fieldId];
          _this2.set("emarsysFieldChoices", choices);
          var currentSelection = _this2.get("mapping.emarsysValue");
          if (currentSelection) {
            var currentValueValid = false;
            choices.forEach(function (it) {
              if (it.id === currentSelection) {
                currentValueValid = true;
              }
            });
            if (!currentValueValid) {
              _this2.set("mapping.emarsysValue", null);
            }
          }
        });
      } else {
        this.set("emarsysFieldChoices", []);
        this.set("mapping.emarsysValue", null);
      }
    }.observes("mapping.emarsysField").on("init")

  });
});