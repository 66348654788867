define("hc2-ui/helpers/is-before-now", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var now = new Date();
    if (params[0]) {
      var date = typeof params[0] === "string" ? new Date(params[0]) : params[0];
      return now > date;
    }
    return false;
  });
});