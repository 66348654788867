define('hc2-ui/helpers/tl', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    i18n: Ember.inject.service(),

    compute: function compute(params, interpolations) {
      var locale = params[0];
      var key = params[1];

      return this.get('i18n').tl(locale, key, interpolations);
    }
  });
});