define("hc2-ui/components/apps/countdown-timer", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ["countdown-progress-bar"],

    seconds: 60,
    duration: 0,
    active: true,

    init: function init() {
      this._super.apply(this, arguments);
      this.startCountDown();
    },
    startCountDown: function startCountDown() {
      this.set("duration", 0);
      this.tick();
    },
    tick: function tick() {
      var _this = this;

      var duration = this.get("duration");
      var seconds = this.get("seconds");
      if (this.get("active") && seconds > duration) {
        this.set("duration", duration + 1);
      }
      if (seconds === duration) {
        this.attrs.onDone && this.attrs.onDone(this.get("counterId"));
      } else {
        Ember.run.later(function () {
          _this.tick();
        }, 1000);
      }
    },


    remainingSeconds: Ember.computed("seconds", "duration", function () {
      return this.get("seconds") - this.get("duration");
    }),

    progress: Ember.computed("seconds", "duration", function () {
      return this.get("duration") * 100 / this.get("seconds");
    })

  });
});