define("hc2-ui/templates/components/apps/app-overview", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 93
              },
              "end": {
                "line": 10,
                "column": 141
              }
            },
            "moduleName": "hc2-ui/templates/components/apps/app-overview.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("(");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(")");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["content", "app.owner.company", ["loc", [null, [10, 119], [10, 140]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 10
            },
            "end": {
              "line": 11,
              "column": 10
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/app-overview.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h3");
          dom.setAttribute(el1, "class", "m-t-0 member-name");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(": ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("strong");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [2]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element1, 0, 0);
          morphs[1] = dom.createMorphAt(element2, 0, 0);
          morphs[2] = dom.createMorphAt(element2, 2, 2);
          return morphs;
        },
        statements: [["inline", "t", ["customer"], [], ["loc", [null, [10, 44], [10, 60]]], 0, 0], ["content", "app.owner.username", ["loc", [null, [10, 70], [10, 92]]], 0, 0, 0, 0], ["block", "if", [["get", "app.owner.company", ["loc", [null, [10, 99], [10, 116]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [10, 93], [10, 148]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 10
            },
            "end": {
              "line": 14,
              "column": 10
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/app-overview.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h3");
          dom.setAttribute(el1, "class", "m-t-0 member-name");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(": ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("strong");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element0, 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [2]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["platform"], [], ["loc", [null, [13, 44], [13, 60]]], 0, 0], ["content", "app.owner.platform.name", ["loc", [null, [13, 70], [13, 97]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 20,
            "column": 6
          }
        },
        "moduleName": "hc2-ui/templates/components/apps/app-overview.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "panel widget-member pointer hover-border");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-xs-3");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4, "alt", "avatar 12");
        dom.setAttribute(el4, "class", "pull-left img-responsive");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-xs-9");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4, "class", "m-t-0 member-name");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(": ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("strong");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          \n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "member-job");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("small");
        var el6 = dom.createTextNode("(Id: ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(")");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "icon-calendar c-gray-light p-r-10");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" - ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element4, [1, 1]);
        var element6 = dom.childAt(element4, [3]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element6, [6]);
        var element9 = dom.childAt(element6, [8]);
        var morphs = new Array(10);
        morphs[0] = dom.createElementMorph(element3);
        morphs[1] = dom.createAttrMorph(element5, 'src');
        morphs[2] = dom.createMorphAt(element7, 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element7, [2]), 0, 0);
        morphs[4] = dom.createMorphAt(element6, 3, 3);
        morphs[5] = dom.createMorphAt(element6, 4, 4);
        morphs[6] = dom.createMorphAt(element8, 0, 0);
        morphs[7] = dom.createMorphAt(dom.childAt(element8, [2]), 1, 1);
        morphs[8] = dom.createMorphAt(element9, 2, 2);
        morphs[9] = dom.createMorphAt(element9, 4, 4);
        return morphs;
      },
      statements: [["element", "action", ["viewAppDetails", ["get", "app", ["loc", [null, [1, 80], [1, 83]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 54], [1, 85]]], 0, 0], ["attribute", "src", ["concat", [["get", "app.mainImage", ["loc", [null, [4, 24], [4, 37]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["app.name"], [], ["loc", [null, [7, 40], [7, 56]]], 0, 0], ["content", "app.title", ["loc", [null, [7, 66], [7, 79]]], 0, 0, 0, 0], ["block", "if", [["get", "showUserInfo", ["loc", [null, [9, 16], [9, 28]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [9, 10], [11, 17]]]], ["block", "if", [["get", "showPlatform", ["loc", [null, [12, 16], [12, 28]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [12, 10], [14, 17]]]], ["inline", "t", [["subexpr", "concat", ["app.", ["get", "app.appType", ["loc", [null, [16, 51], [16, 62]]], 0, 0, 0, 0]], [], ["loc", [null, [16, 36], [16, 63]]], 0, 0]], [], ["loc", [null, [16, 32], [16, 65]]], 0, 0], ["content", "app.id", ["loc", [null, [16, 78], [16, 88]]], 0, 0, 0, 0], ["inline", "moment-format", [["get", "app.startDate", ["loc", [null, [17, 79], [17, 92]]], 0, 0, 0, 0], "L LT"], [], ["loc", [null, [17, 63], [17, 101]]], 0, 0], ["inline", "moment-format", [["get", "app.endDate", ["loc", [null, [17, 120], [17, 131]]], 0, 0, 0, 0], "L LT"], [], ["loc", [null, [17, 104], [17, 140]]], 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});