define("hc2-ui/components/apps/copy-for-user", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    config: Ember.inject.service(),
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),

    app: null,
    action: null,
    selectedUser: 0,

    usersForSelection: Ember.computed("users", function () {
      var users = this.get("users");
      var selectableUsers = [];
      selectableUsers.pushObject({ id: 0, label: this.get("i18n").t("user.new") });

      users.forEach(function (user) {
        user.set("label", user.get('username') + " @ " + user.get("platformName"));
        selectableUsers.pushObject(user);
      });
      return selectableUsers;
    }),

    newUser: {},
    passwordConfirm: "",
    passwordsDoNotMatch: Ember.computed("newUser.password", "passwordConfirm", function () {
      return this.get('passwordConfirm') !== this.get('newUser.password') && this.get('newUser.password');
    }),

    actions: {
      createAppAndUser: function createAppAndUser() {
        if (!this.get('passwordsDoNotMatch')) {
          var data = {
            user: this.get('newUser'),
            appToCopy: this.get('app.id'),
            platform: this.get('config').setting('platform')
          };
          var action = this.get('store').createRecord("action", { action: "createUserAndCopyApp", context: data });
          this.set("action", action);

          action.save();
        }
      },
      createAppForUser: function createAppForUser() {
        var data = {
          user: { id: this.get('selectedUser') },
          appToCopy: this.get('app.id'),
          platform: this.get('config').setting('platform')
        };
        var action = this.get('store').createRecord("action", { action: "createUserAndCopyApp", context: data });
        this.set("action", action);

        action.save();
      }
    }

  });
});