define("hc2-ui/models/action", ["exports", "ember-data"], function (exports, _emberData) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        action: _emberData.default.attr("string"),
        context: _emberData.default.attr()
    });
});