define("hc2-ui/templates/components/apps/integrations/emarsys-field-mapping", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 0
            },
            "end": {
              "line": 16,
              "column": 0
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/integrations/emarsys-field-mapping.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "apps/integrations/emarsys-field-mapping-control", [], ["appFields", ["subexpr", "@mut", [["get", "appFields", ["loc", [null, [11, 14], [11, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "rawAppFields", ["subexpr", "@mut", [["get", "rawAppFields", ["loc", [null, [12, 17], [12, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "emarsysFields", ["subexpr", "@mut", [["get", "emarsysFields", ["loc", [null, [13, 18], [13, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "mappings", ["subexpr", "@mut", [["get", "mappings", ["loc", [null, [14, 13], [14, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "mapping", ["subexpr", "@mut", [["get", "mapping", ["loc", [null, [15, 12], [15, 19]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [10, 2], [15, 21]]], 0, 0]],
        locals: ["mapping"],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 33,
            "column": 6
          }
        },
        "moduleName": "hc2-ui/templates/components/apps/integrations/emarsys-field-mapping.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1, "class", "pull-right pointer");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "class", "glyphicon glyphicon-plus");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h3");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "m-b-10");
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "class", "glyphicon glyphicon-info-sign text-success");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form-group");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "option");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3, "class", "switch-description-label");
        var el4 = dom.createElement("strong");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3, "class", "switch");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "switch-label");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "switch-handle");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "m-t-10");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2, "class", "btn btn-success");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [8, 1]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var element4 = dom.childAt(element3, [3]);
        var element5 = dom.childAt(fragment, [10, 1]);
        var morphs = new Array(12);
        morphs[0] = dom.createAttrMorph(element0, 'onclick');
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [2]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(fragment, [4]), 1, 1);
        morphs[4] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[5] = dom.createMorphAt(dom.childAt(element2, [0]), 0, 0);
        morphs[6] = dom.createMorphAt(element2, 2, 2);
        morphs[7] = dom.createMorphAt(element3, 1, 1);
        morphs[8] = dom.createAttrMorph(element4, 'data-on');
        morphs[9] = dom.createAttrMorph(element4, 'data-off');
        morphs[10] = dom.createAttrMorph(element5, 'onclick');
        morphs[11] = dom.createMorphAt(element5, 0, 0);
        return morphs;
      },
      statements: [["attribute", "onclick", ["subexpr", "action", ["addMapping"], [], ["loc", [null, [null, null], [1, 61]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["mapping.add"], [], ["loc", [null, [3, 4], [3, 23]]], 0, 0], ["inline", "t", ["emarsys.mappingHeader"], [], ["loc", [null, [6, 4], [6, 33]]], 0, 0], ["inline", "t", ["emarsys.mappingInfoText"], [], ["loc", [null, [7, 78], [7, 109]]], 0, 0], ["block", "each", [["get", "mappings", ["loc", [null, [9, 8], [9, 16]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [9, 0], [16, 9]]]], ["inline", "t", ["emarsys.importOnParticipation"], [], ["loc", [null, [20, 52], [20, 89]]], 0, 0], ["inline", "-el/tooltip", [], ["class", "", "text", ["subexpr", "t", ["emarsys.importOnParticipationDescription"], [], ["loc", [null, [21, 34], [21, 80]]], 0, 0]], ["loc", [null, [21, 6], [21, 82]]], 0, 0], ["inline", "input", [], ["type", "checkbox", "checked", ["subexpr", "@mut", [["get", "appIntegrations.emarsys.importOnParticipation", ["loc", [null, [24, 38], [24, 83]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "switch-input", "change", ["subexpr", "action", [["subexpr", "mut", [["get", "appIntegrations.emarsys.importOnParticipation", ["loc", [null, [24, 125], [24, 170]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 120], [24, 171]]], 0, 0]], ["value", "target.checked"], ["loc", [null, [24, 112], [24, 195]]], 0, 0]], ["loc", [null, [24, 6], [24, 197]]], 0, 0], ["attribute", "data-on", ["concat", [["subexpr", "t", ["yes"], [], ["loc", [null, [25, 42], [25, 53]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-off", ["concat", [["subexpr", "t", ["no"], [], ["loc", [null, [25, 65], [25, 75]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["saveMapping"], [], ["loc", [null, [null, null], [32, 59]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["emarsys.saveMapping"], [], ["loc", [null, [32, 60], [32, 87]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  }());
});