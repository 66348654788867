define("hc2-ui/helpers/has-html-content", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hasHtmlContent = hasHtmlContent;
  exports.default = Ember.Helper.helper(function (params) {
    return hasHtmlContent(params[0]);
  });
  function hasHtmlContent(string) {
    if ("<p><br></p>" === string || !string) {
      return false;
    }
    return true;
  }
});