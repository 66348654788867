define('hc2-ui/components/-el/tooltip', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['tooltip-wrapper'],
    iconClass: "glyphicon glyphicon-info-sign text-success",

    mouseLeave: function mouseLeave() {
      this.$(".tooltip-box").addClass("hidden");
    },
    mouseEnter: function mouseEnter() {
      this.$(".tooltip-box").removeClass("hidden");
    }
  });
});