define('hc2-ui/services/device', ['exports', 'hc2-ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    deviceOrientation: Ember.computed("windowOrientation", "parentWindowOrientation", "paramOrientation", function () {
      return this.get("paramOrientation") || this.get("parentWindowOrientation") || this.get("windowOrientation");
    }),

    windowOrientation: "landscape",
    parentWindowOrientation: null,
    paramOrientation: null,

    init: function init() {
      var _this = this;

      this.updateDeviceOrientation();
      window.addEventListener("resize", function () {
        _this.updateDeviceOrientation();
      });
    },
    updateDeviceOrientation: function updateDeviceOrientation() {
      this.set("windowOrientation", $(window).width() > $(window).height() ? "landscape" : "portrait");
    }
  });
});