define("hc2-ui/lib/make/init", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {

        material: function material() {

            window.$('.floating-label').each(function (i, e) {

                // update floating label
                var floatingLabel = $(e);
                if (floatingLabel.prev().attr("placeholder")) {
                    floatingLabel.html(floatingLabel.prev().attr("placeholder"));
                    floatingLabel.prev().attr("placeholder", "");
                }
            });

            window.$.material.init();
            window.$(window).trigger('resize');
        },
        /* jshint ignore:start */
        application: function application() {

            // reinit globals
            $sidebar = $('.sidebar');
            $sidebarFooter = $('.sidebar .sidebar-footer');
            $mainContent = $('.main-content');
            $pageContent = $('.page-content');
            $topbar = $('.topbar');
            $logopanel = $('.logopanel');
            $sidebarWidth = $(".sidebar").width();
            content = document.querySelector('.page-content');
            $loader = $('#preloader');
            docHeight = $(document).height();
            windowHeight = $(window).height();
            topbarWidth = $('.topbar').width();
            headerLeftWidth = $('.header-left').width();
            headerRightWidth = $('.header-right').width();
            start = delta = end = 0;

            $('[data-toggle]').on('click', function (event) {
                event.preventDefault();
                var toggleLayout = $(this).data('toggle');
                if (toggleLayout == 'rtl') toggleRTL();
                if (toggleLayout == 'sidebar-behaviour') toggleSidebar();
                if (toggleLayout == 'submenu') toggleSubmenuHover();
                if (toggleLayout == 'sidebar-collapsed') collapsedSidebar();
                if (toggleLayout == 'sidebar-top') toggleSidebarTop();
                if (toggleLayout == 'sidebar-hover') toggleSidebarHover();
                if (toggleLayout == 'boxed') toggleboxedLayout();
                if (toggleLayout == 'topbar') toggleTopbar();
            });

            $('.toggle_fullscreen').click(function () {
                toggleFullScreen();
            });

            // Add class everytime a mouse pointer hover over it
            var hoverTimeout;
            $('.nav-sidebar > li').hover(function () {
                clearTimeout(hoverTimeout);
                $(this).siblings().removeClass('nav-hover');
                $(this).addClass('nav-hover');
            }, function () {
                var $self = $(this);
                hoverTimeout = setTimeout(function () {
                    $self.removeClass('nav-hover');
                }, 200);
            });
            $('.nav-sidebar > li .children').hover(function () {
                clearTimeout(hoverTimeout);
                $(this).closest('.nav-parent').siblings().removeClass('nav-hover');
                $(this).closest('.nav-parent').addClass('nav-hover');
            }, function () {
                var $self = $(this);
                hoverTimeout = setTimeout(function () {
                    $(this).closest('.nav-parent').removeClass('nav-hover');
                }, 200);
            });

            // Check if sidebar is collapsed
            if ($('body').hasClass('sidebar-collapsed')) $('.nav-sidebar .children').css({
                display: ''
            });
            // Handles form inside of dropdown
            $('.dropdown-menu').find('form').click(function (e) {
                e.stopPropagation();
            });

            initApplication();

            if ($('body').hasClass('sidebar-hover')) sidebarHover();

            /****  Resize Event Functions  ****/

            $(window).resize(function () {
                setTimeout(function () {
                    customScroll();
                    reposition_topnav();
                    if (!$('body').hasClass('fixed-sidebar') && !$('body').hasClass('builder-admin')) sidebarBehaviour();
                    handleboxedLayout();
                    maximizePanel();
                }, 100);
            });
        }
        /* jshint ignore:end */
    };
});