define('hc2-ui/controllers/backend/mailer', ['exports', 'hc2-ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    api: Ember.inject.service(),
    store: Ember.inject.service(),
    apiHost: _environment.default.oauth.host,
    i18n: Ember.inject.service(),

    testRecipient: "admin@happy-contests.de",

    actions: {
      sendMails: function sendMails() {
        var action = this.get('store').createRecord("action", {
          action: this.get("voteIds") ? "sendMailsForVotes" : "sendMailsForParticipants",
          context: {
            subject: this.get('subject'),
            template: this.get('template'),
            participationIds: this.get('participationIds'),
            testRecipient: this.get('testRecipient')
          }
        });
        action.save().then(function () {
          alert("done");
        }, function () {
          alert("action failed");
        });
      }
    }
  });
});