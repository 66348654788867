define("hc2-ui/components/apps/forms/welcome-page", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    editableAccessCodeLabels: ["welcomePage_accessCode_code", "codePromotion_invalidCode"],
    actions: {
      setWelcomePageImgUriFromUpload: function setWelcomePageImgUriFromUpload(url) {
        this.set('appData.welcomePageImgUri', url);
      }
    }
  });
});