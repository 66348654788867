define("hc2-ui/components/controls/doi-opt-in", ["exports", "hc2-ui/lib/make/init"], function (exports, _init) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didRender: function didRender() {
      _init.default.material();
    }
  });
});