define("hc2-ui/components/apps/body-challenge/participation-view", ["exports", "hc2-ui/lib/make/init"], function (exports, _init) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        participation: {},

        didRender: function didRender() {
            _init.default.material(this.elementId);
        },

        actions: {}
    });
});