define("hc2-ui/components/controls/css-editor", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    value: "",
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get("value")) {
        this.set("value", "");
      }
    },

    actions: {
      suggestCompletions: function suggestCompletions() /*editor, session, position, prefix*/{
        return [{ value: '.app-view', meta: 'Main App Container' }, {
          value: 'cc-door-specific',
          meta: "a specific christmas-calendars door",
          snippet: ".christmasCalendar-view .calendar .door-container .door.door-${1} {\n\t${2} \n}"
        }];
      }
    }
  });
});