define('hc2-ui/controllers/backend/apps/details', ['exports', 'hc2-ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    config: _environment.default,

    store: Ember.inject.service(),
    i18n: Ember.inject.service(),
    customDomainFormVisible: false,
    customDomainFormComplete: Ember.computed("customDomain", "selectedBaseDomain", function () {
      var domainName = this.get("customDomain");
      var selectedBaseDomain = this.get("selectedBaseDomain");
      return domainName && selectedBaseDomain;
    }),
    customDomain: "",
    customDomainToLower: function () {
      var customDomain = this.get("customDomain");
      if (customDomain) {
        var low = customDomain.toLowerCase();
        if (low !== customDomain) {
          this.set("customDomain", low);
        }
      }
    }.observes("customDomain"),

    actions: {
      deleteApp: function deleteApp(appData) {
        var _this = this;

        if (appData.get("id") == window.prompt(this.get("i18n").t("editor.deleteAppWarningWithIdPrompt", { title: appData.get("title"), id: appData.get("id") }))) {
          appData.destroyRecord().then(function () {
            window.alert(_this.get("i18n").t("editor.deleteAppSuccess"));
            _this.transitionToRoute("backend.apps.mine");
          }).catch(function () {
            window.alert(_this.get("i18n").t("editor.deleteAppError"));
          });
        } else {
          window.alert(this.get("i18n").t("editor.deleteAppAbort"));
        }
      },

      toggleActivation: function toggleActivation(appData) {
        appData.set("active", !appData.get("active"));
        var action = this.get('store').createRecord("action", { action: "activateApp", context: { id: appData.get('id'), value: appData.get('active') } });
        action.save().then(function () {}, function () {
          alert("action failed");
        });
      },
      toggleUUID: function toggleUUID(appData) {
        appData.set("externalUUID", !appData.get("externalUUID"));
        this.send('saveAppAndStay');
      },
      toggleZipExportEnabled: function toggleZipExportEnabled(appData) {
        appData.set("allowZipExport", !appData.get("allowZipExport"));
        var action = this.get('store').createRecord("action", { action: "allowZipExportForApp", context: { id: appData.get('id'), value: appData.get('allowZipExport') } });
        action.save().then(function () {}, function () {
          alert("action failed");
        });
      },
      setDomain: function setDomain() {
        var _this2 = this;

        this.set("setDomainError", false);
        var domainName = this.get("customDomain");
        var selectedBaseDomain = this.get("selectedBaseDomain");
        if (!domainName || !selectedBaseDomain) {
          return;
        }
        domainName = domainName.toLowerCase();
        this.get("model.customDomain").then(function (appDomain) {
          if (!appDomain) {
            appDomain = _this2.get("store").createRecord('appDomain', { app: _this2.get("model") });
          }
          appDomain.set("domain", domainName + "." + selectedBaseDomain);
          appDomain.save().then(function () {
            _this2.set("customDomain", "");
            _this2.set("customDomainFormVisible", false);
          }).catch(function () {
            _this2.set("setDomainError", true);
            appDomain.rollbackAttributes();
          });
        });
      },
      deleteDomain: function deleteDomain(domain) {
        domain.then(function (record) {
          record.destroyRecord();
        });
      }
    },
    showIntegrations: Ember.computed("userIntegrations.available", "appData.parentApp", function () {
      return this.get("userIntegrations.available") > 0 && !this.get("appData.parentApp");
    })
  });
});