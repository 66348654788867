define('hc2-ui/helpers/format-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var n = params[0];
    if (!isNaN(parseFloat(n)) && isFinite(n)) {
      return Number(n).toFixed(2);
    }
    return n;
  });
});