define("hc2-ui/components/apps/contest/app-view", ["exports", "hc2-ui/lib/make/init"], function (exports, _init) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    config: Ember.inject.service(),
    embed: Ember.inject.service(),
    tracking: Ember.inject.service(),

    participationModel: 'contest-participation',

    quickSearchVal: "",
    sortValues: [{ id: "dateCreated" }, { id: "title" }, { id: "rating" }, { id: "views" }],
    sortValue: "dateCreated",
    sortDirection: "desc",
    sortIconType: Ember.computed("sortValue", function () {
      var sortValue = this.get("sortValue");
      switch (sortValue) {
        case 'title':
          return "alpha";
        case 'rating':
        case 'views':
          return "numeric";
        default:
          return "amount";
      }
    }),
    categoryValue: null,
    categorySelectValues: Ember.computed("appData.categories", "i18n.appLocale", function () {
      var categories = this.get("appData.categories");
      var values = [];
      for (var i = 0; i < categories.length; i++) {
        values.push({ id: categories[i].value, label: this.get("i18n").tu('category_' + categories[i].value, categories[i].value) });
      }
      return values;
    }),

    appData: {},
    participations: null,
    participationsPerRow: 4,
    participationGroups: null,
    groupedParticipations: Ember.computed('participations.[]', function () {

      var participations = this.get('participations'),
          len = this.get('participations.length');

      // initializing participation groups
      if (this.get("participationGroups") == null || participations == null || len === 0) {
        this.set("participationGroups", []);
      }

      var groups = this.get("participationGroups"),
          group = groups.length > 0 ? groups[groups.length - 1] : null,
          perRow = this.get('participationsPerRow');

      var participationsSet = groups.length * perRow;
      if (group) {
        participationsSet -= perRow;
        participationsSet += group.length;
      }

      for (var i = participationsSet; i < len; i++) {
        if (i % perRow === 0) {
          group = [];
          groups.pushObject(group);
        }
        group.pushObject(participations[i]);
      }
      return groups;
    }),

    loadingParticipations: false,
    allParticipationsLoaded: false,
    editMode: false,
    showOverview: true,
    appStates: {
      showWelcomePage: false,
      selectedTab: null,
      selectedParticipation: null
    },
    lastOverviewScrollPosition: 0,
    selectedParticipationIndex: Ember.computed('appStates.selectedParticipation', function () {
      return this.get('participations').indexOf(this.get('appStates.selectedParticipation'));
    }),
    active: Ember.computed('appData.startDate', 'appData.endDate', function () {
      var now = new Date();
      return now > this.get('appData.startDate') && now < this.get('appData.endDate');
    }),

    showHeaderImage: Ember.computed('appData.headerImageUri', 'appStates.selectedTab', 'appStates.selectedParticipation', function () {
      return this.get("appData.headerImageUri") && this.get("appStates.selectedTab") !== 'participate' && (this.get("appStates.selectedTab") === 'overview' ? !this.get('appStates.selectedParticipation') : true);
    }),

    showTabs: true,
    init: function init() {
      this._super();
      this.set('participations', []);

      this.set("appStates.showWelcomePage", this.get('appData.welcomePage') && !this.get('editMode'));
      this.set("appStates.selectedTab", this.get("appData.descriptionHeader") ? 'description' : 'overview');
      if (!this.get('editMode') && this.get('appData.id')) {
        this.get('store').createRecord("view", { app: this.get('appData') }).save();
      }

      // show participation? requested
      var that = this;
      if (this.get("contentId")) {
        this.set("appStates.showWelcomePage", false);
        this.set("appStates.selectedTab", 'overview');
        this.get('store').findRecord(this.get('participationModel'), this.get("contentId")).then(function (participation) {
          that.viewParticipation(participation);
        });
      }
    },
    contentIdChange: Ember.observer("contentId", function () {
      var _this = this;

      var contentId = this.get("contentId");
      if (contentId && this.get("appStates.selectedParticipation.id") != contentId) {
        this.get('store').findRecord(this.get('participationModel'), this.get("contentId")).then(function (participation) {
          _this.viewParticipation(participation);
        });
      } else if (!contentId) {
        this.viewParticipation(null);
      }
    }),
    tabChange: Ember.observer("appStates.selectedTab", function () {
      var _this2 = this;

      Ember.run.later(function () {
        _this2.set("tab", _this2.get("appStates.selectedTab"));
      });
    }).on("init"),

    actions: {
      unselectCategory: function unselectCategory() {
        this.set("categoryValue", null);
        this.loadParticipations(true);
      },
      search: function search() {
        this.loadParticipations(true);
      },
      searchWithNewSort: function searchWithNewSort(sortValue) {
        this.set("sortDirection", sortValue === 'title' ? 'asc' : 'desc');
        this.loadParticipations(true);
      },
      toggleSortDirection: function toggleSortDirection() {
        var sort = this.get('sortDirection');
        this.set('sortDirection', sort === 'desc' ? 'asc' : 'desc');
        this.loadParticipations(true);
      },
      hideWelcomePage: function hideWelcomePage() {
        this.get("embed").notifyStepChange();
        this.set("appStates.showWelcomePage", false);
      },
      setSelectedTab: function setSelectedTab(tabname) {
        if (tabname === 'overview') {
          this.set('appStates.selectedParticipation', null);
          this.set('contentId', null);
        }
        this.set("appStates.selectedTab", tabname);
      },
      loadMoreParticipations: function loadMoreParticipations() {
        this.loadParticipations();
      },
      selectParticipation: function selectParticipation(participation) {
        if (Ember.typeOf(participation) === 'string') {
          if (participation === 'prev') {
            participation = this.get('participations').objectAt(this.get('selectedParticipationIndex') - 1);
          } else if (participation === 'next') {
            participation = this.get('participations').objectAt(this.get('selectedParticipationIndex') + 1);
          }
        }
        if (this.get('participations').indexOf(participation) >= 0) {
          this.viewParticipation(participation);
        }

        if (this.get('selectedParticipationIndex') === this.get('participations.length') - 1) {
          this.loadParticipations();
        }
      },
      viewParticipation: function viewParticipation(participation) {
        this.viewParticipation(participation);
      },
      participationSubmitted: function participationSubmitted() /*participation*/{
        // reload participations after submitting one
        this.set('participations', []);
        this.loadParticipations();
      },
      backToOverview: function backToOverview() {
        this.viewParticipation(null);
        var scrollTo = this.get('lastOverviewScrollPosition');
        if (scrollTo) {
          setTimeout(function () {
            window.$("html, body").animate({ scrollTop: scrollTo + "px" });
          }, 500);
        }
      }
    },
    viewParticipation: function viewParticipation(participation) {
      if (!this.get('appStates.selectedParticipation')) {
        this.set('lastOverviewScrollPosition', document.documentElement.scrollTop || document.body.scrollTop);
      }
      this.set('appStates.selectedParticipation', participation);
      this.set("contentId", participation === null ? null : participation.id);

      // send participation view
      if (!this.get('editMode') && participation) {
        this.get('store').createRecord("view", { app: this.get('appData'), participation: participation }).save().then(function () {
          participation.set("views", participation.get('views') + 1);
        });
      }
      this.get("embed").sendContentIdChange(participation ? participation.id : null);
    },

    lazyLoadImages: function lazyLoadImages() {
      window.$(".lazy-load-img:visible img").each(function (k, v) {
        window.$(v).attr("src", window.$(v).attr("data-src"));
      });
    },

    didRender: function didRender() {
      //MakeInit.material(this.elementId);
      this.lazyLoadImages();
      this.get("tracking").initLinkTracking();
    },
    didInsertElement: function didInsertElement() {
      _init.default.material(this.elementId);
      window.$(window).resize(this.lazyLoadImages);

      // show event popup
      if (this.get('config').setting("event") === "voteActivated") {
        window.$('#' + this.elementId + '-voteActivated-popup').modal();
      }

      //$('.participateTabButton').click()
    },
    reloadOnMaxPerLoadChange: function () {
      this.loadParticipations(true);
    }.observes("appData.maxPerLoad"),
    loadParticipations: function (reset) {
      if (reset) {
        this.set('allParticipationsLoaded', false);
        this.set('participations', []);
      }

      if (this.get('participations') == null) {
        this.set('participations', []);
      }

      this.set('loadingParticipations', true);
      var that = this;
      var appId = this.get('appData.id');
      var maxPerLoad = this.get("appData.maxPerLoad") || 12;
      var offset = this.get('participations.length');
      var quickSearch = this.get("quickSearchVal");
      var sort = this.get("sortValue");
      var sortDirection = this.get("sortDirection");
      var category = this.get("categoryValue");
      this.get('store').query(this.get('participationModel'), { category: category, quickSearch: quickSearch, offset: offset, max: maxPerLoad, sort: sort, order: sortDirection, appId: appId }).then(function (loadedParticipations) {
        var pa = loadedParticipations.toArray();
        that.get('participations').pushObjects(pa);
        that.set('loadingParticipations', false);
        if (pa.length < maxPerLoad) {
          that.set('allParticipationsLoaded', true);
        }
      });
    }.on("init")
  });
});