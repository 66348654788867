define("hc2-ui/helpers/current-host", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params, hash) {
    var hostname = location.hostname;
    if (hash && hash.withProtocol) {
      hostname = location.protocol + "//" + hostname;
      if (location.port !== "80" && location.port !== "443") {
        hostname += ":" + location.port;
      }
    }
    return hostname;
  });
});