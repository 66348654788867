define('hc2-ui/components/games/video-game', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    games: Ember.inject.service(),
    classNames: ['game-container', 'row'],
    classNameBindings: ['gameClassName'],
    gameClassName: Ember.computed("config.gameId", function () {
      return 'game-' + this.get("config.gameId");
    }),
    aspectRatioPadding: Ember.computed("selectedGame.resolution", function () {
      // see https://www.w3schools.com/howto/howto_css_aspect_ratio.asp
      var resolution = this.get("selectedGame.resolution");
      if (!resolution) {
        resolution = "16x9"; // 16:9 aspect ratio
      }
      var resolutionArray = resolution.split("x");
      return resolutionArray[1] / resolutionArray[0] * 100;
    }),

    selectedGame: Ember.computed("config.gameId", function () {
      return this.get("games").getGameById(this.get("config.gameId"));
    }),

    duration: 0,

    gameRunning: Ember.computed("gameStarted", "gameEnded", function () {
      return this.get("gameStarted") && !this.get("gameEnded");
    }),

    actions: {
      start: function start(card) {
        this.set("gameStarted", true);
        if (this.get("gameEnded")) {
          return false;
        }
      },
      proceed: function proceed() {
        this.attrs.onDone && this.attrs.onDone(this.get("gameResults"));
      },
      repeat: function repeat() {
        this.set("showGameOverlay", false);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      // listen to messages from iframe
      var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
      var eventer = window[eventMethod];
      var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
      eventer(messageEvent, function (e) {

        var eventName = e.data.name;
        var eventData = e.data.payload;

        if (window.location.origin === e.origin) {
          console.log(e.origin, eventName, eventData);
          if (eventName === "gamePlayed") {
            _this.set("gameResults", eventData);
            _this.set("showGameOverlay", true);
          }
        }
      });
    }
  });
});