define("hc2-ui/components/apps/slot-machine/combination", ["exports", "hc2-ui/lib/make/init", "hc2-ui/helpers/has-html-content"], function (exports, _init, _hasHtmlContent) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),

    slotCountArr: Ember.computed('slotCount', function () {
      return new Array(parseInt(this.get('slotCount'))).fill().map(function (v, i) {
        return {
          id: i,
          text: i + 1
        };
      });
    }),
    comboType: Ember.computed('i18n.locale', function () {
      return [{ id: 'same', label: this.get('i18n').t('slotMachine.combinations.typeSame') }, { id: 'diff', label: this.get('i18n').t('slotMachine.combinations.typeDiff') }, { id: 'free', label: this.get('i18n').t('slotMachine.combinations.typeFree') }];
    }),
    itemOptions: Ember.computed('items.[]', function () {
      var items = Ember.A();
      var pairs = this.get('items');
      if (pairs.length > 0) {
        for (var i = 0; i < pairs.length; i++) {
          items.pushObject({
            name: pairs[i].name,
            html: Ember.String.htmlSafe('' + '<div class="slot-combo-select-option">' + ' <img src="' + pairs[i].img + '"/>' + ' <span class="name">' + pairs[i].name + '</span>' + ' <span class="nr">' + (i + 1) + '</span>' + '</div>'),
            index: i,
            id: i
          });
        }
      }
      items.pushObject({
        name: '*',
        html: Ember.String.htmlSafe('' + '<div class="slot-combo-select-option slot-combo-select-option-any">' + ' <span class="name"><i class="fa fa-asterisk"/></span>' + ' <span class="nr">' + this.get("i18n").t('any') + '</span>' + '</div>'),
        index: -1,
        id: -1
      });
      return items;
    }),
    actions: {
      setItem: function setItem(index, selection) {
        var val = selection.index;
        this.get('combo.items').set("" + index, val);
        this.reassignCombinations();
      },
      remove: function remove(index) {
        this.removeCombo(index);
      },
      didSelectPrizes: function didSelectPrizes(prizes) {
        this.set('combo.prizes', prizes);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('combo.count')) {
        this.set('combo.count', this.get('slotCount'));
      }
      if (!this.get('combo.items')) {
        this.set('combo.items', []);
      }

      for (var i = 0; i < this.get('slotCount'); i++) {
        if (this.get('combo.items').objectAt(i) === undefined) {
          this.get('combo.items').insertAt(i, -1);
        }
      }
    }
  });
});