define("hc2-ui/components/ml/app-locale-default-selector", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    actions: {
      toggleLocale: function toggleLocale(locale) {
        this.set("app.defaultLocale", locale);
        this.set("i18n.appLocale", locale);
      }
    }
  });
});