define('hc2-ui/helpers/ta', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    i18n: Ember.inject.service(),

    _locale: Ember.computed.readOnly('i18n.locale'),
    _appLocale: Ember.computed.readOnly('i18n.appLocale'),
    _appTranslations: Ember.computed.readOnly('i18n.app.translations'),

    compute: function compute(params, interpolations) {
      var key = params[0];

      var appLocale = this.get("_appLocale");

      // check app translations
      var translations = this.get("_appTranslations");
      if (params[1]) {
        translations = params[1].get("translations");
      }
      if (translations) {
        var translationsKey = appLocale + "_" + key.replace(/\./g, "_");
        Ember.addObserver(translations, translationsKey, this, this.recompute);
      }

      return this.get('i18n').ta(key, interpolations, translations);
    },

    _recomputeOnLocaleChange: Ember.observer('_locale', '_appLocale', '_appTranslations', function () {
      this.recompute();
    })
  });
});