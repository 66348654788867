define("hc2-ui/templates/components/controls/add-to-any", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 11,
              "column": 0
            }
          },
          "moduleName": "hc2-ui/templates/components/controls/add-to-any.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment(" AddToAny BEGIN ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "class", "a2a_button_facebook");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "class", "a2a_button_twitter");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "class", "a2a_button_google_plus");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "class", "a2a_button_whatsapp");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "class", "a2a_dd");
          dom.setAttribute(el2, "href", "https://www.addtoany.com/share");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" AddToAny END ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [2]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createAttrMorph(element0, 'data-a2a-url');
          morphs[2] = dom.createAttrMorph(element0, 'data-a2a-title');
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["a2a_kit a2a_kit_size_32 a2a_default_style share-buttons share-", ["get", "app.appType", ["loc", [null, [3, 76], [3, 87]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-a2a-url", ["concat", [["subexpr", "share-url", [["subexpr", "if", [["get", "app.parentApp", ["loc", [null, [3, 121], [3, 134]]], 0, 0, 0, 0], ["get", "app.parentApp.shareDomain", ["loc", [null, [3, 135], [3, 160]]], 0, 0, 0, 0], ["get", "app.shareDomain", ["loc", [null, [3, 161], [3, 176]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 117], [3, 177]]], 0, 0], ["get", "contentId", ["loc", [null, [3, 178], [3, 187]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 105], [3, 189]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-a2a-title", ["concat", [["subexpr", "share-title", [["subexpr", "if", [["get", "app.parentApp", ["loc", [null, [3, 225], [3, 238]]], 0, 0, 0, 0], ["get", "app.parentApp", ["loc", [null, [3, 239], [3, 252]]], 0, 0, 0, 0], ["get", "app", ["loc", [null, [3, 253], [3, 256]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 221], [3, 257]]], 0, 0]], [], ["loc", [null, [3, 207], [3, 259]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 7
          }
        },
        "moduleName": "hc2-ui/templates/components/controls/add-to-any.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "is-or", [["get", "app.showSharingButtons", ["loc", [null, [1, 13], [1, 35]]], 0, 0, 0, 0], ["get", "app.parentApp.showSharingButtons", ["loc", [null, [1, 36], [1, 68]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 6], [1, 69]]], 0, 0]], [], 0, null, ["loc", [null, [1, 0], [11, 7]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});