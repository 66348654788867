define('hc2-ui/components/apps/copy-from-apps-selection', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PROPERTIES_TO_COPY = ["showTitle", "headerImageUri", "showHeader", "headerContent", "conditions", "impressum", "noForm", "participationsNeedCaptcha", "allowMultipleParticipations", "description", "descriptionHeader", "additionalInformation", "additionalInformationHeader", "footer", "welcomePage", "welcomePageImgUri", "welcomePageText", "welcomePageNextBtnText", "showCountdown", "countDownDuration", "showSharingButtons", "facebookLikeUrl", "questions", "results", "pairs", "cardBackgroundImageUri", "prizes", "config", "showQuestionSteps", "contactData", "contactFormText", "thanksText", "translations", "customStyles", "winWheelImageUrl", "winWheelBackgroundImageUrl", "drawText", "showBorder", "spinDuration"];

  exports.default = Ember.Component.extend({
    actions: {
      copyAppProperties: function copyAppProperties() {
        var selectedApp = this.get("appsSelection").findBy("id", this.get("selectedAppId"));
        var appData = this.get("appData");

        for (var i = 0; i < PROPERTIES_TO_COPY.length; i++) {
          var propertyName = PROPERTIES_TO_COPY[i];
          var propertyValue = selectedApp.get(PROPERTIES_TO_COPY[i]);
          if (propertyValue !== undefined && propertyValue !== null) {
            propertyValue = Ember.copy(propertyValue, true);
            appData.set(propertyName, propertyValue);
          }
        }
        this.set("selectedAppId", null);
        this.set("copiedFromAppName", selectedApp.get("title"));
      }
    }
  });
});