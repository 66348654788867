define('hc2-ui/controllers/backend/user', ['exports', 'hc2-ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    api: Ember.inject.service(),
    store: Ember.inject.service(),
    apiHost: _environment.default.oauth.host,
    i18n: Ember.inject.service(),

    isSuperAdmin: Ember.computed('model.userData.userAuthorities.[]', 'model.userData.userAuthorities.[]', function () {
      return this.get('model.userData.userAuthorities').includes('ROLE_ADMIN');
    }),
    isPlatformAdmin: Ember.computed('model.userData.userAuthorities.[]', 'model.userData.userAuthorities.[]', function () {
      return this.get('model.userData.userAuthorities').includes('ROLE_PLATFORM');
    }),

    visibleAuthorities: [{ name: 'Global Admin', role: 'ROLE_ADMIN', editable: false }, { name: 'Platform Admin', role: 'ROLE_PLATFORM', editable: true }, { name: 'Beta-Funktionen', role: 'ROLE_BETA', editable: true }, { name: 'DOI-Forced-Option', role: 'ROLE_DOI_FORCED', editable: true }, { name: 'EXTERNAL-UUID', role: 'ROLE_EXTERNAL_UUID', editable: true }, { name: 'PARTICIPATION_NOTICICATIONS', role: 'ROLE_PARTICIPATION_NOTICICATIONS', editable: true }, { name: 'CONTEST_HIGHLIGHT_TOP_VOTES', role: 'ROLE_CONTEST_HIGHLIGHT_TOP_VOTES', editable: true }, { header: 'platformIntegrations' }, { name: 'Google Analytics', role: 'ROLE_INTEGRATIONS_PLATFORM_GA', editable: true }, { name: 'Emarsys', role: 'ROLE_INTEGRATIONS_PLATFORM_EMARSYS', editable: true }, { name: 'Episerver', role: 'ROLE_INTEGRATIONS_PLATFORM_EPISERVER', editable: true }, { name: 'Facebook Pixel', role: 'ROLE_INTEGRATIONS_PLATFORM_FACEBOOKPIXEL', editable: true }, { header: 'userIntegrations' }, { name: 'Mailchimp', role: 'ROLE_INTEGRATIONS_USER_MAILCHIMP', editable: true }, { name: 'Paypal', role: 'ROLE_INTEGRATIONS_USER_PAYPAL', editable: true }],

    actions: {
      saveUser: function saveUser(userData) {
        if (userData.username && userData.email) {
          this.get('store').createRecord("action", { action: "saveUser", context: { userData: userData } }).save().then(function (result) {
            console.log(result.get("context"));
            window.location.reload();
          }, function (r) {
            console.log(r);
            alert("Fehlgeschlagen\n" + r.errors[0].detail);
          });
        } else {
          window.alert("Ein User muss einen Namen und eine Email haben!");
        }
      },
      deleteUser: function deleteUser(userData) {
        var _this = this;

        if (userData.appCount === 0) {
          if (window.confirm("Wollen sie den User wirklich löschen!")) {
            this.get('store').createRecord("action", { action: "deleteUser", context: { userId: userData.id } }).save().then(function (result) {
              window.alert("User gelöscht");
              _this.transitionToRoute('backend.platform', userData.platform.id);
            }, function (r) {
              console.log(r);
              alert("action failed\n" + r.message);
            });
          }
        } else {
          window.alert("Nur User ohne Apps können gelöscht werden!");
        }
      },
      grantAuthority: function grantAuthority(authority, userId) {
        if (window.confirm("Recht hinzufügen?")) {
          this.get('store').createRecord("action", { action: "grantUserAuthority", context: { userId: userId, authority: authority } }).save().then(function (result) {
            window.alert("Recht hinzugefügt");
            window.location.reload();
          }, function (r) {
            console.log(r);
            alert("action failed\n" + r.message);
          });
        }
      },
      removeAuthority: function removeAuthority(authority, userId) {
        if (window.confirm("Recht entfernen?")) {
          this.get('store').createRecord("action", { action: "removeUserAuthority", context: { userId: userId, authority: authority } }).save().then(function (result) {
            window.alert("Recht entfernt");
            window.location.reload();
          }, function (r) {
            console.log(r);
            alert("action failed\n" + r.message);
          });
        }
      },
      addPlatform: function addPlatform(platformId, userId) {
        if (window.confirm("Platform hinzufügen?")) {
          this.get('store').createRecord("action", { action: "addPlatform", context: { userId: userId, platformId: platformId } }).save().then(function (result) {
            window.alert("Platform hinzugefügt");
            window.location.reload();
          }, function (r) {
            console.log(r);
            alert("action failed\n" + r.message);
          });
        }
      },
      removePlatform: function removePlatform(platformId, userId) {
        if (window.confirm("Platform entfernen?")) {
          this.get('store').createRecord("action", { action: "removePlatform", context: { userId: userId, platformId: platformId } }).save().then(function (result) {
            window.alert("Platform entfernt");
            window.location.reload();
          }, function (r) {
            console.log(r);
            alert("action failed\n" + r.message);
          });
        }
      }
    }
  });
});