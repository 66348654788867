define('hc2-ui/routes/app/privacy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    i18n: Ember.inject.service(),
    model: function model(params) {
      this.set("i18n.appLocale", params.lang);
      return Ember.RSVP.hash({
        app: this.store.findRecord('apps/app', params.app_id + "_" + params.lang),
        locale: params.locale
      });
    }
  });
});