define('hc2-ui/helpers/filter-by-app', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var participations = params[0];
    var app = params[1];
    var filteredParticipations = [];
    participations.forEach(function (participation) {
      if (participation.get('app.id') === app.get('id')) {
        filteredParticipations.push(participation);
      }
    });
    return filteredParticipations;
  });
});