define('hc2-ui/components/controls/conditions-privacy-accept-checkbox', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    selectedType: null,
    actions: {
      toggle: function toggle(type) {
        var isOpen = this.$('#inline-conditions').hasClass("in");
        var typeChanged = this.get("selectedType") !== type;
        var show = false;
        if (typeChanged || !isOpen) {
          show = true;
        }
        this.set("selectedType", type);
        this.$('#inline-conditions').collapse(show ? "show" : "hide");
        return false;
      }
    }
  });
});