define('hc2-ui/helpers/is-or', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    for (var i = 0; i < params.length; i++) {
      if (params[i]) {
        return params[i];
      }
    }
    return undefined;
  });
});