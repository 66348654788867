define("hc2-ui/components/apps/quiz/editor-form", ["exports", "hc2-ui/lib/make/init", "hc2-ui/helpers/has-html-content"], function (exports, _init, _hasHtmlContent) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    appData: {},
    editorResources: {},

    appStates: {
      showWelcomePage: false
    },
    editableSubAppLabels: ["appdata_participate_nav_label"],
    editableLabels: ["impressum", "overview", "sweepstake_participate", "appdata_participate_nav_label", "test_toResult", "close", "forward", "back", "yes", "no", "test_answer_commit", "question_result_true", "question_result_false", "timer_done", "points_label", "salutation_male", "salutation_female", "salutation_diverse", "sweepstake_contact_firstName", "sweepstake_contact_lastName", "sweepstake_contact_name", "sweepstake_contact_email", "sweepstake_contact_salutation", "sweepstake_contact_phone", "sweepstake_contact_mobile", "sweepstake_contact_street", "sweepstake_contact_streetNr", "sweepstake_contact_zip", "sweepstake_contact_city", "sweepstake_contact_country", "sweepstake_contact_doiLabel", "conditionsAgree_before", "conditions", "conditionsAgree_middle", "privacyPolicy", "conditionsAgree_after", "mandetory_field_hint_checkbox", "mandetory_field_hint_text", "mandetory_field_hint_radio", "mandetory_field_hint_hidden", "mandetory_field", "participation_error_invalidEmail_sweepstake_participation", "constraint_unique_uniqueId_sweepstake_participation", "mandetory_field_patternHint_text"],

    selectedQuestion: Ember.computed.alias("appStates.selectedQuestion"),
    selectedResult: Ember.computed.alias("appStates.selectedResult"),
    selectedExtraFromElement: 0,

    save: "save",
    actions: {
      save: function save() {
        if (this.get('appData.noForm')) {
          this.set('appData.impressum', '-');
          this.set('appData.conditions', '-');
        }
        this.sendAction('save', this.get('appData'));
      },
      setHeaderImageFromUpload: function setHeaderImageFromUpload(url) {
        this.set('appData.headerImageUri', url);
      },
      setQuestionImageFromUpload: function setQuestionImageFromUpload(url, question) {
        Ember.set(question, 'headerImageUri', url);
      },
      handleError: function handleError(err) {
        alert("Error:\n" + err);
      },
      selectParticipationPage: function selectParticipationPage() {
        this.switchWelcomePageIfActive(false);
        this.set("selectedQuestion", this.get('appData.questions.length'));
      },
      selectResultsPage: function selectResultsPage() {
        this.switchWelcomePageIfActive(false);
        this.set("selectedResult", 0);
        this.set("selectedQuestion", this.get("appData.questions.length") + (this.get("appData.noForm") ? 0 : 1));
      },
      selectResult: function selectResult(index) {
        this.set("selectedResult", index);
      },
      addResult: function addResult() {
        this.get('appData.results').pushObject({ minPoints: 0 });
        this.set("selectedResult", this.get('appData.results.length') - 1);
      },
      removeResult: function removeResult(index) {
        this.get('appData.results').removeAt(index);
        this.set("selectedResult", 0);
      },

      selectQuestionsPage: function selectQuestionsPage() {
        this.switchWelcomePageIfActive(false);
        if (this.get('selectedQuestion') >= this.get('appData.questions.length')) {
          this.set("selectedQuestion", 0);
        }
      },
      selectQuestion: function selectQuestion(index) {
        this.set("selectedQuestion", index);
      },
      addQuestion: function addQuestion() {
        this.get('appData.questions').pushObject({ type: "radio", mandetory: true, answers: [] });
        this.set("selectedQuestion", this.get('appData.questions.length') - 1);
      },
      removeQuestion: function removeQuestion(questionIndex) {
        this.get('appData.questions').removeAt(questionIndex);
        this.set("selectedExtraFromElement", 0);
      },
      addAnswer: function addAnswer(question) {
        question.answers.pushObject({ label: "", correct: false, points: 0 });
        this.get("appData").notifyPropertyChange("questions");
      },
      removeAnswer: function removeAnswer(question, answerIndex) {
        question.answers.removeAt(answerIndex);
      },
      toggleWelcomePageIfActive: function toggleWelcomePageIfActive(display) {
        this.switchWelcomePageIfActive(display);
        return true;
      },
      setCountDownDuration: function setCountDownDuration(on) {
        this.set("appData.countDownDuration", on ? 60 : 0);
      }
    },

    observeWelcomePage: function () {
      this.switchWelcomePageIfActive(true);
    }.observes("appData.welcomePage"),
    switchWelcomePageIfActive: function switchWelcomePageIfActive(display) {
      this.set("appStates.showWelcomePage", this.get("appData.welcomePage") && display);
    },

    didRender: function didRender() {
      _init.default.material(this.elementId);
    },
    showHeaderContentEditor: Ember.computed("appData.headerContent", function () {
      return (0, _hasHtmlContent.hasHtmlContent)(this.get("appData.headerContent"));
    }),
    setupModel: function () {
      this.set("selectedQuestion", 0);

      if (!this.get('appData.questions')) {
        this.set('appData.questions', Ember.A([]));
      }
      this.get('appData.questions').forEach(function (q) {
        if (q.answers) {
          q.answers.forEach(function (a) {
            if (isNaN(parseInt(a.points))) {
              a.points = 0;
            }
          });
        }
      });

      if (!this.get('appData.results')) {
        this.set('appData.results', Ember.A([]));
      }
      this.get('appData.results').forEach(function (r) {
        if (isNaN(parseInt(r.minPoints))) {
          r.minPoints = 0;
        }
      });
      if (!this.get('appData.contactData')) {
        this.set('appData.contactData', {
          salutationMandetory: true,
          nameMandetory: true,
          firstName: true,
          firstNameMandetory: true,
          lastName: true,
          lastNameMandetory: true,
          email: true,
          emailMandetory: true,
          extraFromElements: []
        });
      }
    }.on("init")

  });
});