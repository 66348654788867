define("hc2-ui/templates/components/backend/top-bar", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 12
            },
            "end": {
              "line": 17,
              "column": 87
            }
          },
          "moduleName": "hc2-ui/templates/components/backend/top-bar.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "icon-logout");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["logout"], [], ["loc", [null, [17, 66], [17, 80]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 29,
                  "column": 10
                },
                "end": {
                  "line": 31,
                  "column": 10
                }
              },
              "moduleName": "hc2-ui/templates/components/backend/top-bar.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "class", "");
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "target", "_blank");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1, 0]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element2, 'href');
              morphs[1] = dom.createMorphAt(element2, 0, 0);
              return morphs;
            },
            statements: [["attribute", "href", ["concat", [["get", "p.url", ["loc", [null, [30, 36], [30, 41]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "p.name", ["loc", [null, [30, 61], [30, 71]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 8
              },
              "end": {
                "line": 32,
                "column": 8
              }
            },
            "moduleName": "hc2-ui/templates/components/backend/top-bar.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "unless", [["subexpr", "is-eq", [["get", "p.id", ["loc", [null, [29, 27], [29, 31]]], 0, 0, 0, 0], ["get", "platform.id", ["loc", [null, [29, 32], [29, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [29, 20], [29, 44]]], 0, 0]], [], 0, null, ["loc", [null, [29, 10], [31, 21]]]]],
          locals: ["p"],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 2
            },
            "end": {
              "line": 35,
              "column": 2
            }
          },
          "moduleName": "hc2-ui/templates/components/backend/top-bar.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "class", "tm nav-parent");
          dom.setAttribute(el1, "id", "platform-switcher");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "href", "#");
          dom.setAttribute(el2, "data-toggle", "dropdown");
          dom.setAttribute(el2, "data-hover", "dropdown");
          dom.setAttribute(el2, "data-close-others", "true");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("i");
          dom.setAttribute(el3, "class", "fa fa-th");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "hidden-xs");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          dom.setAttribute(el2, "class", "dropdown-menu");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element3, [1, 2]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["platforms.change"], [], ["loc", [null, [25, 56], [25, 80]]], 0, 0], ["block", "each", [["get", "session.data.authenticated.userAuthorityInfo.platforms", ["loc", [null, [28, 16], [28, 70]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [28, 8], [32, 17]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child2 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 53,
                "column": 10
              },
              "end": {
                "line": 57,
                "column": 10
              }
            },
            "moduleName": "hc2-ui/templates/components/backend/top-bar.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [1]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createAttrMorph(element1, 'src');
            morphs[2] = dom.createAttrMorph(element1, 'title');
            morphs[3] = dom.createAttrMorph(element1, 'alt');
            morphs[4] = dom.createAttrMorph(element1, 'onclick');
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["locale ", ["subexpr", "if", [["subexpr", "is-eq", [["get", "locale", ["loc", [null, [54, 43], [54, 49]]], 0, 0, 0, 0], ["get", "i18n.locale", ["loc", [null, [54, 50], [54, 61]]], 0, 0, 0, 0]], [], ["loc", [null, [54, 36], [54, 62]]], 0, 0], "selected"], [], ["loc", [null, [54, 31], [54, 75]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["subexpr", "flag", [["get", "locale", ["loc", [null, [55, 31], [55, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [55, 24], [55, 39]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "title", ["concat", [["subexpr", "t", [["subexpr", "concat", ["locales.", ["get", "locale", ["loc", [null, [55, 71], [55, 77]]], 0, 0, 0, 0], ".label"], [], ["loc", [null, [55, 52], [55, 87]]], 0, 0]], [], ["loc", [null, [55, 48], [55, 89]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "alt", ["concat", [["subexpr", "t", [["subexpr", "concat", ["locales.", ["get", "locale", ["loc", [null, [55, 119], [55, 125]]], 0, 0, 0, 0], ".label"], [], ["loc", [null, [55, 100], [55, 135]]], 0, 0]], [], ["loc", [null, [55, 96], [55, 137]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["selectLocale", ["get", "locale", ["loc", [null, [55, 171], [55, 177]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [55, 180]]], 0, 0], 0, 0, 0, 0]],
          locals: ["locale"],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 50,
              "column": 6
            },
            "end": {
              "line": 60,
              "column": 4
            }
          },
          "moduleName": "hc2-ui/templates/components/backend/top-bar.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "ml-app-locale-switcher");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "locales");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "i18n.locales", ["loc", [null, [53, 18], [53, 30]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [53, 10], [57, 19]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 69,
            "column": 19
          }
        },
        "moduleName": "hc2-ui/templates/components/backend/top-bar.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" BEGIN TOPBAR ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "topbar");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "header-left");
        dom.setAttribute(el2, "style", "display: block;");
        var el3 = dom.createTextNode("       ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "topnav");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "class", "menutoggle");
        dom.setAttribute(el4, "href", "#");
        dom.setAttribute(el4, "data-toggle", "sidebar-collapsed");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "menu__handle");
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("Menu");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "header-right");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3, "class", "header-menu nav navbar-nav");
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment(" BEGIN USER DROPDOWN ");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4, "class", "dropdown");
        dom.setAttribute(el4, "id", "user-header");
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "#");
        dom.setAttribute(el5, "data-toggle", "dropdown");
        dom.setAttribute(el5, "data-hover", "dropdown");
        dom.setAttribute(el5, "data-close-others", "true");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "class", "username");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(", ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n    ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        dom.setAttribute(el5, "class", "dropdown-menu");
        var el6 = dom.createTextNode("\n         ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n    ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment(" END USER DROPDOWN ");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4, "class", "dropdown");
        dom.setAttribute(el4, "id", "locale-header");
        var el5 = dom.createTextNode("\n  ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "#");
        dom.setAttribute(el5, "data-toggle", "dropdown");
        dom.setAttribute(el5, "data-hover", "dropdown");
        dom.setAttribute(el5, "data-close-others", "true");
        var el6 = dom.createTextNode("\n    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "ml-app-locale-switcher");
        var el7 = dom.createTextNode("\n    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "locales");
        var el8 = dom.createTextNode("\n      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "locale selected");
        var el9 = dom.createTextNode("\n        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("img");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n  ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n  ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        dom.setAttribute(el5, "class", "dropdown-menu");
        var el6 = dom.createTextNode("\n    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n  ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" header-right ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" END TOPBAR ");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [2, 3, 1]);
        var element5 = dom.childAt(element4, [3]);
        var element6 = dom.childAt(element5, [1, 1]);
        var element7 = dom.childAt(element4, [9]);
        var element8 = dom.childAt(element7, [1, 1, 1, 1, 1]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(element6, 0, 0);
        morphs[1] = dom.createMorphAt(element6, 2, 2);
        morphs[2] = dom.createMorphAt(dom.childAt(element5, [3, 1]), 1, 1);
        morphs[3] = dom.createMorphAt(element4, 5, 5);
        morphs[4] = dom.createAttrMorph(element8, 'src');
        morphs[5] = dom.createAttrMorph(element8, 'title');
        morphs[6] = dom.createAttrMorph(element8, 'alt');
        morphs[7] = dom.createMorphAt(dom.childAt(element7, [3, 1]), 1, 1);
        return morphs;
      },
      statements: [["inline", "t", ["hello"], [], ["loc", [null, [13, 31], [13, 44]]], 0, 0], ["content", "session.data.authenticated.userAuthorityInfo.username", ["loc", [null, [13, 46], [13, 103]]], 0, 0, 0, 0], ["block", "link-to", ["logout"], [], 0, null, ["loc", [null, [17, 12], [17, 99]]]], ["block", "if", [["subexpr", "is-gt", [["get", "session.data.authenticated.userAuthorityInfo.platforms.length", ["loc", [null, [22, 15], [22, 76]]], 0, 0, 0, 0], 1], [], ["loc", [null, [22, 8], [22, 79]]], 0, 0]], [], 1, null, ["loc", [null, [22, 2], [35, 9]]]], ["attribute", "src", ["concat", [["subexpr", "flag", [["get", "i18n.locale", ["loc", [null, [43, 25], [43, 36]]], 0, 0, 0, 0]], [], ["loc", [null, [43, 18], [43, 38]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "title", ["concat", [["subexpr", "t", [["subexpr", "concat", ["locales.", ["get", "i18n.locale", ["loc", [null, [43, 70], [43, 81]]], 0, 0, 0, 0], ".label"], [], ["loc", [null, [43, 51], [43, 91]]], 0, 0]], [], ["loc", [null, [43, 47], [43, 93]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "alt", ["concat", [["subexpr", "t", [["subexpr", "concat", ["locales.", ["get", "i18n.locale", ["loc", [null, [43, 123], [43, 134]]], 0, 0, 0, 0], ".label"], [], ["loc", [null, [43, 104], [43, 144]]], 0, 0]], [], ["loc", [null, [43, 100], [43, 146]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["subexpr", "is-gt", [["get", "i18n.locales.length", ["loc", [null, [50, 19], [50, 38]]], 0, 0, 0, 0], 1], [], ["loc", [null, [50, 12], [50, 41]]], 0, 0]], [], 2, null, ["loc", [null, [50, 6], [60, 11]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});