define("hc2-ui/components/apps/quiz/app-view", ["exports", "hc2-ui/lib/make/init"], function (exports, _init) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    tracking: Ember.inject.service(),
    session: Ember.inject.service(),
    embed: Ember.inject.service(),

    appData: {},
    editMode: false,
    selectedPage: 0,
    totalPoints: 0,
    result: Ember.computed("appStates.selectedResult", function () {
      return this.get("appData.results").objectAt(this.get("appStates.selectedResult"));
    }),
    resultIndex: Ember.computed("result", function () {
      return this.get("appData.results").indexOf(this.get("result"));
    }),
    currentQuestion: Ember.computed("selectedPage", "appData.questions.[]", function () {
      if (this.get("selectedPage") === 0) {
        this.set("totalPoints", 0);
      }
      return this.get("appData.questions").objectAt(this.get("selectedPage"));
    }),
    currentQuestionHasCorrectAnswerDefined: Ember.computed("currentQuestion", function () {
      var hasCorrect = false;
      var answers = this.get("currentQuestion.answers");
      if (answers) {
        answers.forEach(function (answer) {
          if (answer.correct === true) {
            hasCorrect = true;
          }
        });
      }
      return hasCorrect;
    }),
    appStates: {
      selectedQuestion: 0,
      showWelcomePage: false,
      selectedResult: 0
    },
    participation: null,
    active: Ember.computed('appData.startDate', 'appData.endDate', function () {
      var now = new Date();
      return now > this.get('appData.startDate') && now < this.get('appData.endDate');
    }),
    changingSelectedQuestion: Ember.observer('appStates.selectedQuestion', function () {
      this.set("selectedPage", this.get("appStates.selectedQuestion"));
    }),

    changingSelected: Ember.observer('appData.questions.[]', function () {
      this.rerender();
    }),

    showTabs: Ember.computed("appData.descriptionHeader", "appData.additionalInformationHeader", function () {
      return this.get("appData.descriptionHeader") || this.get("appData.additionalInformationHeader");
    }),
    progress: Ember.computed("appData.questions.length", "selectedPage", "appData.noForm", function () {
      var steps = this.get("appData.questions.length");
      if (!this.get("appData.noForm")) {
        steps++;
      }
      var progress = 100 / steps * (this.get("selectedPage") + 1);
      return progress;
    }),
    init: function init() {
      this._super();
      this.set("appStates.showWelcomePage", this.get('appData.welcomePage') && !this.get('editMode'));
      if (!this.get('editMode') && !this.get("appData.isNew")) {
        this.get('store').createRecord("view", { app: this.get('appData') }).save();
      }
      this.set("appStates.selectedTab", this.get("appData.descriptionHeader") ? "description" : "participate");
    },
    tabChange: Ember.observer("appStates.selectedTab", function () {
      var _this = this;

      Ember.run.later(function () {
        _this.set("tab", _this.get("appStates.selectedTab"));
      });
    }).on("init"),
    stepChange: Ember.observer("selectedPage", function () {
      var _this2 = this;

      Ember.run.later(function () {
        var plusForLastStep = _this2.get("appData.noForm") ? 0 : 1;
        _this2.set("step", _this2.get("selectedPage") === _this2.get("appData.questions.length") + plusForLastStep ? "done" : _this2.get("selectedPage") + 1);
      });
    }).on("init"),
    didRender: function didRender() {
      this.get("tracking").initLinkTracking();
      _init.default.material(this.elementId);

      // Handler to check for correct answer on radio questions and show hint+
      window.$('#' + this.get('elementId') + ' input[data-correct]').off('click');
      var that = this;
      window.$('#' + this.get('elementId') + ' input[data-correct]').click(function (event) {
        var isCorrect = window.$(event.currentTarget).data().correct;
        var inputName = window.$(event.currentTarget).attr('name');
        //var answer = window.$('input[name=answer-1]:checked').val();

        window.$('#' + inputName + "-hint").html('<span class="label ' + (isCorrect ? 'label-success' : 'label-danger') + '">' + that.get('i18n').ta('question.result.' + isCorrect) + '</span>');
      });
    },
    stripTags: function stripTags(html) {
      if (!html) {
        return "";
      }
      var tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      var text = tmp.textContent || tmp.innerText || "";
      return text.replace(/\s/g, '');
    },


    actions: {
      setSelectedTab: function setSelectedTab(tabname) {
        this.set("appStates.selectedTab", tabname);
      },
      selectPage: function selectPage(page) {
        this.set('selectedPage', page);
      },
      changedAnswer: function changedAnswer(answer) {
        this.set("currentAnswer", answer);
      },
      selectNextPage: function selectNextPage(force) {

        // increment if required fields set
        if (this.isActiveTabValid() || force || this.get("answerCommited")) {

          if (this.get("selectedPage") < this.get("appData.questions.length") && !this.get("answerCommited")) {
            var points = parseInt(this.get("currentAnswer.points"));
            this.set("totalPoints", this.get("totalPoints") + (isNaN(points) ? 0 : points));
            var hasCorrectAnswer = this.get("currentQuestionHasCorrectAnswerDefined");
            var questionHint = this.stripTags(this.get("currentQuestion.questionHint"));
            if (hasCorrectAnswer || questionHint) {
              this.set("answerCommited", true);
            } else {
              if (this.get("appData.noForm") && this.get("selectedPage") === this.get("appData.questions.length") - 1) {
                this.set("submittingResult", true);
                this.send("submitForm");
              } else {
                this.incrementProperty('selectedPage');
              }
            }
          } else {
            if (this.get("appData.noForm") && this.get("selectedPage") === this.get("appData.questions.length") - 1) {
              this.set("submittingResult", true);
              this.send("submitForm");
            } else if (this.get("selectedPage") === this.get("appData.questions.length")) {
              this.set("submittingResult", true);
              this.send("submitForm");
            } else {
              this.incrementProperty('selectedPage');
            }
            this.set("answerCommited", false);
            this.set("currentAnswer", undefined);
          }
        }
      },

      submitForm: function submitForm() {
        if (this.isActiveTabValid()) {
          var o = {};
          var f = window.$('#' + this.get('elementId') + ' form');
          var a = f.serializeArray();
          window.$.each(a, function () {
            if (o[this.name] !== undefined) {
              if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
              }
              o[this.name].push(this.value || '');
            } else {
              o[this.name] = this.value || '';
            }
          });
          f.find(':disabled[name]:checked', this).each(function () {
            o[this.name] = this.value || '';
          });

          o.locale = this.get("i18n.appLocale");
          o.source = this.get("session.source");
          o.totalPoints = this.get("totalPoints");
          o.resultIndex = this.determineResultIndex();
          if (window.hc2_ad && window.hc2_ad.form) {
            Ember.$.extend(o, window.hc2_ad.form);
          }

          var that = this;
          var participation = this.get('store').createRecord("participation", {
            app: this.get('appData'), formData: o, domain: window.location.hostname
          });
          this.set('participation', participation);
          participation.save().then(function () /*participation*/{
            that.setResult();
            that.set("submittingResult", false);

            that.set('selectedPage', that.get('appData.questions.length') + (that.get("appData.noForm") ? 0 : 1));
            that.get("tracking").sendLeadEvent(that.get("appData.title"));
          });
        }
      },
      hideWelcomePage: function hideWelcomePage() {
        this.get("embed").notifyStepChange();
        this.set("appStates.showWelcomePage", false);
      },
      onCountDownDone: function onCountDownDone(counterId) {
        if (this.get("selectedPage") == counterId) {
          if (!this.get("editMode")) {
            this.set("countDownDone-" + counterId, true);
            this.send("selectNextPage", true);
          }
        }
      }
    },
    determineResultIndex: function determineResultIndex() {
      var points = this.get("totalPoints");
      var results = this.get("appData.results");
      // getting result with lowest points
      var resultIndex = 0;
      var result = results[0];
      if (isNaN(parseInt(result.minPoints))) {
        result.minPoints = 0;
      }
      results.forEach(function (r, index) {
        if (isNaN(parseInt(r.minPoints))) {
          r.minPoints = 0;
        }
        if (parseInt(r.minPoints) < parseInt(result.minPoints)) {
          result = r;
          resultIndex = index;
        }
      });
      // get result matching points
      results.forEach(function (r, index) {
        if (points >= parseInt(r.minPoints) && parseInt(r.minPoints) > parseInt(result.minPoints)) {
          result = r;
          resultIndex = index;
        }
      });
      return resultIndex;
    },
    setResult: function setResult() {
      var resultIndex = this.determineResultIndex();
      var results = this.get("appData.results");
      this.set("result", results[resultIndex]);
    },

    isActiveTabValid: function isActiveTabValid() {
      // check required fields on current page
      var requiredInputs = window.$('#tab-ss-participate .tab-pane.active input[required]');
      var requiredPatternInputs = window.$('#tab-ss-participate .tab-pane.active input[data-required-pattern]');
      var inputsToCheck = window.$.merge(requiredInputs, requiredPatternInputs);
      var allRequiredSet = true;
      for (var i = 0; i < inputsToCheck.length; i++) {
        var type = requiredInputs[i].type;
        var val = null;
        var field = window.$('#tab-ss-participate form input[name="' + requiredInputs[i].name + '"]');
        if ('radio' === type || 'checkbox' === type) {
          val = window.$('#tab-ss-participate form input[name="' + requiredInputs[i].name + '"]:checked').val();
        } else {
          val = field.val();
        }

        var required = field.prop("required");
        var requiredPattern = field.attr("data-required-pattern");

        var requiredValid = required ? val : true;
        var patternValid = true;
        if (requiredPattern && val) {
          patternValid = val.match(requiredPattern);
        }

        if (!requiredValid || !patternValid) {
          var fg = field.closest('.form-group');
          fg.addClass('has-error');
          allRequiredSet = false;
          var msgBox = fg.find('.mandetoryFieldMessage');
          if (msgBox.length === 0) {
            msgBox = window.$('<div class="mandetoryFieldMessage"><span class="text-danger"></span></div>');
            fg.append(msgBox);
          }
          var msg = !requiredValid ? this.get('i18n').ta('mandetory.field.hint.' + type) : this.get('i18n').ta('mandetory.field.patternHint.' + type);
          msgBox.find('span').text(msg);
          msgBox.show().delay(5000).fadeOut();
        } else {
          field.closest('.form-group').removeClass('has-error');
        }
      }
      return allRequiredSet;
    },

    didInsertElement: function didInsertElement() {
      _init.default.material(this.elementId);
      //window.$(".sweepstake-editor-form > .nav li:nth-child(9) a").click();
    }
  });
});