define("hc2-ui/components/apps/duration-timer", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ["duration-timer", "label"],
    classNameBindings: ['active'],

    duration: 0,
    active: false,
    minutesRunning: Ember.computed("duration", function () {
      return Math.floor(this.get("duration") / 60);
    }),
    secondsRunning: Ember.computed("duration", function () {
      return this.get("duration") % 60;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.attrs.static) {
        this.startTimer();
      }
    },
    startTimer: function startTimer() {
      this.set("duration", 0);
      this.tick();
    },
    tick: function tick() {
      var _this = this;

      if (!this.get("isDestroyed")) {
        Ember.run.later(function () {
          if (_this.get("active") && !_this.get("isDestroyed")) {
            var duration = _this.get("duration");
            _this.set("duration", duration + 1);
          }
          _this.tick();
        }, 1000);
      }
    }
  });
});