define('hc2-ui/routes/backend/apps/copy-for-user', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    i18n: Ember.inject.service(),
    afterModel: function afterModel() {
      this.set("pageTitle", this.get("i18n").t('app.copy.for.user'));
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        appData: this.store.findRecord('apps/' + params.app_type, params.app_id),
        users: this.store.query('user', {})
      });
    }
  });
});