define("hc2-ui/helpers/is-now-between", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var now = new Date();
    if (params[0] && params[1]) {
      var date1 = typeof params[0] === "string" ? new Date(params[0]) : params[0];
      var date2 = typeof params[1] === "string" ? new Date(params[1]) : params[1];
      return now > date1 && now < date2;
    }
    return false;
  });
});