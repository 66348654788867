define("hc2-ui/components/apps/win-wheel/app-view", ["exports", "hc2-ui/lib/make/init"], function (exports, _init) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    tracking: Ember.inject.service(),
    session: Ember.inject.service(),
    embed: Ember.inject.service(),

    winWheelApi: {},
    status: "start",

    appData: {},
    editMode: false,
    selectedPage: 0,
    appStates: {
      selectedQuestion: 0,
      showWelcomePage: false
    },
    stepChange: Ember.observer("status", function () {
      var _this = this;

      Ember.run.later(function () {
        _this.set("step", _this.get("status"));
      });
    }).on("init"),
    participation: null,
    active: Ember.computed('appData.startDate', 'appData.endDate', function () {
      var now = new Date();
      return now > this.get('appData.startDate') && now < this.get('appData.endDate');
    }),
    changingSelectedQuestion: Ember.observer('appStates.selectedQuestion', function () {
      this.set("selectedPage", this.get("appStates.selectedQuestion"));
    }),

    changingSelected: Ember.observer('appData.questions.[]', function () {
      this.rerender();
    }),

    showTabs: Ember.computed("appData.descriptionHeader", "appData.additionalInformationHeader", function () {
      return this.get("appData.descriptionHeader") || this.get("appData.additionalInformationHeader");
    }),
    showStartButton: Ember.computed.equal("status", "start"),
    canBeStopped: Ember.computed("status", "appData.noForm", function () {
      return this.get("status") === "wheelRunning" && this.get("appData.noForm");
    }),
    showForm: Ember.computed.equal("status", "wheelRunning"),
    showPrize: Ember.computed.equal("status", "wheelStopped"),
    init: function init() {
      this._super();
      this.set("appStates.showWelcomePage", this.get('appData.welcomePage') && !this.get('editMode'));
      if (!this.get('editMode') && !this.get("appData.isNew")) {
        this.get('store').createRecord("view", { app: this.get('appData') }).save();
      }
    },
    didRender: function didRender() {
      _init.default.material(this.elementId);

      // Handler to check for correct answer on radio questions and show hint+
      window.$('#' + this.get('elementId') + ' input[data-correct]').off('click');
      var that = this;
      window.$('#' + this.get('elementId') + ' input[data-correct]').click(function (event) {
        var isCorrect = window.$(event.currentTarget).data().correct;
        var inputName = window.$(event.currentTarget).attr('name');
        //var answer = window.$('input[name=answer-1]:checked').val();

        window.$('#' + inputName + "-hint").html('<span class="label ' + (isCorrect ? 'label-success' : 'label-danger') + '">' + that.get('i18n').ta('question.result.' + isCorrect) + '</span>');
      });
    },

    actions: {
      wheelStopped: function wheelStopped() {
        this.set("status", "wheelStopped");
      },
      clickWheel: function clickWheel() /*event*/{
        var status = this.get("status");
        if (status === "wheelRunning" && this.get("appData.noForm")) {
          // stop
          this.send("submitForm");
        } else if (status === "start") {
          // start
          this.set("status", "wheelRunning");
          this.get("winWheelApi").actions.startWheel();
        } else if (status === "wheelStopped") {// reset
          //this.set("status", "start"); // no reset on wheel click
        }
      },

      selectPage: function selectPage(page) {
        this.set('selectedPage', page);
      },
      selectPrevPage: function selectPrevPage() {
        this.decrementProperty('selectedPage');
      },
      selectNextPage: function selectNextPage() {

        // increment if required fields set
        if (this.isActiveTabValid()) {
          this.incrementProperty('selectedPage');
        }
      },
      submitForm: function submitForm() {
        if (this.isActiveTabValid()) {
          var o = {};
          var a = window.$('#' + this.get('elementId') + ' form').serializeArray();
          window.$.each(a, function () {
            if (o[this.name] !== undefined) {
              if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
              }
              o[this.name].push(this.value || '');
            } else {
              o[this.name] = this.value || '';
            }
          });

          o.locale = this.get("i18n.appLocale");
          o.source = this.get("session.source");
          if (window.hc2_ad && window.hc2_ad.form) Ember.$.extend(o, window.hc2_ad.form);

          if (!this.get("isSubmitting")) {
            this.set("isSubmitting", true);
            var that = this;
            var participation = this.get('store').createRecord("participation", {
              app: this.get('appData'), formData: o, domain: window.location.hostname
            });
            this.set('participation', participation);
            participation.save().then(function (participation) {
              var prize = participation.get("prize");
              that.set('status', 'participationSaved');
              that.set('prize', that.get('appData.prizes').objectAt(prize));
              that.set('prizeIndex', prize);
              that.get("winWheelApi").actions.stopWheel(prize + 1);
              that.get("tracking").sendLeadEvent(that.get("appData.title"));
            }).finally(function () {
              that.set("isSubmitting", false);
            });
          }
        }
      },
      resetWheel: function resetWheel(event) {
        this.set("status", "start");
        event.stopPropagation();
        return false;
      },
      hideWelcomePage: function hideWelcomePage() {
        this.get("embed").notifyStepChange();
        this.set("appStates.showWelcomePage", false);
      }
    },
    isActiveTabValid: function isActiveTabValid() {
      // check required fields on current page
      var requiredInputs = window.$('.winWheelForm input[required]');
      var requiredPatternInputs = window.$('.winWheelForm input[data-required-pattern]');
      var inputsToCheck = window.$.merge(requiredInputs, requiredPatternInputs);
      var allRequiredSet = true;
      for (var i = 0; i < inputsToCheck.length; i++) {
        var type = requiredInputs[i].type;
        var val = null;
        var field = window.$('#tab-ss-participate form input[name="' + requiredInputs[i].name + '"]');
        if ('radio' === type || 'checkbox' === type) {
          val = window.$('#tab-ss-participate form input[name="' + requiredInputs[i].name + '"]:checked').val();
        } else {
          val = field.val();
        }

        var required = field.prop("required");
        var requiredPattern = field.attr("data-required-pattern");

        var requiredValid = required ? val : true;
        var patternValid = true;
        if (requiredPattern && val) {
          patternValid = val.match(requiredPattern);
        }

        if (!requiredValid || !patternValid) {
          var fg = field.closest('.form-group');
          fg.addClass('has-error');
          allRequiredSet = false;
          var msgBox = fg.find('.mandetoryFieldMessage');
          if (msgBox.length === 0) {
            msgBox = window.$('<div class="mandetoryFieldMessage"><span class="text-danger"></span></div>');
            fg.append(msgBox);
          }
          var msg = !requiredValid ? this.get('i18n').ta('mandetory.field.hint.' + type) : this.get('i18n').ta('mandetory.field.patternHint.' + type);
          msgBox.find('span').text(msg);
          msgBox.show().delay(5000).fadeOut();
        } else {
          field.closest('.form-group').removeClass('has-error');
        }
      }
      return allRequiredSet;
    },

    didInsertElement: function didInsertElement() {
      _init.default.material(this.elementId);
    }
  });
});