define('hc2-ui/components/backend/top-bar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    session: service('session'),
    i18n: Ember.inject.service(),

    locales: Ember.computed.alias("i18n.locales"),

    actions: {
      selectLocale: function selectLocale(locale) {
        this.set("i18n.locale", locale);
      }
    }
  });
});