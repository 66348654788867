define('hc2-ui/helpers/to-array', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    // params is a frozen, non-ember array
    return Ember.A(params.slice());
  });
});