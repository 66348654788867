define('hc2-ui/components/apps/integration-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    actions: {
      getMailchimpLists: function getMailchimpLists(query, deferred) {
        this.get("store").findAll('mailchimp-list').then(deferred.resolve, deferred.reject);
      },
      activateMailchimpList: function activateMailchimpList(list) {
        var appIntegrations = this.getAppIntegrations();
        appIntegrations.set("mailchimp", { active: true, listId: list.get("id"), listName: list.get("name") });
        appIntegrations.save();
      },
      deactivateMailchimp: function deactivateMailchimp() {
        var appIntegrations = this.getAppIntegrations();
        appIntegrations.set("mailchimp", { active: false });
        appIntegrations.save();
      },
      activateEmarsys: function activateEmarsys() {
        var appIntegrations = this.getAppIntegrations();
        appIntegrations.set("emarsys", { active: true });
        appIntegrations.save();
      },
      deactivateEmarsys: function deactivateEmarsys() {
        var appIntegrations = this.getAppIntegrations();
        appIntegrations.set("emarsys", { active: false });
        appIntegrations.save();
      },
      setFacebookPixelForApp: function setFacebookPixelForApp(facebookPixelIdForApp) {
        var _this = this;

        var appIntegrations = this.getAppIntegrations();
        appIntegrations.set("facebookPixel", { appTrackingId: facebookPixelIdForApp });
        appIntegrations.save().then(function () {
          return _this.set("showSetFacebookPixelForAppForm", false);
        });
      },
      removeFacebookPixel: function removeFacebookPixel() {
        var appIntegrations = this.getAppIntegrations();
        appIntegrations.set("facebookPixel", {});
        appIntegrations.save();
      },
      deactivateGlobalFacebookPixel: function deactivateGlobalFacebookPixel() {
        var appIntegrations = this.getAppIntegrations();
        appIntegrations.set("facebookPixel", { globalInactive: true });
        appIntegrations.save();
      }
    },
    getAppIntegrations: function getAppIntegrations() {
      var appData = this.get("appData");
      var appIntegrations = appData.get("appIntegrations");
      if (!appIntegrations.get("id")) {
        appIntegrations = this.get("store").createRecord("appIntegration", { app: appData });
        this.set("appIntegrations", appIntegrations);
      } else {
        appIntegrations = this.get("store").peekRecord("appIntegration", this.get("appData.appIntegrations.id"));
        if (!appIntegrations) {
          // not in store yet
          return appData.get("appIntegrations");
        }
      }
      return appIntegrations;
    },

    appIntegrations: Ember.computed("appData.id", function () {
      // kinda init on render in controller logic?!
      return this.getAppIntegrations();
    })
  });
});