define('hc2-ui/helpers/export-uri', ['exports', 'hc2-ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    session: Ember.inject.service(),
    compute: function compute(params) {
      return '/export/' + params[1] + '/' + params[0].get('id');
    }
  });
});