define('hc2-ui/models/apps/pack-your-bag', ['exports', 'ember-data', 'hc2-ui/models/apps/sweepstake'], function (exports, _emberData, _sweepstake) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _sweepstake.default.extend({
    portraitModeEnabled: _emberData.default.attr('boolean'),
    backgroundImageUri: _emberData.default.attr("string"),
    backgroundImagePortraitUri: _emberData.default.attr("string"),
    minItems: _emberData.default.attr("number", { defaultValue: 0 }),
    maxItems: _emberData.default.attr("number", { defaultValue: 0 }),
    multiSelect: _emberData.default.attr("boolean", { defaultValue: false }),
    autoArrange: _emberData.default.attr("boolean", { defaultValue: false })
  });
});