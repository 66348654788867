define("hc2-ui/templates/backend/apps/customers", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 0
            },
            "end": {
              "line": 31,
              "column": 0
            }
          },
          "moduleName": "hc2-ui/templates/backend/apps/customers.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("       ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "apps/app-overview", [], ["app", ["subexpr", "@mut", [["get", "app", ["loc", [null, [30, 31], [30, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "showUserInfo", true], ["loc", [null, [30, 7], [30, 54]]], 0, 0]],
        locals: ["app"],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 32,
            "column": 6
          }
        },
        "moduleName": "hc2-ui/templates/backend/apps/customers.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row pagination-search-box");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-sm-2");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "totalCount");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(": ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-sm-4");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-sm-6");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "input-group");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5, "type", "text");
        dom.setAttribute(el5, "class", "form-control search-field");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "input-group-btn");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6, "class", "btn btn-primary");
        dom.setAttribute(el6, "type", "submit");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("i");
        dom.setAttribute(el7, "class", "glyphicon glyphicon-search");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row row-flex");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(element0, [1, 1]);
        var element2 = dom.childAt(element0, [5, 1]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element3, [1]);
        var morphs = new Array(11);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element1, 0, 0);
        morphs[2] = dom.createMorphAt(element1, 2, 2);
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
        morphs[4] = dom.createAttrMorph(element2, 'onsubmit');
        morphs[5] = dom.createAttrMorph(element4, 'value');
        morphs[6] = dom.createAttrMorph(element4, 'placeholder');
        morphs[7] = dom.createAttrMorph(element4, 'onchange');
        morphs[8] = dom.createMorphAt(dom.childAt(element3, [3, 1]), 3, 3);
        morphs[9] = dom.createMorphAt(element2, 3, 3);
        morphs[10] = dom.createMorphAt(dom.childAt(fragment, [4]), 1, 1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "-el/header", [], ["title", ["subexpr", "t", ["apps.customers"], [], ["loc", [null, [1, 19], [1, 39]]], 0, 0]], ["loc", [null, [1, 0], [1, 41]]], 0, 0], ["inline", "t", ["in.total"], [], ["loc", [null, [5, 29], [5, 45]]], 0, 0], ["content", "totalElements", ["loc", [null, [5, 47], [5, 64]]], 0, 0, 0, 0], ["inline", "-el/pagination", [], ["totalElements", ["subexpr", "@mut", [["get", "totalElements", ["loc", [null, [8, 35], [8, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "page", ["subexpr", "@mut", [["get", "page", ["loc", [null, [8, 54], [8, 58]]], 0, 0, 0, 0]], [], [], 0, 0], "size", ["subexpr", "@mut", [["get", "size", ["loc", [null, [8, 64], [8, 68]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [8, 4], [8, 70]]], 0, 0], ["attribute", "onsubmit", ["subexpr", "action", ["performSearch", ["get", "searchString", ["loc", [null, [11, 44], [11, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [11, 58]]], 0, 0], 0, 0, 0, 0], ["attribute", "value", ["get", "search", ["loc", [null, [13, 69], [13, 75]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "placeholder", ["concat", [["subexpr", "t", ["searchFor"], [], ["loc", [null, [13, 91], [13, 108]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onchange", ["subexpr", "action", [["subexpr", "mut", [["get", "searchString", ["loc", [null, [14, 38], [14, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 33], [14, 51]]], 0, 0]], ["value", "target.value"], ["loc", [null, [null, null], [14, 74]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["search"], [], ["loc", [null, [17, 50], [17, 64]]], 0, 0], ["inline", "apps/app-type-selector", [], ["selectedAppTypes", ["subexpr", "@mut", [["get", "appTypes", ["loc", [null, [22, 48], [22, 56]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [22, 6], [22, 58]]], 0, 0], ["block", "each", [["get", "model", ["loc", [null, [29, 8], [29, 13]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [29, 0], [31, 9]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});