define("hc2-ui/components/apps/cookie-info", ["exports", "hc2-ui/lib/make/init"], function (exports, _init) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tracking: Ember.inject.service(),

    classNames: ["cookie-info"],
    classNameBindings: ["needsThirdPartyOptIn", "accepted", "cookieOverlay"],
    needsThirdPartyOptIn: Ember.computed.alias("appData.needsThirdPartyOptIn"),
    cookieOverlay: Ember.computed.alias("appData.cookieBannerAsOverlay"),
    modalId: "privacyPolicy",
    impressumModalId: Ember.computed("appData.appType", function () {
      if (this.get("appData.appType") === "contest") {
        return "contest-impressum";
      } else if (["calendar", "christmasCalendar", "packYourBag"].contains(this.get("appData.appType"))) {
        return "impressum";
      } else {
        return "ss-impressum";
      }
    }),
    appStates: {},
    accepted: Ember.computed.alias("appStates.cookieBannerAccepted"),
    init: function init() {
      this._super.apply(this, arguments);
      this.set("statisticsOn", window["trackingOptedIn"]);
      this.set("socialMediaFunctionalityOn", window["socialMediaOptedIn"]);

      if (this.get("statisticsOn") && this.get("socialMediaFunctionalityOn")) {
        this.set("accepted", true);
      }
    },


    resetAccepted: function () {
      if (this.get("appData.cookieBanner")) {
        this.set("accepted", false);
      }
    }.observes('appData.cookieBanner'),

    actions: {
      acceptAll: function acceptAll() {
        this.initTracking();
        this.initSocialMediaFunctionality();
        this.set("accepted", true);
      },
      acceptSelected: function acceptSelected() {
        var reloadNeeded = false;
        if (this.get("statisticsOn")) {
          this.initTracking();
        } else {
          reloadNeeded = window["trackingOptedIn"];
          this.get("tracking").trackingOptOut();
        }
        if (this.get("personalityOn")) {}
        if (this.get("socialMediaFunctionalityOn")) {
          this.initSocialMediaFunctionality();
        } else {
          reloadNeeded = window["socialMediaOptedIn"];
          window.socialOptout();
        }
        if (reloadNeeded) {
          window.location.reload();
        } else {
          this.set("accepted", true);
        }
      }
    },
    initSocialMediaFunctionality: function initSocialMediaFunctionality() {
      window.socialOptin();
      this.get("tracking").loadAddToAny();
      this.get("tracking").loadFacebookSDK();
    },
    initTracking: function initTracking() {
      this.get("tracking").trackingOptIn();
      this.get("tracking").trackPage();
    },

    didRender: function didRender() {
      _init.default.material(this.elementId);
    }
  });
});