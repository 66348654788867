define('hc2-ui/components/dob-picker', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var on = Ember.on,
      computed = Ember.computed,
      run = Ember.run;

  var computedProps = Ember.A(['minDate', 'maxDate', 'disabledDates', 'enabledDates', 'dateIcon']);

  var now = (0, _moment.default)();

  var bsDateTimePickerComponent = Ember.Component.extend({

    days: Array.from({ length: 31 }, function (_, i) {
      return i + 1;
    }),
    months: Array.from({ length: 12 }, function (_, i) {
      return i + 1;
    }),
    years: Array.from({ length: 150 }, function (_, i) {
      return now.format("YYYY") - i;
    }),

    status: 0,

    didRender: function didRender() {
      if (!this.get("year")) {
        this.$('.years-panel .years').scrollTop(95);
      }
    },


    actions: {
      open: function open() {
        this.open();
      },
      select: function select(property, day, e) {
        if (day < 10) {
          day = '0' + day;
        }
        this.set(property, day);
        this.incrementProperty("status");
        if (property === 'year') {
          var val = this.get("day") + '.' + this.get("month") + '.' + this.get("year");
          this.set("value", val);
          this.set('status', 0);
        }
      },
      keyDown: function keyDown(e) {
        var key = e.key;
        var keyCode = e.charCode || e.keyCode || 0;
        if (keyCode === 46 || // Del
        keyCode === 8 || // Backspace
        keyCode === 9 || // Tab
        keyCode === 37 || // Left
        keyCode === 39 // Right
        ) {
            return true;
          }
        if (keyCode == 190) {
          // dot
          if (e.target.value.length == 1) {
            return true;
          }
          var dateParts = e.target.value.split('.');
          if (dateParts.length === 2 && dateParts[1].length == 1) {
            return true;
          }
        }

        if (e.target.value.length >= 10) {
          if (e.target.selectionStart != e.target.selectionEnd) {
            return true;
          }
          return false;
        }
        if (key < '0' || key > '9') {
          return false;
        }
        console.log("keydown", e);
      },
      keyPress: function keyPress(e) {
        var l = e.target.value.length;
        var dateParts = e.target.value.split('.');
        if (l === 2 && dateParts.length === 1 || l == 5 && dateParts.length === 2) {
          e.target.value += '.';
        }
      },
      onChange: function onChange(e) {
        var result = (0, _moment.default)(e.target.value, "DD.MM.YYYY");
        this.set("value", result.isValid() ? result.format("DD.MM.YYYY") : "");
        e.target.value = this.get("value");
        console.log("change", e, result);
      }
    },

    open: function open() {
      if (this.get("status") === 0) {
        this.set("status", 1);
      }
    },
    close: function close() {
      this.set("status", 0);
    },
    focusOut: function focusOut(e) {
      this.close();
    },
    focusIn: function focusIn() {
      this.open();
    },
    mouseDown: function mouseDown(e) {
      if (this.$('.selector-panel')[0].contains(e.target)) {
        e.preventDefault();
      }
    }
  });

  exports.default = bsDateTimePickerComponent;
});