define("hc2-ui/templates/components/apps/duration-timer", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 109
          }
        },
        "moduleName": "hc2-ui/templates/components/apps/duration-timer.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "duration-label");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(":");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("span");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("span");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(fragment, [4]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
        morphs[1] = dom.createAttrMorph(element0, 'class');
        morphs[2] = dom.createMorphAt(element0, 0, 0);
        morphs[3] = dom.createMorphAt(element0, 2, 2);
        morphs[4] = dom.createAttrMorph(element1, 'class');
        morphs[5] = dom.createMorphAt(element1, 0, 0);
        morphs[6] = dom.createMorphAt(element1, 2, 2);
        return morphs;
      },
      statements: [["inline", "ta", ["duration"], [], ["loc", [null, [1, 29], [1, 46]]], 0, 0], ["attribute", "class", ["concat", ["duration-minutes ", ["subexpr", "unless", [["get", "minutesRunning", ["loc", [null, [2, 39], [2, 53]]], 0, 0, 0, 0], "is-zero"], [], ["loc", [null, [2, 30], [2, 65]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "minutesRunning", ["loc", [null, [2, 67], [2, 85]]], 0, 0, 0, 0], ["inline", "ta", ["minutes"], [], ["loc", [null, [2, 86], [2, 102]]], 0, 0], ["attribute", "class", ["concat", ["duration-seconds ", ["subexpr", "unless", [["get", "secondsRunning", ["loc", [null, [3, 39], [3, 53]]], 0, 0, 0, 0], "is-zero"], [], ["loc", [null, [3, 30], [3, 65]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "secondsRunning", ["loc", [null, [3, 67], [3, 85]]], 0, 0, 0, 0], ["inline", "ta", ["seconds"], [], ["loc", [null, [3, 86], [3, 102]]], 0, 0]],
      locals: [],
      templates: []
    };
  }());
});