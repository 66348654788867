define("hc2-ui/templates/components/ml/app-locale-selector", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 8,
              "column": 0
            }
          },
          "moduleName": "hc2-ui/templates/components/ml/app-locale-selector.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2, "type", "checkbox");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          if (this.cachedFragment) {
            dom.repairClonedNode(element1, [], true);
          }
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element1, 'checked');
          morphs[1] = dom.createAttrMorph(element1, 'onclick');
          morphs[2] = dom.createMorphAt(element0, 3, 3);
          return morphs;
        },
        statements: [["attribute", "checked", ["subexpr", "contains-obj-with-prop", [["get", "app.locales", ["loc", [null, [5, 58], [5, 69]]], 0, 0, 0, 0], "value", ["get", "locale", ["loc", [null, [5, 78], [5, 84]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [5, 86]]], 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["toggleLocale", ["get", "locale", ["loc", [null, [5, 119], [5, 125]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [5, 128]]], 0, 0], 0, 0, 0, 0], ["inline", "t", [["subexpr", "concat", ["locales.", ["get", "locale", ["loc", [null, [6, 25], [6, 31]]], 0, 0, 0, 0], ".label"], [], ["loc", [null, [6, 6], [6, 41]]], 0, 0]], [], ["loc", [null, [6, 2], [6, 43]]], 0, 0]],
        locals: ["locale"],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "hc2-ui/templates/components/ml/app-locale-selector.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "app-locale-selector");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(":\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element2, 1, 1);
        morphs[1] = dom.createMorphAt(element2, 3, 3);
        return morphs;
      },
      statements: [["inline", "t", ["locales.label"], [], ["loc", [null, [2, 0], [2, 21]]], 0, 0], ["block", "each", [["get", "i18n.locales", ["loc", [null, [3, 8], [3, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 0], [8, 9]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});