define('hc2-ui/routes/backend', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        defaults: Ember.inject.service(),
        config: Ember.inject.service(),
        session: Ember.inject.service(),

        model: function model() {
            return Ember.RSVP.hash({
                platform: this.get("config").getPlatform(),
                userAuthorityInfo: this.store.queryRecord('userAuthorityInfo', {}),
                userIntegrations: this.store.queryRecord('userIntegration', { platformId: this.get("config").getPlatform().id }),
                domains: this.store.findAll('domain')

            });
        },
        afterModel: function afterModel(model) {
            this.set("pageTitle", model.platform.get("name"));
        },

        setupController: function setupController(controller, model) {
            this._super.apply(this, arguments);
            controller.set('model', model);
            this.set('session.data.authenticated.userAuthorityInfo', model.userAuthorityInfo);
        },
        actions: {
            viewAppDetails: function viewAppDetails(app) {
                var route = this;
                this.store.findRecord("apps/" + app.get('appType'), app.get('id')).then(function () {
                    route.transitionTo('backend.apps.details', app.get('appType'), app.get('id'));
                });
            },
            saveApp: function saveApp(appType, data, promise) {
                var that = this;
                var newApp = data.get("isNew");
                var resolve = function resolve(record) {
                    if (promise) {
                        promise.resolve();
                    } else {
                        var route = 'backend.apps.' + (newApp ? 'edit' : 'details');
                        that.transitionTo(route, record.get('appType'), record.get('id'));
                    }
                };
                var reject = function reject(err) {
                    if (promise) {
                        promise.reject();
                    }
                    console.warn("Error while saving: " + err);
                };

                data.save().then(resolve, reject);
            },
            createApp: function createApp(appType) {
                this.transitionTo('backend.apps.create', appType);
            }
        }
    });
});