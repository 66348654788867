define("hc2-ui/models/user-authority-info", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    username: _emberData.default.attr("string"),
    authorities: _emberData.default.attr(),
    platforms: _emberData.default.attr(),
    email: _emberData.default.attr("string")
  });
});