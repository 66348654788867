define("hc2-ui/templates/backend/mailer", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 19,
            "column": 0
          }
        },
        "moduleName": "hc2-ui/templates/backend/mailer.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h2");
        var el2 = dom.createTextNode("Mailer");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h3");
        var el2 = dom.createTextNode("subject");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h3");
        var el2 = dom.createTextNode("template");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h3");
        var el2 = dom.createTextNode("participation ids");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" vote activation - (template and subject not used)");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("hr");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h3");
        var el2 = dom.createTextNode("testrecipient");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("a");
        dom.setAttribute(el1, "class", "btn btn-primary");
        var el2 = dom.createTextNode("send");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [23]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 9, 9, contextualElement);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [13]), 0, 0);
        morphs[3] = dom.createMorphAt(fragment, 15, 15, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 21, 21, contextualElement);
        morphs[5] = dom.createAttrMorph(element0, 'onclick');
        return morphs;
      },
      statements: [["inline", "input", [], ["class", "form-control ", "value", ["subexpr", "@mut", [["get", "subject", ["loc", [null, [5, 36], [5, 43]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [5, 0], [5, 45]]], 0, 0], ["inline", "textarea", [], ["class", "form-control lg-textarea", "value", ["subexpr", "@mut", [["get", "template", ["loc", [null, [8, 50], [8, 58]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [8, 0], [8, 60]]], 0, 0], ["inline", "input", [], ["type", "checkbox", "checked", ["subexpr", "@mut", [["get", "voteIds", ["loc", [null, [11, 37], [11, 44]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [11, 5], [11, 46]]], 0, 0], ["inline", "textarea", [], ["class", "form-control lg-textarea", "value", ["subexpr", "@mut", [["get", "participationIds", ["loc", [null, [12, 50], [12, 66]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [12, 0], [12, 68]]], 0, 0], ["inline", "input", [], ["class", "form-control ", "value", ["subexpr", "@mut", [["get", "testRecipient", ["loc", [null, [16, 36], [16, 49]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [16, 0], [16, 51]]], 0, 0], ["attribute", "onclick", ["subexpr", "action", ["sendMails"], [], ["loc", [null, [null, null], [18, 57]]], 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  }());
});