define("hc2-ui/components/controls/scratch-card", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    redraw: function () {
      this.didInsertElement();
    }.observes("segmentsDef", "segmentsDef.@each", "segmentsDef.@each.text", "segmentsDef.@each.fillStyle", "segmentsDef.@each.textFillStyle", "showImage", "drawText", "imageOverlay", "image"),

    // Todo: pins, pointer, segmentsDescription

    actions: {
      reset: function reset() {
        this.reset();
      }
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      // register public api
      var publicApi = this.get("publicApi");
      if (publicApi) {
        publicApi.actions = {
          reset: function reset() {
            _this.reset();
          }
        };
      }
      this.set("publicApi", publicApi);
    },
    reset: function reset() {
      this.didInsertElement();
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      Ember.run.scheduleOnce('afterRender', function () {
        _this2.set("scratchCard", new window.ScratchCard("scratchCard-container", _this2.get("image"), [{
          percent: 'any',
          action: function action(canvas, percent) {
            _this2.set("percent", percent);
          }
        }, {
          percent: 5,
          action: function action() {
            if (_this2.get("scratchStarted")) {
              _this2.get("scratchStarted")();
            }
          }
        }, {
          percent: 70,
          action: function action(canvas) {
            window.$(canvas).css("display", "none");
            if (_this2.get("scratchFinished")) {
              _this2.get("scratchFinished")();
            }
          }
        }]));
      });
    }
  });
});