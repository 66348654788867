define("hc2-ui/controllers/app/view", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    device: Ember.inject.service(),
    embed: Ember.inject.service(),
    queryParams: ["locale", "contentId", "tab", "step", "door", "d", "ad"],
    locale: null,
    contentId: null,
    tab: null,
    step: 1,
    door: null,
    ad: null,

    _lastStep: null,

    onStepChange: function () {
      if (!(this.get("_lastStep") === null && this.get("step") === "start")) {
        if (!isNaN(this.get("step") && typeof this.get("_lastStep") === "number")) {
          this.get("embed").notifyStepChange(this.get("step"));
        }
      }
      this.set("_lastStep", this.get("step"));
    }.observes("step")
  });
});