define('hc2-ui/components/controls/slot-machine', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    store: Ember.inject.service(),

    slotCount: Ember.computed.alias('appData.maxItems'),
    items: Ember.computed('appData.pairs.[]', 'appData.pairs.@each.img', function () {
      var _this = this;

      var items = Ember.A();
      var pairs = this.get('appData.pairs');
      if (pairs.length > 0) {
        while (items.length < 10) {
          for (var i = 0; i < pairs.length; i++) {
            items.pushObject({
              pair: pairs[i],
              pairIndex: i,
              img: Ember.String.htmlSafe("background-image: url(" + pairs[i].img + ")"),
              imgUrl: pairs[i].img
            });
          }
        }
      }
      Ember.run.scheduleOnce('afterRender', function () {
        _this.initSlotWheel();
      });
      return items;
    }),
    slots: Ember.computed('slotCount', function () {
      var count = parseInt(this.get('slotCount'));
      var slots = [];
      return new Array(count).fill().map(function (v, i, arr) {
        var animationDuration = Math.floor(Math.random() * 10);
        var position = Math.floor(Math.random() * (count + 1));
        return Ember.Object.create({
          index: i,
          position: Math.floor(Math.random() * (count + 1)),
          state: 'initial',
          animationDuration: Ember.String.htmlSafe("animation-duration: 1." + animationDuration + "s;")
        });
      });
    }),
    slotSize: Ember.computed('slotCount', function () {
      var count = this.get('slotCount');
      return 100 / count;
    }),
    isRotating: Ember.computed("slots.@each.state", function () {
      return this.get('slots').filterBy("state", "rotate").length > 0;
    }),
    isInitial: Ember.computed("slots.@each.state", function () {
      var slots = this.get('slots');
      return slots.filterBy("state", "initial").length === slots.length;
    }),

    actions: {
      startWithTrigger: function startWithTrigger(event) {
        var _this2 = this;

        this.$('.slot-trigger .arm').addClass('animate');
        Ember.run.later(function () {
          _this2.$('.slot-trigger .arm').removeClass('animate');
        }, 1000);
        this.send('start');
      },
      start: function start() {
        if (!this.get('isRotating')) {
          this.get('slots').forEach(function (slot) {
            slot.set("state", "rotate");
          });
        } else {
          this.stopNext();
        }
      },
      stopNext: function stopNext() {
        this.stopNext();
      },
      stopSlot: function stopSlot(slot) {
        this.stopSlot(slot);
      },
      initSlotWheel: function initSlotWheel() {
        this.initSlotWheel();
      },
      resetSlots: function resetSlots() {
        var _this3 = this;

        this.set('uuid', null);
        this.set('participationSaved', null);
        this.notifyPropertyChange('slots');
        Ember.run.scheduleOnce('afterRender', function () {
          _this3.initSlotWheel();
        });
      }
    },
    init: function init() {
      var _this4 = this;

      this._super.apply(this, arguments);

      // register public api
      var publicApi = this.get("publicApi");
      if (publicApi) {
        publicApi.actions = {
          stopNext: function stopNext() {
            _this4.stopNext();
          }
        };
      }
      this.set("publicApi", publicApi);
    },
    didInsertElement: function didInsertElement() {
      var _this5 = this;

      Ember.run.later(function () {
        _this5.initSlotWheel();
      });
    },
    initSlotWheel: function initSlotWheel() {
      var positionStyles = "";
      $(".slot-wheel").each(function (item, slotIndex) {
        var $panes = $(".item", this),
            paneSize = $panes.first().height(),
            zDepth = Math.floor(paneSize / (2 * Math.tan(Math.PI / $panes.length)));

        var setPositionStyles = positionStyles === "";
        $panes.each(function (index) {
          var xAngle = 360 / $panes.length * index;
          $(this).attr("style", Ember.String.htmlSafe("transform: rotateX(-" + xAngle + "deg) translateZ(" + zDepth + "px);"));
          if (setPositionStyles) {
            var random = Math.floor(Math.random() * 10);
            positionStyles += ".slot-wheel.position-" + index + " { " + "transform: rotateX(" + xAngle + "deg); " + "transition: transform 1s;}";
          }
        });
      });
      this.set("positionStyles", positionStyles);
    },
    stopNext: function stopNext() {
      var rotatingSlots = this.get('slots').filterBy('state', 'rotate');
      if (rotatingSlots.length) {
        this.stopSlot(rotatingSlots[0]);
      }
    },
    stopSlot: function stopSlot(slot) {
      var _this6 = this;

      if (slot.get('state') == 'rotate') {

        var stoppedSlots = this.get('slots').filterBy("state", "stopped").length;

        var canDraw = this.get('appData.minItems') > stoppedSlots || this.get('participationSaved');
        if (!canDraw) {
          this.requestParticipation(this.get('uuid'));
        } else {
          var uuid = this.get('uuid');
          var action = this.get('store').createRecord("action", { action: "draw", context: { appId: this.get('appData.id'), uuid: uuid, index: slot.index } });
          action.save().then(function (response) {
            _this6.set('uuid', response.get('context.uuid'));
            var drawIndex = response.get('context.draw');
            if (drawIndex === -1) {
              window.console.log("draw error");
            } else {
              slot.set('position', drawIndex);
              slot.set('state', 'stopped');
              if (_this6.get('isRotating') === false) {
                Ember.run.later(function () {
                  _this6.sendStatus('showPrize');
                }, 3000);
              }
            }
          }, function () {
            window.console.log("draw action failed");
          });
        }
      }
    },

    setSlot1FromEditorState: function () {
      var selectedPair = this.get('appStates.selectedEditorPair');
      var slots = this.get('slots');
      if (slots[0]) {
        slots.set('firstObject.position', selectedPair);
      }
    }.observes('appStates.selectedEditorPair')
  });
});