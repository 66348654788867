define('hc2-ui/components/ml/app-translation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ml-app-translation'],
    i18n: Ember.inject.service(),

    selectedLocale: null,
    type: "textfield",

    keyDotted: Ember.computed("key", function () {
      return this.get("key").replace(/_/g, ".");
    }),

    _selectedLocaleSyncAppLocale: Ember.observer("i18n.appLocale", function () {
      this.set("selectedLocale", this.get("i18n.appLocale"));
    }),
    actions: {
      selectLocale: function selectLocale(locale) {
        var _this = this;

        var selectedLocale = this.get("selectedLocale") === locale ? null : locale;
        this.set("selectedLocale", null);
        Ember.run.later(function () {
          _this.set("selectedLocale", selectedLocale);
        }, 50);
      },
      setImageFromUpload: function setImageFromUpload(url) {
        this.set("app.translations." + this.get("currentAppDataKey"), url);
      }
    },
    currentAppDataKey: Ember.computed("selectedLocale", "key", function () {
      return this.get("selectedLocale") + (this.get("messageTranslation") ? "_" : "_appdata_") + this.get("key");
    }),
    defaultLocaleAppDataKey: Ember.computed("app.defaultLocaleParent", "key", function () {
      return this.get("app.defaultLocaleParent") + (this.get("messageTranslation") ? "_" : "_appdata_") + this.get("key");
    })
  });
});