define("hc2-ui/components/apps/contest/judging-view", ["exports", "hc2-ui/helpers/camel-to-dash"], function (exports, _camelToDash) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    appData: {},

    firstWinner: null,
    otherWinner: null,

    preview: false,

    _loadWinners: function () {
      var that = this;
      this.set("otherWinners", null);
      this.get('store').query(_camelToDash.default.compute([this.get('appData.appType')]) + '-participation', { sort: 'juryRank', order: 'asc', max: 100, appId: this.get('appData.id'), filter: 'winner' }).then(function (loadedParticipations) {
        that.set('firstWinner', loadedParticipations.objectAt(0));
        loadedParticipations.removeAt(0);
        that.set('otherWinner', loadedParticipations);
      });
    }.observes("appData.juryWinners"),

    actions: {
      viewParticipation: function viewParticipation(participation) {
        this.sendAction("viewParticipation", participation);
      }
    },

    didInitAttrs: function didInitAttrs() {
      this._loadWinners();
    },
    didRender: function didRender() {
      this.initSlider();
    },
    initSlider: function initSlider() {
      window.$(this.element.querySelector('.slick')).slick({
        dots: true, infinite: false, speed: 300, slidesToShow: 4, slidesToScroll: 4, autoplay: !this.get('preview'),
        responsive: [{
          breakpoint: 1024,
          settings: {
            slidesToShow: 3, slidesToScroll: 3, infinite: true, dots: true
          }
        }, {
          breakpoint: 600,
          settings: {
            slidesToShow: 2, slidesToScroll: 2
          }
        }, {
          breakpoint: 480,
          settings: {
            slidesToShow: 1, slidesToScroll: 1
          }
        }]
      });
    }

  });
});