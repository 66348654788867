define("hc2-ui/components/apps/code-promotion/app-view", ["exports", "hc2-ui/lib/make/init"], function (exports, _init) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    api: Ember.inject.service(),
    tracking: Ember.inject.service(),
    session: Ember.inject.service(),

    classNameBindings: ['showPrize:hasPrize', 'showThanks:hasParticipation'],

    // TODO: remove dummy presets
    captchaResponse: "test",
    codeInput: null,
    prize: null,
    participation: null,

    showCodeInput: Ember.computed.empty("prize"),
    showPrize: Ember.computed.notEmpty("prize"),
    showThanks: Ember.computed.alias("participation.id"),

    appData: {},
    editMode: false,
    selectedPage: 0,
    appStates: {
      selectedQuestion: 0,
      showWelcomePage: false
    },

    active: Ember.computed('appData.startDate', 'appData.endDate', function () {
      var now = new Date();
      return now > this.get('appData.startDate') && now < this.get('appData.endDate');
    }),
    changingSelectedQuestion: Ember.observer('appStates.selectedQuestion', function () {
      this.set("selectedPage", this.get("appStates.selectedQuestion"));
    }),

    changingSelected: Ember.observer('appData.questions.[]', function () {
      this.rerender();
    }),

    showTabs: Ember.computed("appData.descriptionHeader", "appData.additionalInformationHeader", function () {
      return this.get("appData.descriptionHeader") || this.get("appData.additionalInformationHeader");
    }),
    showStartButton: Ember.computed.equal("status", "start"),
    canBeStopped: Ember.computed("status", "appData.noForm", function () {
      return this.get("status") === "wheelRunning" && this.get("appData.noForm");
    }),

    init: function init() {
      this._super();
      this.set("appStates.showWelcomePage", this.get('appData.welcomePage') && !this.get('editMode'));
      if (!this.get('editMode') && !this.get("appData.isNew")) {
        this.get('store').createRecord("view", { app: this.get('appData') }).save();
      }
    },
    didRender: function didRender() {
      _init.default.material(this.elementId);
    },

    actions: {
      captchaFulfilled: function captchaFulfilled(response) {
        this.set("captchaResponse", response);
      },
      submitCode: function submitCode() {
        var _this = this;

        if (this.get("submitting")) {
          return false;
        }

        var _getProperties = this.getProperties(["codeInput", "captchaResponse"]),
            codeInput = _getProperties.codeInput,
            captchaResponse = _getProperties.captchaResponse;

        this.set("participateButtonClicked", true);

        if (this.get("appData.contactFormOnCodeInput")) {
          if (!this.isActiveFormValid()) {
            return;
          }
        }

        if (codeInput && captchaResponse) {
          this.set("submitting", true);
          this.get("api").post("/appCode/submit", {
            "appId": this.get("appData.id"),
            "code": this.get("codeInput"),
            "captchaResponse": this.get("captchaResponse")
          }).then(function (response) {
            // set prize on successful response
            var prize = response.prize;
            _this.set("codeInput", response.code);
            if (_this.get("appData.contactFormOnCodeInput")) {
              _this.submitContactData(_this.get('appData.prizes').objectAt(prize));
            } else {
              _this.set('prize', _this.get('appData.prizes').objectAt(prize));
            }
          }).fail(function (errorResponse) {
            // handle errors
            var status = errorResponse.status;
            var message = errorResponse.responseJSON.message;

            if (status === 404) {
              _this.set("invalidCode", _this.get("codeInput"));
            } else if (status === 403) {
              _this.set("captchaResponse", null);
            } else if (status === 409) {
              _this.set("codeAlreadyUsed", _this.get("codeInput"));
            } else if (status === 503) {
              _this.set("drawServerError", true);
            }

            window.grecaptcha.reset(); // we always reset captcha as only valid once
          }).always(function () {
            _this.set("submitting", false);
          });
        }
        return false;
      },
      submitContactData: function submitContactData() {
        return this.submitContactData();
      },
      reset: function reset() {
        this.set("prize", null);
        this.set("participation", null);
        this.set("codeInput", null);
        this.set("participateButtonClicked", false);
      },

      hideWelcomePage: function hideWelcomePage() {
        this.set("appStates.showWelcomePage", false);
      }
    },
    isActiveFormValid: function isActiveFormValid() {
      // check required fields on current page
      var requiredInputs = window.$('.codePromotionContainer input[required]');
      var requiredPatternInputs = window.$('.codePromotionContainer input[data-required-pattern]');
      var inputsToCheck = window.$.merge(requiredInputs, requiredPatternInputs);
      var allRequiredSet = true;
      for (var i = 0; i < inputsToCheck.length; i++) {
        var type = requiredInputs[i].type;
        var val = null;
        var field = window.$('.codePromotionContainer form input[name="' + requiredInputs[i].name + '"]');
        if ('radio' === type || 'checkbox' === type) {
          val = window.$('.codePromotionContainer form input[name="' + requiredInputs[i].name + '"]:checked').val();
        } else {
          val = field.val();
        }

        var required = field.prop("required");
        var requiredPattern = field.attr("data-required-pattern");

        var requiredValid = required ? val : true;
        var patternValid = true;
        if (requiredPattern && val) {
          patternValid = val.match(requiredPattern);
        }

        if (!requiredValid || !patternValid) {
          var fg = field.closest('.form-group');
          fg.addClass('has-error');
          allRequiredSet = false;
          var msgBox = fg.find('.mandetoryFieldMessage');
          if (msgBox.length === 0) {
            msgBox = window.$('<div class="mandetoryFieldMessage"><span class="text-danger"></span></div>');
            fg.append(msgBox);
          }
          var msg = !requiredValid ? this.get('i18n').ta('mandetory.field.hint.' + type) : this.get('i18n').ta('mandetory.field.patternHint.' + type);
          msgBox.find('span').text(msg);
          msgBox.show().delay(5000).fadeOut();
        } else {
          field.closest('.form-group').removeClass('has-error');
        }
      }
      return allRequiredSet;
    },

    didInsertElement: function didInsertElement() {
      _init.default.material(this.elementId);
    },
    submitContactData: function submitContactData(prizeToSetAfterSuccess) {
      var _this2 = this;

      if (this.isActiveFormValid()) {
        var o = {};
        var a = window.$('#' + this.get('elementId') + ' form').serializeArray();
        window.$.each(a, function () {
          if (o[this.name] !== undefined) {
            if (!o[this.name].push) {
              o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
          } else {
            o[this.name] = this.value || '';
          }
        });

        o.locale = this.get("i18n.appLocale");
        o.source = this.get("session.source");
        o.code = this.get("codeInput");
        if (window.hc2_ad && window.hc2_ad.form) Ember.$.extend(o, window.hc2_ad.form);

        var participation = this.get('store').createRecord("participation", {
          app: this.get('appData'), formData: o, domain: window.location.hostname
        });
        this.set('participation', participation);
        if (!this.get("appData.demo")) {
          participation.save().then(function () {
            if (prizeToSetAfterSuccess) {
              _this2.set('prize', prizeToSetAfterSuccess);
            }
          });
          this.get("tracking").sendLeadEvent(this.get("appData.title"));
        } else {
          this.set('prize', prizeToSetAfterSuccess);
          this.set('participation.id', "demo");
        }
      }
    }

  });
});