define("hc2-ui/templates/components/controls/sort-button", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 1,
            "column": 150
          }
        },
        "moduleName": "hc2-ui/templates/components/controls/sort-button.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("i");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createElementMorph(element0);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["pointer fa ", ["subexpr", "if", [["subexpr", "is-eq", [["get", "currentSortValue", ["loc", [null, [1, 54], [1, 70]]], 0, 0, 0, 0], ["get", "sortValue", ["loc", [null, [1, 71], [1, 80]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 47], [1, 81]]], 0, 0], ["subexpr", "str-concat", ["fa-sort-", ["get", "sortDirection", ["loc", [null, [1, 105], [1, 118]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 82], [1, 119]]], 0, 0], "fa-unsorted disabled"], [], ["loc", [null, [1, 42], [1, 144]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["setSort"], [], ["loc", [null, [1, 3], [1, 23]]], 0, 0]],
      locals: [],
      templates: []
    };
  }());
});