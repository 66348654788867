define('hc2-ui/adapters/mailchimp-list', ['exports', 'hc2-ui/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType() {
      return 'mailchimp/lists';
    }
  });
});