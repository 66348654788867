define('hc2-ui/models/apps/win-wheel', ['exports', 'ember-data', 'hc2-ui/models/apps/app'], function (exports, _emberData, _app) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _app.default.extend({
        noForm: _emberData.default.attr('boolean'),
        prizes: _emberData.default.attr(),
        showQuestionSteps: _emberData.default.attr('boolean'),
        contactData: _emberData.default.attr(),
        contactFormText: _emberData.default.attr('string'),
        headerImageUri: _emberData.default.attr("string"),
        thanksText: _emberData.default.attr("string"),

        winWheelImageUrl: _emberData.default.attr("string"),
        winWheelBackgroundImageUrl: _emberData.default.attr("string"),
        drawText: _emberData.default.attr('boolean'),
        showBorder: _emberData.default.attr('boolean'),
        spinDuration: _emberData.default.attr('number', { defaultValue: 5 }),
        resultMails: _emberData.default.attr('boolean'),
        allowMultipleParticipations: _emberData.default.attr('boolean', { defaultValue: false }),
        headerContent: _emberData.default.attr('string')
    });
});