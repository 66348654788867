define("hc2-ui/templates/components/apps/slot-machine/combination-stats", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 4
              },
              "end": {
                "line": 12,
                "column": 4
              }
            },
            "moduleName": "hc2-ui/templates/components/apps/slot-machine/combination-stats.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "pull-right");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "class", "pointer");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("i");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1, 1]);
            var element5 = dom.childAt(element4, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element4, 'onclick');
            morphs[1] = dom.createMorphAt(element4, 1, 1);
            morphs[2] = dom.createAttrMorph(element5, 'class');
            return morphs;
          },
          statements: [["attribute", "onclick", ["subexpr", "action", ["toggleShowList"], [], ["loc", [null, [null, null], [7, 60]]], 0, 0], 0, 0, 0, 0], ["inline", "t", [["subexpr", "if", [["get", "showList", ["loc", [null, [8, 16], [8, 24]]], 0, 0, 0, 0], "hide", "show"], [], ["loc", [null, [8, 12], [8, 39]]], 0, 0]], [], ["loc", [null, [8, 8], [8, 41]]], 0, 0], ["attribute", "class", ["concat", ["fa fa-angle-", ["subexpr", "if", [["get", "showList", ["loc", [null, [9, 35], [9, 43]]], 0, 0, 0, 0], "up", "down"], [], ["loc", [null, [9, 30], [9, 57]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 4
              },
              "end": {
                "line": 16,
                "column": 4
              }
            },
            "moduleName": "hc2-ui/templates/components/apps/slot-machine/combination-stats.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["content", "yield", ["loc", [null, [15, 6], [15, 15]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child2 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 4
              },
              "end": {
                "line": 18,
                "column": 4
              }
            },
            "moduleName": "hc2-ui/templates/components/apps/slot-machine/combination-stats.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(": ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" / ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(element3, 0, 0);
            morphs[1] = dom.createMorphAt(element3, 2, 2);
            morphs[2] = dom.createMorphAt(element3, 4, 4);
            return morphs;
          },
          statements: [["inline", "t", ["slotMachine.combinations.matching"], [], ["loc", [null, [17, 11], [17, 52]]], 0, 0], ["content", "comboAssignment.assignedCount", ["loc", [null, [17, 54], [17, 87]]], 0, 0, 0, 0], ["content", "comboAssignment.matchingCount", ["loc", [null, [17, 90], [17, 123]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child3 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 27,
                        "column": 10
                      },
                      "end": {
                        "line": 29,
                        "column": 10
                      }
                    },
                    "moduleName": "hc2-ui/templates/components/apps/slot-machine/combination-stats.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "item-img");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    return morphs;
                  },
                  statements: [["inline", "add-val", [1, ["get", "item", ["loc", [null, [28, 47], [28, 51]]], 0, 0, 0, 0]], [], ["loc", [null, [28, 35], [28, 53]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child1 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 29,
                        "column": 10
                      },
                      "end": {
                        "line": 31,
                        "column": 10
                      }
                    },
                    "moduleName": "hc2-ui/templates/components/apps/slot-machine/combination-stats.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("img");
                    dom.setAttribute(el1, "class", "item-img");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element0 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createAttrMorph(element0, 'src');
                    return morphs;
                  },
                  statements: [["attribute", "src", ["concat", [["subexpr", "get", [["get", "items", ["loc", [null, [30, 43], [30, 48]]], 0, 0, 0, 0], ["subexpr", "concat", [["get", "item", ["loc", [null, [30, 57], [30, 61]]], 0, 0, 0, 0], ".img"], [], ["loc", [null, [30, 49], [30, 69]]], 0, 0]], [], ["loc", [null, [30, 37], [30, 72]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 26,
                      "column": 8
                    },
                    "end": {
                      "line": 32,
                      "column": 8
                    }
                  },
                  "moduleName": "hc2-ui/templates/components/apps/slot-machine/combination-stats.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "unless", [["subexpr", "get", [["get", "items", ["loc", [null, [27, 25], [27, 30]]], 0, 0, 0, 0], ["subexpr", "concat", [["get", "item", ["loc", [null, [27, 39], [27, 43]]], 0, 0, 0, 0], ".img"], [], ["loc", [null, [27, 31], [27, 51]]], 0, 0]], [], ["loc", [null, [27, 20], [27, 52]]], 0, 0]], [], 0, 1, ["loc", [null, [27, 10], [31, 21]]]]],
                locals: ["item"],
                templates: [child0, child1]
              };
            }();
            var child1 = function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 35,
                      "column": 8
                    },
                    "end": {
                      "line": 38,
                      "column": 8
                    }
                  },
                  "moduleName": "hc2-ui/templates/components/apps/slot-machine/combination-stats.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1, "class", "fa fa-arrow-right");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                  morphs[1] = dom.createMorphAt(fragment, 5, 5, contextualElement);
                  return morphs;
                },
                statements: [["inline", "t", ["slotMachine.combination"], [], ["loc", [null, [37, 10], [37, 41]]], 0, 0], ["inline", "add-val", [1, ["get", "ca.assigned", ["loc", [null, [37, 54], [37, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 42], [37, 67]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 23,
                    "column": 6
                  },
                  "end": {
                    "line": 41,
                    "column": 6
                  }
                },
                "moduleName": "hc2-ui/templates/components/apps/slot-machine/combination-stats.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2, "class", "items");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2, "class", "assigned-to-info");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element1, 'class');
                morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
                morphs[2] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["combo-assignment-line ", ["subexpr", "if", [["subexpr", "is-eq", [["get", "ca.assigned", ["loc", [null, [24, 52], [24, 63]]], 0, 0, 0, 0], ["get", "combiIndex", ["loc", [null, [24, 64], [24, 74]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 45], [24, 75]]], 0, 0], "assigned", "not-assigned"], [], ["loc", [null, [24, 40], [24, 103]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "each", [["get", "ca.combo", ["loc", [null, [26, 16], [26, 24]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [26, 8], [32, 17]]]], ["block", "unless", [["subexpr", "is-eq", [["get", "ca.assigned", ["loc", [null, [35, 25], [35, 36]]], 0, 0, 0, 0], ["get", "combiIndex", ["loc", [null, [35, 37], [35, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [35, 18], [35, 48]]], 0, 0]], [], 1, null, ["loc", [null, [35, 8], [38, 19]]]]],
              locals: [],
              templates: [child0, child1]
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 22,
                  "column": 4
                },
                "end": {
                  "line": 42,
                  "column": 4
                }
              },
              "moduleName": "hc2-ui/templates/components/apps/slot-machine/combination-stats.hbs"
            },
            isEmpty: false,
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "is-lt", [["get", "index", ["loc", [null, [23, 19], [23, 24]]], 0, 0, 0, 0], 100], [], ["loc", [null, [23, 12], [23, 29]]], 0, 0]], [], 0, null, ["loc", [null, [23, 6], [41, 13]]]]],
            locals: ["ca", "index"],
            templates: [child0]
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 43,
                  "column": 6
                },
                "end": {
                  "line": 45,
                  "column": 6
                }
              },
              "moduleName": "hc2-ui/templates/components/apps/slot-machine/combination-stats.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "m-l-20");
              var el2 = dom.createTextNode("... ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 4
              },
              "end": {
                "line": 47,
                "column": 4
              }
            },
            "moduleName": "hc2-ui/templates/components/apps/slot-machine/combination-stats.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "combo-list ");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element2, 1, 1);
            morphs[1] = dom.createMorphAt(element2, 2, 2);
            return morphs;
          },
          statements: [["block", "each", [["get", "displayingCombinations", ["loc", [null, [22, 12], [22, 34]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [22, 4], [42, 13]]]], ["block", "if", [["subexpr", "is-gt", [["get", "comboAssignment.combinations.length", ["loc", [null, [43, 19], [43, 54]]], 0, 0, 0, 0], ["get", "maxDisplay", ["loc", [null, [43, 55], [43, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [43, 12], [43, 66]]], 0, 0]], [], 1, null, ["loc", [null, [43, 6], [45, 13]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 49,
              "column": 4
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/slot-machine/combination-stats.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "matching-combinations");
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element6, 1, 1);
          morphs[1] = dom.createMorphAt(element6, 3, 3);
          morphs[2] = dom.createMorphAt(element6, 5, 5);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "is-gt", [["get", "comboAssignment.combinations.length", ["loc", [null, [5, 17], [5, 52]]], 0, 0, 0, 0], 0], [], ["loc", [null, [5, 10], [5, 55]]], 0, 0]], [], 0, null, ["loc", [null, [5, 4], [12, 11]]]], ["block", "if", [["get", "hasBlock", ["loc", [null, [14, 10], [14, 18]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [14, 4], [18, 11]]]], ["block", "if", [["get", "showList", ["loc", [null, [20, 10], [20, 18]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [20, 4], [47, 11]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 49,
              "column": 4
            },
            "end": {
              "line": 51,
              "column": 2
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/slot-machine/combination-stats.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "fa fa-warning");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["slotMachine.combinations.notAssignedYet"], [], ["loc", [null, [50, 35], [50, 82]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 52,
            "column": 6
          }
        },
        "moduleName": "hc2-ui/templates/components/apps/slot-machine/combination-stats.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "slot-combination-stats");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["get", "comboAssignment.autoAssign", ["loc", [null, [2, 8], [2, 34]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 2], [51, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});