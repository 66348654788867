define("hc2-ui/components/apps/contest/participation-view", ["exports", "hc2-ui/lib/make/init"], function (exports, _init) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    participation: {},
    preview: false,

    publicFormData: Ember.computed("participation", "participation.publicFormData", "participation.formData", function () {
      var participation = this.get("participation");
      if (participation.get("isNew")) {
        // we need to build it from form data
        return this.get("participation.formData");
      }
      return this.get("participation.publicFormData");
    }),
    didInsertElement: function didInsertElement() {},

    didRender: function didRender() {
      _init.default.material(this.elementId);
      this.initSlider();
    },
    showExtraMedia: false,
    selectedMediaUrl: null,
    actions: {
      viewMedia: function viewMedia(mediaUrl) {
        this.set("selectedMediaUrl", mediaUrl);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set("selectedMediaUrl", this.get("participation.mediaUrl"));
      if (!Ember.isEmpty(this.get("participation.extraMedia"))) {
        this.set("showExtraMedia", true);
      }
    },

    participationChanged: function () {
      var _this = this;

      this.set("selectedMediaUrl", this.get("participation.mediaUrl"));
      this.set("showExtraMedia", false);
      if (!Ember.isEmpty(this.get("participation.extraMedia"))) {
        Ember.run.later(function () {
          // we need to totally rerender slick slider as it is not updatable
          _this.set("showExtraMedia", true);
        }, 200);
      }
    }.observes("participation"),
    initSlider: function initSlider() {
      try {
        var infinite = true;
        var dots = true;
        var slickSlider = this.element.querySelector('.slick');

        if (!window.$(slickSlider).hasClass("slick-initialized")) {
          window.$(slickSlider).slick({
            dots: dots,
            infinite: infinite,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 4,
            autoplay: !this.get('preview'),
            responsive: [{
              breakpoint: 1024,
              settings: {
                slidesToShow: 3, slidesToScroll: 3, infinite: true, dots: true
              }
            }, {
              breakpoint: 600,
              settings: {
                slidesToShow: 2, slidesToScroll: 2
              }
            }, {
              breakpoint: 480,
              settings: {
                slidesToShow: 1, slidesToScroll: 1
              }
            }]
          });
        }
      } catch (e) {
        console.error(e);
      }
    }
  });
});