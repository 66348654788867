define("hc2-ui/components/apps/direct-link/app-view", ["exports", "hc2-ui/lib/make/init"], function (exports, _init) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isHTTPS: Ember.computed("appData.link", function () {
      return this.get("appData.link").startsWith("https");
    })
  });
});