define('hc2-ui/models/apps/direct-link', ['exports', 'ember-data', 'hc2-ui/models/apps/app'], function (exports, _emberData, _app) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _app.default.extend({
    link: _emberData.default.attr("string"),
    openInTarget: _emberData.default.attr("string", { defaultValue: "_blank" })
  });
});