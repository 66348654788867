define('hc2-ui/components/apps/legal-settings', ['exports', 'hc2-ui/lib/make/init'], function (exports, _init) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),

    editableLabels: Ember.computed("appData.needsThirdPartyOptIn", function () {
      var labels = ["cookieHint_text"];
      if (this.get("appData.needsThirdPartyOptIn")) {
        labels.addObjects(["cookieHint_text2", "cookieHint_mandetory", "cookieHint_mandetoryHint", "cookieHint_statistics", "cookieHint_statisticsHint", "cookieHint_socialMediaFunctionality", "cookieHint_socialMediaFunctionalityHint", "cookieHint_acceptSelected", "cookieHint_acceptAll"]);
      } else {
        "cookieHint_ok";
      }
      labels.addObjects(["cookieHint_showMore"]);
      return labels;
    }),
    editorLabels: ["cookieHint_text", "cookieHint_text2", "cookieHint_statisticsHint", "cookieHint_socialMediaFunctionalityHint", "cookieHint_mandetoryHint"],
    actions: {
      showCookieBanner: function showCookieBanner() {
        this.set("appStates.cookieBannerAccepted", false);
      }
    }
  });
});