define("hc2-ui/helpers/add-translation-to-objects", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    i18n: Ember.inject.service(),
    /**
     * params.0 array
     * params.1 property to use for translation
     * params.2 property to set with translation
     * params.3 optional, prefix for translation property params.2
     * @param params
     * @param hash
     * @returns {*}
       */
    compute: function compute(params /*, hash*/) {
      var array = params[0];
      var prefix = params.length > 3 ? params[3] + "." : "";

      for (var i = 0; i < array.length; i++) {
        array[i][params[2]] = this.get('i18n').ta(prefix + array[i][params[1]]);
      }
      return array;
    }
  });
});