define('hc2-ui/models/vote', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    active: _emberData.default.attr('boolean'),
    rating: _emberData.default.attr('number'),
    email: _emberData.default.attr("string"),
    reCaptchaResponse: _emberData.default.attr('string'),
    participation: _emberData.default.belongsTo('participation'),
    dateCreated: _emberData.default.attr('date'),
    locale: _emberData.default.attr("string"),

    domain: _emberData.default.attr('string') // just ui side for generating activation links
  });
});