define('hc2-ui/components/-el/toggle-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['toggle-button'],
    iconClass: "glyphicon glyphicon-info-sign text-success",
    visible: false,

    actions: {
      toggle: function toggle() {
        this.set("visible", !this.get("visible"));
      }
    }

  });
});