define("hc2-ui/transforms/csv", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      serialized = serialized ? serialized.split(",") : [];
      var deserialized = [];
      serialized.forEach(function (entry) {
        if (entry) {
          deserialized.push({ value: entry });
        }
      });
      return deserialized;
    },

    serialize: function serialize(deserialized) {
      var array = [];
      if (deserialized) {
        deserialized.forEach(function (entry) {
          if (entry && entry.value) {
            array.push(entry.value);
          }
        });
      }
      return array.join();
    }
  });
});