define("hc2-ui/lib/video/videouploader-tools", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	/**
  * Tools used for the video-"uploader" used in contest/participate. I.e.
  * parse url for correct URLs, create valid URLs for embedded player etc.
  */
	var $ = window.$;

	function Videouploader() {
		// Define String constants
		this.YOUTUBE_LINKS = ["youtu.be/", "youtube.com/watch?v=", "youtube.com/embed/"];
		this.YOUTUBE_EMBED = "https://www.youtube.com/embed/VIDEO_ID?rel=0";

		this.VIMEO_LINKS = ["vimeo.com/"];
		this.VIMEO_EMBED = "https://player.vimeo.com/video/VIDEO_ID";

		// Configure timeout
		this.TIMEOUT_INTERVAL = 500;

		// Define fields
		this.source = undefined;
		this.drain = undefined;
		this.playerWrapper = undefined;
		this.timeout = undefined;
		this.lastVideoId = ''; // to check, if it has changed since last timeout
		this.videoFound = false;

		// Function to cleanup the start of the string provided
		this.cleanupLinkStart = function (link) {
			var linkStarts = ["http://", "https://", "www."];

			// Strip unused link characters
			$(linkStarts).each(function (index, start) {
				if (link.toLowerCase().substr(0, start.length) === start) {
					link = link.substr(start.length);
				}
			});

			return link;
		};

		// Function to cleanup the end of the string provided
		this.cleanupLinkEnd = function (link) {
			var unwantedChars = ['/', '#', '&', '?'];

			// Cleanup VIDEO_ID (might be followed by parameters, directories etc.)
			$(unwantedChars).each(function (index, char) {
				if (link && link.indexOf(char) !== -1) {
					link = link.substr(0, link.indexOf(char));
				}
			});

			return link;
		};

		// Function is valled by timeout to check the link for validity and display the youtube player
		this.parseSource = function () {
			var value = $(this.source).val();

			if (value === undefined) {
				return;
			}

			var videoId = null;
			value = this.cleanupLinkStart(value);

			var videoType = null;

			// Check, if it's a youtube link (i.e. youtu.be/<video_id>)
			$(this.YOUTUBE_LINKS).each(function (index, link) {
				if (value.substr(0, link.length) === link) {
					videoId = value.substr(link.length);
					videoType = 'youtube';
				}
			});
			// Check, if it's a vimeo link (i.e. vimeo.com/<video_id>)
			$(this.VIMEO_LINKS).each(function (index, link) {
				if (value.substr(0, link.length) === link) {
					videoId = value.substr(link.length);
					videoType = 'vimeo';
				}
			});

			videoId = this.cleanupLinkEnd(videoId);

			if (videoId !== this.lastVideoId) {
				this.videoFound = false;
				this.lastVideoId = '';

				if (videoId && videoId.length > 0 && videoId !== this.lastVideoId) {
					this.lastVideoId = videoId;
					this.videoFound = true;

					var embedLink;
					if (videoType === 'youtube') {
						embedLink = this.YOUTUBE_EMBED.replace(/VIDEO_ID/, videoId);
					} else if (videoType === 'vimeo') {
						embedLink = this.VIMEO_EMBED.replace(/VIDEO_ID/, videoId);
					}

					// Write to hidden
					this.drain.set("value", embedLink);

					// load player
					var player = this.playerWrapper.children().first();
					player.attr('src', embedLink);
					this.playerWrapper.add(player);
					this.playerWrapper.show();
				}
			}

			if (!this.videoFound) {
				this.drain.set("value", null);
				this.playerWrapper.hide();
			}

			this.myTimeout();
		};

		// Handles timeout
		this.myTimeout = function () {
			window.clearTimeout(this.timeout);
			this.timeout = window.setTimeout("videouploader.parseSource()", this.TIMEOUT_INTERVAL);
		};

		this.selectSource = function () {
			this.source.select();
		};

		// Inits this class with the options provided
		this.init = function (source, drain, playerWrapper) {
			this.source = source;
			this.drain = drain;
			this.playerWrapper = $(playerWrapper);
			this.myTimeout();
			$(this.source).keyup(function () /*event*/{
				window.videouploader.myTimeout();
			});
			$(this.source).focus(function () /*event*/{
				this.select();
			});
		};
	}

	// Do not change the name of this instance!
	window.videouploader = new Videouploader();

	exports.default = window.videouploader;
});