define('hc2-ui/routes/backend/apps/edit', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        defaults: Ember.inject.service(),
        i18n: Ember.inject.service(),

        model: function model(params) {
            var route = this;
            var promise = this.store.findRecord('apps/' + params.app_type, params.app_id).then(function (app) {
                var defaultEditorData = {
                    editorResources: route.get('defaults').getAppEditorResources(params.app_type),
                    appData: app
                };
                if (!app.get("translations")) {
                    app.set("translations", {});
                }
                if (app.get("defaultLocale")) {
                    route.set("i18n.appLocale", app.get("defaultLocale"));
                }
                route.set("i18n.app", app);
                return defaultEditorData;
            });
            return promise;
        },
        afterModel: function afterModel(model) {
            if (!model.appData.get("editable")) {
                this.transitionTo("backend.apps.mine");
            }
            this.set("pageTitle", [model.appData.get("title"), model.appData.get("id"), this.get("i18n").t('editor.mode.edit')]);
        }
    });
});