define('hc2-ui/helpers/has-role-group', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    session: Ember.inject.service(),
    compute: function compute(params) {
      var roles = this.get('session.data.authenticated.userAuthorityInfo.authorities');
      for (var i = 0; i < roles.length; i++) {
        if (roles[i].startsWith("ROLE_" + params[0])) {
          return true;
        }
      }
      return false;
    }
  });
});