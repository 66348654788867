define('hc2-ui/components/controls/ck-editor', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['hc2-editor'],

    contentHeight: 250,
    editingDisabled: false,

    initEditor: false,

    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.later(function () {
        if (!_this.get("isDestroyed")) {
          _this.set("initEditor", true);
        }
      }, 50);
    },

    disabledOptions: {
      style: false,
      help: false
    }
  });
});