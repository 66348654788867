define("hc2-ui/templates/app/view", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 6
          }
        },
        "moduleName": "hc2-ui/templates/app/view.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createAttrMorph(element0, 'class');
        morphs[2] = dom.createMorphAt(element0, 1, 1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "apps/style-controls-css", [], ["appData", ["subexpr", "@mut", [["get", "model", ["loc", [null, [1, 34], [1, 39]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 0], [1, 41]]], 0, 0], ["attribute", "class", ["concat", ["app-view-wrapper wrapper-for-", ["subexpr", "camel-to-dash", [["get", "model.appType", ["loc", [null, [2, 57], [2, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 41], [2, 72]]], 0, 0], " orientation-", ["get", "device.deviceOrientation", ["loc", [null, [2, 87], [2, 111]]], 0, 0, 0, 0], " ", ["subexpr", "if", [["get", "model.portraitModeEnabled", ["loc", [null, [2, 119], [2, 144]]], 0, 0, 0, 0], "portrait-mode-enabled"], [], ["loc", [null, [2, 114], [2, 170]]], 0, 0], " ", ["subexpr", "if", [["get", "model.autoCenterModals", ["loc", [null, [2, 176], [2, 198]]], 0, 0, 0, 0], "auto-center-modals"], [], ["loc", [null, [2, 171], [2, 221]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "component", [["subexpr", "concat", ["apps/", ["subexpr", "camel-to-dash", [["get", "model.appType", ["loc", [null, [3, 43], [3, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 28], [3, 57]]], 0, 0], "/app-view"], [], ["loc", [null, [3, 12], [3, 70]]], 0, 0]], ["appData", ["subexpr", "@mut", [["get", "model", ["loc", [null, [3, 79], [3, 84]]], 0, 0, 0, 0]], [], [], 0, 0], "contentId", ["subexpr", "@mut", [["get", "contentId", ["loc", [null, [3, 95], [3, 104]]], 0, 0, 0, 0]], [], [], 0, 0], "door", ["subexpr", "@mut", [["get", "door", ["loc", [null, [3, 110], [3, 114]]], 0, 0, 0, 0]], [], [], 0, 0], "tab", ["subexpr", "@mut", [["get", "tab", ["loc", [null, [3, 119], [3, 122]]], 0, 0, 0, 0]], [], [], 0, 0], "step", ["subexpr", "@mut", [["get", "step", ["loc", [null, [3, 128], [3, 132]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [3, 0], [3, 134]]], 0, 0]],
      locals: [],
      templates: []
    };
  }());
});