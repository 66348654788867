define('hc2-ui/components/controls/css-quick-controls', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    styles: {
      'hideDoorNumbers': {
        selector: ".app-view .door-number",
        defaultValue: "display:none;"
      },
      'hideDoorContainer': {
        selector: ".app-view .calendar .door-container .door",
        defaultValue: "border-color:transparent;background:transparent"
      }
    },

    generatedStyles: Ember.computed("appData.customStyles", function () {
      var styles = this.get("appData.customStyles");
      if (styles) {
        var matches = styles.match(/\/\*generated-(\w*)\*\//g);
        if (matches) {
          var activeStyles = matches.map(function (v) {
            return v.substring(12, v.length - 2);
          });
          var obj = {};
          activeStyles.forEach(function (it) {
            obj[it] = true;
          });
          return obj;
        }
      }
      return {};
    }),

    actions: {
      toggleStyle: function toggleStyle(styleName, event) {
        this.setStyle(styleName, event.currentTarget.checked);
      }
    },
    setStyle: function setStyle(styleName, enabled) {
      var style = this.get("styles." + styleName);

      var customStyles = this.get("appData.customStyles") || "";

      // create style template
      var styleTemplate = "/*generated-" + styleName + "*/";
      var styleRegExp = new RegExp(("\n?" + styleTemplate).replace(/\./g, "\\.").replace(/\//g, "\\/").replace(/\*/g, "\\*") + ".*\}", "g");

      // remove style from customStyles if existent
      customStyles = customStyles.replace(styleRegExp, "");

      // generate new style
      if (enabled) {
        var newStyle = "\n" + styleTemplate + style.selector + " {" + style.defaultValue + "}";
        customStyles += newStyle;
      }
      this.set("appData.customStyles", customStyles);
    }
  });
});