define('hc2-ui/components/controls/rate-it', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    _control: null,
    'on-change': null,
    value: null,
    class: "rateit-range",

    valueChange: function () {
      var _this = this;

      Ember.run.later(function () {
        _this.get("on-change") && _this.get("on-change")(_this.get("value"));
      });
    }.observes('value'),

    didInsertElement: function didInsertElement() {
      var element = this.element.querySelector('.rate-it');
      this._control = window.$(element).rateit();
      var that = this;
      this._control.bind('rated', function (event, value) {
        that.set('value', value);
      });
    }
  });
});