define('hc2-ui/components/ml/property-translation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ml-app-translation'],
    i18n: Ember.inject.service(),

    selectedLocale: null,
    type: "textfield",
    label: null,
    property: null,

    init: function init() {
      this._super.apply(this, arguments);
    },


    _selectedLocaleSyncAppLocale: Ember.observer("i18n.appLocale", function () {
      this.set("selectedLocale", this.get("i18n.appLocale"));
    }),
    actions: {
      selectLocale: function selectLocale(locale) {
        var _this = this;

        var selectedLocale = this.get("selectedLocale") === locale ? null : locale;
        this.set("selectedLocale", null);
        Ember.run.later(function () {
          _this.set("selectedLocale", selectedLocale);
        }, 50);
      },
      setImageFromUpload: function setImageFromUpload(url) {
        var locale = this.get("selectedLocale") ? this.get("selectedLocale") : this.get("app.defaultLocaleParent");
        this.set("property." + locale, url);
      }
    }
  });
});