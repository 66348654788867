define("hc2-ui/services/games", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    availableGames: [{
      id: "1",
      name: "Christmas Run",
      url: "/assets/games/santa_girl_runner/gameplay/index.html",
      resolution: "1280x720"
    }, {
      id: "2",
      name: "Santa Bridge",
      url: "/assets/games/santa_stick/index.html",
      resolution: "720x1280"
    }, {
      id: "3",
      name: "Christmas Time Match",
      url: "/assets/games/christmas_time/index.html",
      resolution: "720x1320"
    }],
    getGameById: function getGameById(id) {
      return this.get("availableGames").findBy("id", id + "");
    }
  });
});