define('hc2-ui/routes/logout', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        session: Ember.inject.service('session'),

        beforeModel: function beforeModel() {
            if (this.get('session.isAuthenticated')) {
                this.get('session').invalidate();
            }
            this.transitionTo("index");
        }
    });
});