define("hc2-ui/components/apps/calendar/editor-form", ["exports", "hc2-ui/lib/make/init"], function (exports, _init) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    appData: {},
    editorResources: { availableBackgrounds: [] },
    appStates: {
      showWelcomePage: false
    },
    editableLabels: ["impressum", "sweepstake_participate", "close", "forward", "back", "christmasCalendar_toEarly", "christmasCalendar_toLate", "conditionsAgree_before", "conditions", "conditionsAgree_middle", "privacyPolicy", "conditionsAgree_after", "forward", "back", "yes", "no", "test_answer_commit", "test_toResult", "question_result_true", "question_result_false", "points_label", "salutation_male", "salutation_female", "salutation_diverse", "sweepstake_contact_firstName", "sweepstake_contact_lastName", "sweepstake_contact_name", "sweepstake_contact_email", "sweepstake_contact_salutation", "sweepstake_contact_phone", "sweepstake_contact_mobile", "sweepstake_contact_street", "sweepstake_contact_streetNr", "sweepstake_contact_zip", "sweepstake_contact_city", "sweepstake_contact_country", "sweepstake_contact_doiLabel", "mandetory_field_hint_checkbox", "mandetory_field_hint_text", "mandetory_field_hint_radio", "mandetory_field_hint_hidden", "mandetory_field", "participation_error_invalidEmail_sweepstake_participation", "constraint_unique_uniqueId_sweepstake_participation", "mandetory_field_patternHint_text"],

    calendar: Ember.computed.alias('appData'),
    availableBackgrounds: Ember.computed.alias('editorResources.availableBackgrounds'),

    observeWelcomePage: function () {
      this.switchWelcomePageIfActive(true);
    }.observes("appData.welcomePage"),
    switchWelcomePageIfActive: function switchWelcomePageIfActive(display) {
      this.set("appStates.showWelcomePage", this.get("appData.welcomePage") && display);
    },
    init: function init() {
      this._super();
      var url = this.get("calendar.backgroundImageUri");
      if (url) {
        this.get('availableBackgrounds').push({ id: url, text: url.substring(url.lastIndexOf('/') + 1) });
      }
    },


    didRender: function didRender() {
      _init.default.material(this.elementId);
    },

    save: "save",
    actions: {
      toggleWelcomePageIfActive: function toggleWelcomePageIfActive(display) {
        this.switchWelcomePageIfActive(display);
      },
      create: function create() {
        this.sendAction('save', this.get('calendar'));
      },
      setBackgroundFromUpload: function setBackgroundFromUpload(url) {
        if (!this.get('availableBackgrounds')) {
          this.set('availableBackgrounds', []);
        }
        this.get('availableBackgrounds').push({ id: url, text: url.substring(url.lastIndexOf('/') + 1) });
        this.set('calendar.backgroundImageUri', url);
      },
      setBackgroundPortraitFromUpload: function setBackgroundPortraitFromUpload(url) {
        if (!this.get('availableBackgrounds')) {
          this.set('availableBackgrounds', []);
        }
        this.get('availableBackgrounds').push({ id: url, text: url.substring(url.lastIndexOf('/') + 1) });
        this.set('calendar.backgroundImagePortraitUri', url);
      },
      setLogoFromUpload: function setLogoFromUpload(url) {
        this.set('calendar.logoUri', url);
      },
      handleError: function handleError(err) {
        alert("Error:\n" + err);
      }
    }
  });
});