define('hc2-ui/models/apps/code-promotion', ['exports', 'ember-data', 'hc2-ui/models/apps/app'], function (exports, _emberData, _app) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _app.default.extend({
    prizes: _emberData.default.attr(),
    contactData: _emberData.default.attr(),
    contactFormText: _emberData.default.attr('string'),
    headerImageUri: _emberData.default.attr("string"),
    thanksText: _emberData.default.attr("string"),
    participationHints: _emberData.default.attr("string"),
    hasDrawServerSetup: _emberData.default.attr("boolean"),
    contactFormOnCodeInput: _emberData.default.attr("boolean"),
    resultMails: _emberData.default.attr('boolean')
  });
});