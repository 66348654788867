define("hc2-ui/components/ml/label-editor", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    i18n: Ember.inject.service(),

    translations: null,
    labelKeys: null,
    locales: null,

    selectedLocale: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this.set("selectedLocale", this.get("i18n.appLocale"));

      if (!this.get('locales')) {
        this.set('locales', [{ value: this.get("i18n.appLocale") }]);
      }
    },
    didRender: function didRender() {
      //this.$('input').change();
    },

    actions: {
      selectLocale: function selectLocale(locale) {
        var _this = this;

        this.set("selectedLocale", locale);
        this.set("hideEditor", true);
        Ember.run.later(function () {
          _this.set("hideEditor", false);
        }, 50);
      }
    },

    _selectedLocaleSyncAppLocale: Ember.observer("i18n.appLocale", function () {
      this.set("selectedLocale", this.get("i18n.appLocale"));
    })

  });
});