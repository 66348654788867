define('hc2-ui/components/login-form', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),

        actions: {
            authenticateOAuth: function authenticateOAuth() {
                var _this = this;

                var data = this.getProperties('identification', 'password');
                this.get('session').authenticate('authenticator:hc2-restapi-oauth', data).catch(function (reason) {
                    _this.set('errorMessage', reason.error);
                });
            }
        },
        didInsertElement: function didInsertElement() {
            window.$.material.init();
            window.$('input[type=password]').focus();
            window.$('input[type=text]').click();
        }
    });
});