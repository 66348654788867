define("hc2-ui/components/ml/app-locale-switcher", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    i18n: Ember.inject.service(),

    locales: null,

    actions: {
      selectLocale: function selectLocale(locale) {
        this.set("i18n.appLocale", locale);
      }
    }

  });
});