define('hc2-ui/routes/backend/apps/customers', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

    api: Ember.inject.service(),
    i18n: Ember.inject.service(),
    afterModel: function afterModel() {
      this.set("pageTitle", this.get("i18n").t('apps.customers'));
    },
    model: function model(params) {
      var offset = params.page * params.size - params.size;
      var platform = this.modelFor("backend").platform;
      return this.get("api").request("/search/apps", { platformId: platform.id,
        max: params.size, sort: params.sort, order: params.order, offset: offset, search: params.search, appTypes: params.appTypes, customers: true
      });
    },

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('model', model.content);
      controller.set('totalElements', model.totalElements);
    },
    queryParams: {
      page: {
        refreshModel: true
      },
      size: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      },
      appTypes: {
        refreshModel: true
      }
    }
  });
});