define('hc2-ui/components/apps/email-placeholder-info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    placeholders: ['salutation', 'firstName', 'lastName', 'name', 'email', 'phone', 'mobile', 'street', 'streetNr', 'zip', 'city'],
    showAll: Ember.computed("appData.appType", function () {
      return this.get("appData.hasMailings");
    })
  });
});