define('hc2-ui/components/chart-js', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    type: 'horizontalBar',
    options: {
      legend: {
        display: false
      },
      animation: {
        duration: 5000
      },
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero: true,
            display: false
          },
          gridLines: {
            display: false
          }
        }],
        yAxes: [{
          ticks: {
            mirror: false
          }
        }]
      }
    }
  });
});