define("hc2-ui/helpers/nl-br", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.nlBr = nlBr;
  function nlBr(params /*, hash*/) {
    if (params[0] && typeof params[0] === "string") {
      return params[0].replace(/\n/g, "<br/>");
    }
    return params[0];
  }

  exports.default = Ember.Helper.helper(nlBr);
});