define('hc2-ui/components/controls/facebook-comments', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    initialized: false,

    app: null,
    participation: null,

    valueObserver: Ember.observer('app', function () /*sender, key, value, rev*/{
      this.initPlugin();
    }),

    didInsertElement: function didInsertElement() {
      this.initPlugin();
    },
    initPlugin: function initPlugin() {
      if (typeof window.FB !== 'undefined') {
        window.FB.init({
          appId: '519321034913139',
          status: true,
          xfbml: true,
          version: 'v2.4' // or v2.0, v2.1, v2.2, v2.3
        });
      }
    }
  });
});