define('hc2-ui/helpers/has-role', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    session: Ember.inject.service(),
    compute: function compute(params) {
      var roles = this.get('session.data.authenticated.userAuthorityInfo.authorities');
      if (roles && roles.indexOf('ROLE_' + params[0]) >= 0) {
        return true;
      }
      return false;
    }
  });
});