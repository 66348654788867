define('hc2-ui/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),

    config: Ember.inject.service(),
    embed: Ember.inject.service(),

    queryParams: ["orientation", "source"],
    source: null,

    // observing application wide data and write to session
    currentPathDidChange: function () {
      this.get('session').set('currentPath', this.get('currentPath'));
    }.observes('currentPath'),

    init: function init() {
      this._super.apply(this, arguments);
      this.adjustModalPosition();
      this.updateModalBackdrops();
      this.get("embed").initIframeResizerContentWindow();
    },
    adjustModalPosition: function adjustModalPosition() {
      // only modify dialog position if in iframe
      if (window.self !== window.top) {
        var that = this;
        document.onmousemove = function (e) {
          var cursorY = e.clientY;
          if (!window.$('.modal-dialog').is(':visible')) {
            var dialogY = cursorY > 200 ? cursorY - 200 : cursorY;
            that.set("dialogPositionY", dialogY);
          }
        };
      }
    },
    updateModalBackdrops: function updateModalBackdrops() {
      var _this = this;

      Ember.run.later(function () {
        try {
          $('.modal.in').modal('handleUpdate');
        } catch (e) {}
        _this.updateModalBackdrops();
      }, 1000);
    }
  });
});