define('hc2-ui/components/debug-infos', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),
        config: Ember.inject.service(),

        actions: {
            logout: function logout() {
                this.get('session').invalidate();
            }
        },

        debugMode: Ember.computed(function () {
            return this.get('config').setting('debugMode');
        })
    });
});