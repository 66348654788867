define('hc2-ui/routes/login', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    i18n: Ember.inject.service(),
    afterModel: function afterModel(model) {
      this.set("pageTitle", [this.get("i18n").t("login.header")]);
    },
    model: function model() {
      return this.modelFor("application");
    }
  });
});