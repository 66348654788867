define('hc2-ui/helpers/api-host', ['exports', 'hc2-ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.apiHost = apiHost;
  function apiHost(params /*, hash*/) {
    return _environment.default.oauth.host;
  }

  exports.default = Ember.Helper.helper(apiHost);
});