define('hc2-ui/components/password-forgot-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    api: Ember.inject.service(),
    actions: {
      sendPasswordToken: function sendPasswordToken() {
        var _this = this;

        var identification = this.get('identification');

        this.get("api").request("/user/sendPasswordToken", { identification: identification, location: window.location.href }).then(function (data) {
          _this.set("response", data);
        });
      }
    }
  });
});