define("hc2-ui/templates/components/ml/label-editor", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 4
              },
              "end": {
                "line": 11,
                "column": 4
              }
            },
            "moduleName": "hc2-ui/templates/components/ml/label-editor.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [1]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createAttrMorph(element1, 'src');
            morphs[2] = dom.createAttrMorph(element1, 'title');
            morphs[3] = dom.createAttrMorph(element1, 'alt');
            morphs[4] = dom.createAttrMorph(element1, 'onclick');
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["locale ", ["subexpr", "if", [["subexpr", "is-eq", [["get", "locale.value", ["loc", [null, [5, 38], [5, 50]]], 0, 0, 0, 0], ["get", "selectedLocale", ["loc", [null, [5, 51], [5, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 31], [5, 66]]], 0, 0], "selected"], [], ["loc", [null, [5, 26], [5, 79]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["subexpr", "flag", [["get", "locale.value", ["loc", [null, [6, 25], [6, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 18], [6, 39]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "title", ["concat", [["subexpr", "t", [["subexpr", "concat", ["locales.", ["get", "locale.value", ["loc", [null, [7, 43], [7, 55]]], 0, 0, 0, 0], ".label"], [], ["loc", [null, [7, 24], [7, 65]]], 0, 0]], [], ["loc", [null, [7, 20], [7, 67]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "alt", ["concat", [["subexpr", "t", [["subexpr", "concat", ["locales.", ["get", "locale.value", ["loc", [null, [8, 41], [8, 53]]], 0, 0, 0, 0], ".label"], [], ["loc", [null, [8, 22], [8, 63]]], 0, 0]], [], ["loc", [null, [8, 18], [8, 65]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["selectLocale", ["get", "locale.value", ["loc", [null, [9, 45], [9, 57]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [9, 60]]], 0, 0], 0, 0, 0, 0]],
          locals: ["locale"],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 6
            },
            "end": {
              "line": 12,
              "column": 6
            }
          },
          "moduleName": "hc2-ui/templates/components/ml/label-editor.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "locales", ["loc", [null, [4, 12], [4, 19]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 4], [11, 13]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 19,
                  "column": 14
                },
                "end": {
                  "line": 21,
                  "column": 14
                }
              },
              "moduleName": "hc2-ui/templates/components/ml/label-editor.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "defaultTranslation");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["inline", "tl", [["get", "selectedLocale", ["loc", [null, [20, 55], [20, 69]]], 0, 0, 0, 0], ["subexpr", "replace", [["get", "labelKey", ["loc", [null, [20, 79], [20, 87]]], 0, 0, 0, 0], "_", "."], [], ["loc", [null, [20, 70], [20, 96]]], 0, 0]], [], ["loc", [null, [20, 49], [20, 99]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 22,
                  "column": 14
                },
                "end": {
                  "line": 24,
                  "column": 14
                }
              },
              "moduleName": "hc2-ui/templates/components/ml/label-editor.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "controls/ck-editor", [], ["value", ["subexpr", "mut", [["subexpr", "get", [["get", "translations", ["loc", [null, [23, 53], [23, 65]]], 0, 0, 0, 0], ["subexpr", "concat", [["get", "selectedLocale", ["loc", [null, [23, 74], [23, 88]]], 0, 0, 0, 0], "_", ["get", "labelKey", ["loc", [null, [23, 93], [23, 101]]], 0, 0, 0, 0]], [], ["loc", [null, [23, 66], [23, 102]]], 0, 0]], [], ["loc", [null, [23, 48], [23, 103]]], 0, 0]], [], ["loc", [null, [23, 43], [23, 104]]], 0, 0]], ["loc", [null, [23, 16], [23, 106]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 14
              },
              "end": {
                "line": 25,
                "column": 14
              }
            },
            "moduleName": "hc2-ui/templates/components/ml/label-editor.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "ml-label", [["get", "labelKey", ["loc", [null, [18, 34], [18, 42]]], 0, 0, 0, 0], ["get", "customLabels", ["loc", [null, [18, 43], [18, 55]]], 0, 0, 0, 0], ["get", "translatedCustomLabels", ["loc", [null, [18, 56], [18, 78]]], 0, 0, 0, 0], ["get", "index", ["loc", [null, [18, 79], [18, 84]]], 0, 0, 0, 0]], [], ["loc", [null, [18, 23], [18, 86]]], 0, 0], ["block", "unless", [["get", "hideDefaultTranslation", ["loc", [null, [19, 24], [19, 46]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [19, 14], [21, 25]]]], ["block", "unless", [["get", "hideEditor", ["loc", [null, [22, 24], [22, 34]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [22, 14], [24, 25]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 29,
                  "column": 18
                },
                "end": {
                  "line": 31,
                  "column": 18
                }
              },
              "moduleName": "hc2-ui/templates/components/ml/label-editor.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "defaultTranslation");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["inline", "tl", [["get", "selectedLocale", ["loc", [null, [30, 56], [30, 70]]], 0, 0, 0, 0], ["subexpr", "replace", [["get", "labelKey", ["loc", [null, [30, 80], [30, 88]]], 0, 0, 0, 0], "_", "."], [], ["loc", [null, [30, 71], [30, 97]]], 0, 0]], [], ["loc", [null, [30, 51], [30, 99]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 14
              },
              "end": {
                "line": 32,
                "column": 14
              }
            },
            "moduleName": "hc2-ui/templates/components/ml/label-editor.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "input", [], ["value", ["subexpr", "mut", [["subexpr", "get", [["get", "translations", ["loc", [null, [26, 40], [26, 52]]], 0, 0, 0, 0], ["subexpr", "concat", [["get", "selectedLocale", ["loc", [null, [26, 61], [26, 75]]], 0, 0, 0, 0], "_", ["get", "labelKey", ["loc", [null, [26, 80], [26, 88]]], 0, 0, 0, 0]], [], ["loc", [null, [26, 53], [26, 89]]], 0, 0]], [], ["loc", [null, [26, 35], [26, 90]]], 0, 0]], [], ["loc", [null, [26, 30], [26, 91]]], 0, 0], "placeholder", ["subexpr", "ml-label", [["get", "labelKey", ["loc", [null, [27, 40], [27, 48]]], 0, 0, 0, 0], ["get", "customLabels", ["loc", [null, [27, 49], [27, 61]]], 0, 0, 0, 0], ["get", "translatedCustomLabels", ["loc", [null, [27, 62], [27, 84]]], 0, 0, 0, 0], ["get", "index", ["loc", [null, [27, 85], [27, 90]]], 0, 0, 0, 0]], [], ["loc", [null, [27, 30], [27, 91]]], 0, 0], "class", "form-control floating-label"], ["loc", [null, [26, 16], [28, 53]]], 0, 0], ["block", "unless", [["get", "hideDefaultTranslation", ["loc", [null, [29, 28], [29, 50]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [29, 18], [31, 29]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 4
            },
            "end": {
              "line": 35,
              "column": 4
            }
          },
          "moduleName": "hc2-ui/templates/components/ml/label-editor.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "form-group");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "m-t-10");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "contains", [["get", "editorKeys", ["loc", [null, [17, 30], [17, 40]]], 0, 0, 0, 0], ["get", "labelKey", ["loc", [null, [17, 41], [17, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 20], [17, 50]]], 0, 0]], [], 0, 1, ["loc", [null, [17, 14], [32, 21]]]]],
        locals: ["labelKey", "index"],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 36,
            "column": 6
          }
        },
        "moduleName": "hc2-ui/templates/components/ml/label-editor.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "ml-label-editor");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "locales");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(element2, 3, 3);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "is-gt", [["get", "locales.length", ["loc", [null, [3, 19], [3, 33]]], 0, 0, 0, 0], 1], [], ["loc", [null, [3, 12], [3, 36]]], 0, 0]], [], 0, null, ["loc", [null, [3, 6], [12, 13]]]], ["block", "each", [["get", "labelKeys", ["loc", [null, [14, 12], [14, 21]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [14, 4], [35, 13]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});