define("hc2-ui/controllers/login-user", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ["newUser"],
        newUser: false
    });
});