define('hc2-ui/helpers/add-id-to-objects', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Helper.helper(function (params) {
        var array = params[0];

        for (var i = 0; i < array.length; i++) {
            array[i].id = array[i][params[1]];
        }
        return array;
    });
});