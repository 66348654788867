define('hc2-ui/helpers/starts-with', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    if (!params[0]) {
      return false;
    }
    if (!params[0].substring) {
      return false;
    }
    var substr = params[0].substring(0, params[1].length);
    return substr === params[1];
  });
});