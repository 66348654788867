define('hc2-ui/models/view', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    app: _emberData.default.belongsTo('apps/app'),
    participation: _emberData.default.belongsTo('participation'),
    dateCreated: _emberData.default.attr('date')
  });
});