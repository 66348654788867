define('hc2-ui/components/controls/sort-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    sortValue: 'id',
    currentSortValue: null,
    sortDirection: null,
    tagName: "span",
    classNames: ['sort-button'],

    onSetSort: "onSetSort",

    actions: {
      setSort: function setSort() {
        this.sendAction("onSetSort", this.get("sortValue"));
      }
    }

  });
});