define("hc2-ui/components/apps/vote-button", ["exports", "hc2-ui/lib/make/init"], function (exports, _init) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    store: Ember.inject.service(),
    i18n: Ember.inject.service(),

    classes: null,

    participation: null,

    showRecaptcha: false,
    captchaResponse: null,
    email: null,
    ratedResetter: Ember.observer('participation', function () {
      this.set('rated', false);
    }),
    rated: false,

    votedPopUpInitialized: false,
    activatePopUpInitialized: false,

    vote: "vote",

    actions: {
      vote: function vote() /*participation*/{
        this.voteCycle();
      },
      captchaFulfilled: function captchaFulfilled(response) {
        this.set("captchaResponse", response);
        this.voteCycle();
      }
    },
    voteCycle: function voteCycle() {
      if (!this.get('rated')) {
        if (this.get('participation.app.votesNeedCaptcha') && !this.get('captchaResponse')) {
          this.set('showRecaptcha', true);
        } else if (this.get('participation.app.votesNeedEmailActivation')) {
          _init.default.material();
          this.showEmailForm();
          if (this.get("emailFormOpened") && this.$('.vote-email-popup-body form').parsley().validate() && this.get("conditionsAccepted")) {
            this.saveVote(this.get("captchaResponse"), this.get("email"));
          }
          if (this.get("emailFormOpened")) {
            this.set("showNoConditionsMessage", true);
          }
          this.set("emailFormOpened", true);
        } else {
          this.saveVote(this.get("captchaResponse"), this.get("email"));
        }
      }
    },
    showEmailForm: function showEmailForm() {
      window.$('#' + this.elementId + '-email-popup').modal();
    },
    saveVote: function saveVote(captchaResponse, email) {
      var that = this;
      this.get('store').createRecord("vote", {
        participation: this.get('participation'),
        reCaptchaResponse: captchaResponse,
        email: email,
        rating: 1,
        domain: window.location.hostname,
        locale: this.get("i18n.appLocale")
      }).save().then(function (vote) {
        if (vote.get('active')) {
          that.set('participation.rating', that.get('participation.rating') + vote.get('rating'));
          window.$('#' + that.elementId + '-voted-popup').modal();
          that.set('votedPopUpInitialized', true);
        } else {
          window.$('#' + that.elementId + '-activate-popup').modal();
          that.set('activatePopUpInitialized', true);
        }
      }, function (vote) {
        if (vote.errors) {
          if (vote.errors[0].detail === "participation.error.invalidEmail") {
            alert(that.get("i18n").ta("participation.error.invalidEmail.sweepstake.participation"));
            that.set('rated', false);
            that.set('captchaResponse', null);
            that.set('email', null);
            return;
          }
          if (vote.errors[0].field === 'email') {
            alert(that.get("i18n").ta("vote.email.valid.required"));
            that.set('rated', false);
          } else {
            alert(that.get("i18n").ta("vote.already.voted"));
          }
        } else {
          alert(that.get("i18n").ta("vote.error"));
        }
      });

      window.$('#' + this.elementId + '-email-popup').modal('hide');
      this.set('showRecaptcha', false);
      this.set('rated', true);
    }
  });
});