define("hc2-ui/components/chart-html-bars", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    bars: Ember.computed("chartData", function () {
      var rows = [];
      rows.maxValue = 0;
      var data = this.get("chartData");
      if (!data) {
        return rows;
      }
      data.datasets[0].data.forEach(function (value) {
        if (value > rows.maxValue) {
          rows.maxValue = value;
        }
      });
      data.labels.forEach(function (label, i) {
        var value = data.datasets[0].data[i];
        rows.push({
          label: label,
          value: value,
          percentOfMax: value * 100 / rows.maxValue,
          valueLabel: data.datasets[0].label
        });
      });
      return rows;
    }),

    didRender: function didRender() {
      this.set("rendered", true);
    }
  });
});