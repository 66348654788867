define("hc2-ui/components/apps/editor/custom-contact-form-fields", ["exports", "hc2-ui/lib/make/init", "hc2-ui/helpers/input-pattern"], function (exports, _init, _inputPattern) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    validationPattern: (0, _inputPattern.patternList)(),
    selectedExtraFromElement: 0,
    actions: {
      selectExtraFromElement: function selectExtraFromElement(index) {
        this.set("selectedExtraFromElement", index);
      },
      addExtraFromElement: function addExtraFromElement() {
        this.get('appData.contactData.extraFromElements').pushObject({ answers: [] });
        this.set("selectedExtraFromElement", this.get('appData.contactData.extraFromElements.length') - 1);
      },
      removeExtraFromElement: function removeExtraFromElement(questionIndex) {
        this.get('appData.contactData.extraFromElements').removeAt(questionIndex);
        this.set("selectedQuestion", 0);
      },
      addAnswer: function addAnswer(question) {
        question.answers.pushObject(Object.create({ label: "", correct: false }));
        this.get("appData").notifyPropertyChange("contactData");
      },
      removeAnswer: function removeAnswer(question, answerIndex) {
        question.answers.removeAt(answerIndex);
      },
      refreshContactData: function refreshContactData() {
        this.get("appData").notifyPropertyChange("contactData");
      }
    },
    didRender: function didRender() {
      _init.default.material(this.elementId);
    }
  });
});