define("hc2-ui/routes/password-change", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    api: Ember.inject.service(),

    model: function model(params) {
      return Ember.RSVP.hash({
        token: params.token,
        username: this.get("api").request("/user/getUserByToken/" + params.token)
      });
    }
  });
});