define('hc2-ui/adapters/user-authority-info', ['exports', 'hc2-ui/adapters/authenticatedRestAdapter'], function (exports, _authenticatedRestAdapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _authenticatedRestAdapter.default.extend({
        pathForType: function pathForType() {
            return 'user/authorities';
        }
    });
});