define('hc2-ui/helpers/ml-label', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    i18n: Ember.inject.service(),

    compute: function compute(params, interpolations) {
      var labelKey = params[0];
      var customLabels = params[1]; // list of labelKeys translated with 'Label' appended
      var indexedCustomLabels = params[2];
      var index = params[3];
      if (indexedCustomLabels && customLabels) {
        return customLabels[index];
      }
      var regex = new RegExp('_', "g");
      var labelKey_ = labelKey.replace(regex, '\.');

      if (customLabels && customLabels.contains(labelKey)) {
        return this.get('i18n').t(labelKey_ += "Label");
      } else {
        return this.get('i18n').t('labelFor', { labelKey: this.get('i18n').t(labelKey_) });
      }
    }
  });
});