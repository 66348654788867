define('hc2-ui/components/games/pixel-graphic-game', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['pixel-graphic-container'],

    duration: 0,
    gameRunning: Ember.computed("gameStarted", "gameEnded", "gamePaused", function () {
      return this.get("gameStarted") && !this.get("gameEnded") && !this.get("gamePaused");
    }),

    imageWidth: 100,
    imageHeight: 100,

    selectedImageIndex: 0,
    selectedImage: Ember.computed.alias("selectedImageObject.image"),
    selectedImageAnswers: Ember.computed("selectedImageObject.answers.@each", "gameRunning", function () {
      var answers = this.get("selectedImageObject.answers");
      if (!answers) {
        return [{ answer: { label: "No Answers", correct: false }, index: 0 }];
      }
      var answersMap = answers.map(function (a, i) {
        return {
          answer: a,
          index: i
        };
      });
      return this.shuffleArray(answersMap);
    }),
    selectedImageObject: Ember.computed("selectedImageIndex", function () {
      var imageIndex = this.get('selectedImageIndex') || 0;
      return this.get("pairs").objectAt(imageIndex);
    }),

    autoRevealDuration: false,
    autoReveal: Ember.computed.bool("autoRevealDuration"),

    pixelMatrixColumnCount: Ember.computed("pixelColumns", function () {
      var pixelColumns = this.get("pixelColumns") || 10;
      if (pixelColumns > 100) {
        pixelColumns = 100;
      }
      return pixelColumns;
    }),

    pixelSize: Ember.computed("pixelMatrixColumnCount", function () {
      // get pixel size in percent
      var columns = this.get("pixelMatrixColumnCount");
      return 100 / columns;
    }),
    pixelObjects: Ember.computed("pixelMatrixColumnCount", "pixelMatrixRowCount", function () {
      var columns = this.get("pixelMatrixColumnCount");
      var rows = this.get("pixelMatrixRowCount");
      var pixels = [];
      for (var row = 0; row < rows; row++) {
        for (var col = 0; col < columns; col++) {
          pixels.addObject(Ember.Object.create({
            row: row,
            col: col,
            _revealed: false
          }));
        }
      }
      return pixels;
    }),
    revealedPixel: Ember.computed("pixelMatrix.unrevealed.length", "pixelObjects.length", function () {
      return this.get("pixelObjects.length") - this.get("pixelMatrix.unrevealed.length");
    }),
    unrevealedPixel: Ember.computed.alias("pixelMatrix.unrevealed.length"),
    pixelMatrix: Ember.computed("pixelObjects", function () {
      var rows = this.get("pixelMatrixRowCount");
      var pixels = this.get("pixelObjects");
      var matrix = { rows: [], unrevealed: [] };
      for (var row = 0; row < rows; row++) {
        matrix.rows[row] = [];
      }
      pixels.forEach(function (pixel) {
        matrix.rows[pixel.get("row")][pixel.get("col")] = pixel;
        matrix.unrevealed.addObject(pixel);
      });
      return matrix;
    }),

    gameResults: Ember.computed("duration", function () {
      return {
        answerCorrect: this.get("selectedAnswer.answer.correct") == true,
        answer: this.get("selectedAnswer.answer.label"),
        answerIndex: this.get("selectedAnswer.index"),
        duration: this.get("duration"),
        revealed: this.get("revealedPixel")
      };
    }),
    resetGame: function () {
      if (!this.get("editMode")) {
        this.setRandomImage();
      }
      this.notifyPropertyChange("pixelObjects");
      this.set("duration", 0);
      this.set("gameStarted", false);
      this.set("selectedAnswer", undefined);
    }.on("init"),
    endGame: function endGame() {
      this.set("gameEnded", true);
      this.attrs.onDone && this.attrs.onDone(this.get("gameResults"));
    },
    revealPixel: function revealPixel(pixel) {
      pixel.set("_revealed", true);
      var unrevealed = this.get("pixelMatrix.unrevealed").removeObject(pixel);
      if (unrevealed.length == 0 && this.get("autoReveal") && this.get("editMode")) {
        this.set("duration", 0);
        this.notifyPropertyChange("pixelObjects");
      }
    },
    doAutoReveal: function doAutoReveal() {
      var _this = this;

      if (!this.get("isDestroyed") && this.get("gameRunning") && this.get("autoReveal")) {
        var pixelCount = this.get("pixelObjects.length");
        var unrevealedPixel = this.get("pixelMatrix.unrevealed");
        if (unrevealedPixel) {

          // check if there should be pixels unreleaved
          var gameDuration = 1;
          var durationTimer = this.get("duration") * 1000;
          if (this.get("gameStartedAt")) {
            gameDuration = new Date() - this.get("gameStartedAt");
          }
          if (durationTimer + 1000 < gameDuration) {
            // syncing gameStart with timer if
            this.set("gameStartedAt", new Date(new Date().getTime() - durationTimer));
            gameDuration = new Date() - this.get("gameStartedAt");
          }
          var autoRevealDuration = this.get("autoRevealDuration") * 1000;

          if (autoRevealDuration) {
            var shouldBeRevealed = pixelCount * gameDuration / autoRevealDuration;
            var revealedPixelCount = pixelCount - unrevealedPixel.length;
            var pixelToReveal = shouldBeRevealed - revealedPixelCount;

            for (var i = 0; i < pixelToReveal; i++) {
              var randomIndex = Math.floor(Math.random() * (unrevealedPixel.length - 1));
              var randomPixel = unrevealedPixel.objectAt(randomIndex);
              if (randomPixel) {
                this.revealPixel(randomPixel);
              }
            }
          }
        }
      }
      if (this.get("gameRunning")) {
        Ember.run.later(function () {
          _this.doAutoReveal();
        }, 100);
      }
    },


    pixelMatrixRowCount: Ember.computed("pixelMatrixColumnCount", "imageWidth", "imageHeight", function () {
      // calculate how many rows we need
      var width = this.get("imageWidth");
      var height = this.get("imageHeight");
      var pixelSizePercent = this.get("pixelSize");
      var pixelSizePx = width * pixelSizePercent / 100;
      var rows = height / pixelSizePx;
      return Math.floor(rows) || 1;
    }),

    actions: {
      calculateRows: function calculateRows(img) {
        var width = img.naturalWidth;
        var height = img.naturalHeight;

        this.set("imageWidth", width);
        this.set("imageHeight", height);
      },
      clickPixel: function clickPixel(pixel) {
        if (!this.get("autoReveal")) this.revealPixel(pixel);
      },
      startGame: function startGame() {
        if (!this.get("gameRunning")) {
          this.set("gameStarted", true);
          if (this.get("gamePaused")) {
            this.set("gamePaused", false);
          } else {
            this.set("gameStartedAt", new Date());
          }
          this.doAutoReveal();
        }
      },
      pauseGame: function pauseGame() {
        this.pauseGame();
      },
      resetGame: function resetGame() {
        this.resetGame();
      },
      solveGame: function solveGame(answer) {
        this.pauseGame();
        this.set("selectedAnswer", answer);
      },
      endGame: function endGame() {
        this.endGame();
      }
    },
    pauseGame: function pauseGame() {
      if (this.get("gameRunning")) {
        this.set("gamePaused", true);
      }
    },
    setRandomImage: function setRandomImage() {
      var randomIndex = Math.floor(Math.random() * this.get("pairs").length);
      this.set("selectedImageIndex", randomIndex);
    },
    shuffleArray: function shuffleArray(array) {
      var counter = array.length,
          temp,
          index;
      // While there are elements in the array
      while (counter > 0) {
        // Pick a random index
        index = Math.floor(Math.random() * counter);

        // Decrease counter by 1
        counter--;

        // And swap the last element with it
        temp = array[counter];
        array[counter] = array[index];
        array[index] = temp;
      }
      return array;
    }
  });
});