define('hc2-ui/components/summer-note', ['exports', 'ember-cli-summernote/components/summer-note', 'hc2-ui/lib/make/init'], function (exports, _summerNote, _init) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _summerNote.default.extend({
    config: Ember.inject.service(),
    session: Ember.inject.service(),
    i18n: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var _this = this;

      var _btnSize = this.get('btnSize');
      var _height = this.get('height');
      var _focus = this.get('focus');
      var _airMode = this.get('airMode');
      var _dialogsInBody = this.get('dialogsInBody');
      var _toolbar = this.getToolbarOptions(this.get('disabledOptions'));

      Ember.assert("summernote has to exist on Ember.$.fn.summernote", typeof Ember.$.fn.summernote === "function");
      Ember.assert("tooltip has to exist on Ember.$.fn.tooltip", typeof Ember.$.fn.tooltip === "function");

      var lang = "en-US";
      if (this.get("i18n.locale") === "de") {
        lang = "de-DE";
      }

      this.$('#summernote').summernote({
        styleWithCSS: true,
        height: _height,
        focus: _focus,
        toolbar: _toolbar,
        airMode: _airMode,
        dialogsInBody: _dialogsInBody,
        // airPopover: [
        //   ['color', ['color']],
        //   ['font', ['bold', 'underline', 'clear']],
        //   ['para', ['ul', 'paragraph']],
        //   ['table', ['table']],
        //   ['insert', ['link', 'picture']]
        // ]
        popover: {
          image: [['custom', ['imageAttributes']], ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']], ['float', ['floatLeft', 'floatRight', 'floatNone']], ['remove', ['removeMedia']]]
        },
        lang: lang,
        callbacks: {
          onImageUpload: function onImageUpload(image) {
            _this.uploadImage(image[0]);
          },
          onBlur: function onBlur() {
            _init.default.material();
          },
          onPaste: function onPaste(e) {
            var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
            if (bufferText) {
              bufferText = bufferText.replace(/\u000B/g, ''); // remove all tabs
            }
            e.preventDefault();
            if ((e.originalEvent || e).clipboardData) {
              document.execCommand('insertText', false, bufferText);
            } else if (window.clipboardData && window.getSelection) {
              // handling ie11
              window.getSelection().getRangeAt(0).insertNode(document.createTextNode(bufferText));
              window.getSelection().removeAllRanges();
            }
          }
        }
      });

      this.$().find('.note-editable').attr('contenteditable', !this.get('disabled'));
      this.$('.btn').addClass(_btnSize);

      var _content = this.get('content');
      this.$('#summernote').summernote('code', _content);
    },
    bindContent: function () {
      if (this.get("content") != this.$('#summernote').summernote('code')) {
        this.$('#summernote').summernote('code', this.get("content"));
      }
      if (/^(<p><br><\/p>$)/.test(this.get("content"))) {
        this.set("content", null);
      }
    }.observes("syncOnObjectChange", "content"),
    willDestroyElement: function willDestroyElement() {
      this.$('#summernote').summernote('destroy');
    },
    uploadImage: function uploadImage(image) {
      var _this2 = this;

      var data = new FormData();
      data.append("file", image);

      var appId = this.get("appId");
      if (!appId) {
        appId = this.get("i18n.app.id");
      }
      data.append("appId", appId);
      var uploadHost = this.get('config').getENV().oauth.host;
      var uploadUrl = uploadHost + '/upload/file';
      var accessToken = this.get('session.data.authenticated.access_token');

      window.$.ajax({
        data: data,
        type: "POST",
        url: uploadUrl + '?access_token=' + accessToken, // this file uploads the picture and
        // returns a chain containing the path
        cache: false,
        contentType: false,
        processData: false,
        success: function success(response) {
          var image = response.message;

          if (!window.$.summernote.options.modules.editor.prototype.insertImageWithCallback) {
            window.$.summernote.options.modules.editor.prototype.insertImageWithCallback = function (src, param, callback) {
              this.insertImage(src, param).then(function () {
                callback();
              });
            };
          }

          _this2.$('#summernote').summernote("insertImageWithCallback", image, function ($image) {
            $image.css('width', "100%");
          }, function () {
            _this2.doUpdate();
          });
        },
        error: function error(data) {
          console.log(data);
        }
      });
    },

    patchVideoDialog: function () {
      if (!window.$.summernote.options.modules.videoDialog.prototype.createVideoNodeOriginal) {
        window.$.summernote.options.modules.videoDialog.prototype.createVideoNodeOriginal = window.$.summernote.options.modules.videoDialog.prototype.createVideoNode;

        window.$.summernote.options.modules.videoDialog.prototype.createVideoNode = function (url) {
          var node = this.createVideoNodeOriginal(url);
          if (node.nodeName === "IFRAME") {
            node.setAttribute('allowfullscreen', '');
            node.setAttribute('webkitallowfullscreen', '');
            node.setAttribute('mozallowfullscreen', '');
          }
          return node;
        };
        window.$.summernote.options.modules.videoDialog.prototype.show = function () {
          var _this3 = this;

          try {
            var text = this.context.invoke('editor.getSelectedText');
          } catch (e) {}
          this.context.invoke('editor.saveRange');
          this.showVideoDialog().then(function (url) {
            // [workaround] hide dialog before restore range for IE range focus
            _this3.ui.hideDialog(_this3.$dialog);

            try {
              _this3.context.invoke('editor.restoreRange');
            } catch (e) {}

            // build node
            var $node = _this3.createVideoNode(url);

            if ($node) {
              // insert video node
              _this3.context.invoke('editor.insertNode', $node);
            }
          }).fail(function () {
            _this3.context.invoke('editor.restoreRange');
          });
        };
      }
    }.on("init")
  });
});