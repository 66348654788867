define("hc2-ui/models/app-mailing", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr("string"),
    active: _emberData.default.attr("boolean"),
    deliveryDateOption: _emberData.default.attr('string', { defaultValue: "beforeEveryAppDay" }),
    deliveryDate: _emberData.default.attr('date'),
    subject: _emberData.default.attr('object', { defaultValue: function defaultValue() {
        return {};
      } }),
    body: _emberData.default.attr('object', { defaultValue: function defaultValue() {
        return {};
      } }),
    dateCreated: _emberData.default.attr('date'),
    lastUpdated: _emberData.default.attr('date'),
    app: _emberData.default.belongsTo('apps/app', { polymorphic: true, async: true })
  });
});