define('hc2-ui/components/games/memory-game', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['memory-container'],

    duration: 0,

    cardsPerLine: Ember.computed("pairs.length", function () {
      return Math.floor(Math.sqrt(this.get("pairs.length") * 2));
    }),
    cardsWidth: Ember.computed("cardsPerLine", function () {
      return Math.floor(100 / this.get("cardsPerLine"));
    }),

    cards: Ember.computed("pairs.length", function () {
      // generate card objects from pairs
      var cards = [];
      var cardIndex = 0;
      this.get("pairs").forEach(function (pair, index) {
        cards.addObject(Ember.Object.create({
          image: pair.image,
          cardIndex: cardIndex++,
          pairIndex: index
        }));
        cards.addObject(Ember.Object.create({
          image: pair.image2 || pair.image,
          cardIndex: cardIndex++,
          pairIndex: index
        }));
      });
      cards = this.shuffleArray(cards);
      return cards;
    }),
    selectedCards: Ember.computed.filterBy("cards", "selected"),
    pairedCards: Ember.computed.filterBy("cards", "paired"),
    pairsFound: Ember.computed("pairedCards", function () {
      return this.get("pairedCards.length") / 2;
    }),
    allPairsFound: Ember.computed("pairsFound", function () {
      return this.get("pairsFound") === this.get("pairs.length");
    }),
    cardsMatrix: Ember.computed("cards", function () {

      var cards = this.get("cards");

      var cardsMatrix = [[]];
      var cardsPerLine = this.get("cardsPerLine");
      for (var i = 0; i < cards.length; i++) {
        if (cardsMatrix[cardsMatrix.length - 1].length >= cardsPerLine) {
          cardsMatrix.addObject([]);
        }
        cardsMatrix[cardsMatrix.length - 1].addObject(cards.objectAt(i));
      }
      return cardsMatrix;
    }),
    gameRunning: Ember.computed("gameStarted", "gameEnded", function () {
      return this.get("gameStarted") && !this.get("gameEnded");
    }),

    actions: {
      selectCard: function selectCard(card) {
        var _this = this;

        this.set("gameStarted", true);
        if (this.get("gameEnded")) {
          return false;
        }

        var selectedCards = this.get("selectedCards");
        if (selectedCards.get("length") === 2) {
          return; // only two cards can be selected at once
        }

        if (selectedCards.get("length")) {
          var firstCard = selectedCards.objectAt(0);

          if (firstCard.get("cardIndex") === card.get("cardIndex")) {
            return; // same card cannot be selected
          }

          if (card.get("pairIndex") === firstCard.get("pairIndex")) {
            firstCard.set("paired", true);
            card.set("paired", true);
          }
          Ember.run.later(function () {
            _this.unsetSelectedCards();
          }, 1000);
        }

        card.set("selected", true);
      }
    },
    unsetSelectedCards: function unsetSelectedCards() {
      this.get("selectedCards").forEach(function (card) {
        card.set("selected", false);
      });
      if (this.get("allPairsFound")) {
        this.set("gameEnded", true);
        this.attrs.onDone && this.attrs.onDone(this.get("duration"));
      }
    },
    shuffleArray: function shuffleArray(array) {
      var counter = array.length,
          temp,
          index;
      // While there are elements in the array
      while (counter > 0) {
        // Pick a random index
        index = Math.floor(Math.random() * counter);

        // Decrease counter by 1
        counter--;

        // And swap the last element with it
        temp = array[counter];
        array[counter] = array[index];
        array[index] = temp;
      }
      return array;
    }
  });
});