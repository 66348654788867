define('hc2-ui/adapters/app-statistic', ['exports', 'hc2-ui/adapters/authenticatedRestAdapter'], function (exports, _authenticatedRestAdapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _authenticatedRestAdapter.default.extend({
        pathForType: function pathForType() {
            return 'statistics/app';
        }
    });
});