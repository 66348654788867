define("hc2-ui/templates/components/controls/win-wheel", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 0
            },
            "end": {
              "line": 11,
              "column": 0
            }
          },
          "moduleName": "hc2-ui/templates/components/controls/win-wheel.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("button");
          var el2 = dom.createTextNode("Start");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          var el2 = dom.createTextNode("Stop");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0]);
          var element1 = dom.childAt(fragment, [2]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'onclick');
          morphs[1] = dom.createAttrMorph(element1, 'onclick');
          return morphs;
        },
        statements: [["attribute", "onclick", ["subexpr", "action", ["startWheel"], [], ["loc", [null, [null, null], [9, 39]]], 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["stopWheel"], [], ["loc", [null, [null, null], [10, 38]]], 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 7
          }
        },
        "moduleName": "hc2-ui/templates/components/controls/win-wheel.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "winWheelContainer");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "winWheelPointer");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("canvas");
        dom.setAttribute(el2, "id", "win-wheel");
        dom.setAttribute(el2, "class", "win-wheel");
        var el3 = dom.createTextNode("\n    Canvas not supported, use another browser.\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0, 3]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element2, 'width');
        morphs[1] = dom.createAttrMorph(element2, 'height');
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["attribute", "width", ["concat", [["get", "width", ["loc", [null, [3, 52], [3, 57]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "height", ["concat", [["get", "width", ["loc", [null, [3, 71], [3, 76]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "showButtons", ["loc", [null, [8, 6], [8, 17]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [8, 0], [11, 7]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});