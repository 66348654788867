define('hc2-ui/components/apps/welcome-page', ['exports', 'moment', 'hc2-ui/lib/make/init'], function (exports, _moment, _init) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    api: Ember.inject.service(),

    fromNow: {},
    init: function init() {
      this._super();
      this.setFromNow();
    },
    didRender: function didRender() {
      _init.default.material();
    },


    setFromNow: function setFromNow() {
      var self = this;

      var startDate = (0, _moment.default)(this.get('appData.startDate'));
      var stringVal = (0, _moment.default)(this.get('appData.startDate')).fromNow();
      var now = (0, _moment.default)();
      var started = now.isAfter(startDate);
      var days = startDate.diff(now, 'days');
      now = now.add(days, 'days'); // hours
      var hours = startDate.diff(now, 'hours');
      now = now.add(hours, 'hours');
      var minutes = startDate.diff(now, 'minutes');
      now = now.add(minutes, 'minutes');
      var seconds = startDate.diff(now, 'seconds');

      if (!this.get("isDestroyed")) {
        this.set('fromNow', {
          started: started,
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
          string: stringVal
        });

        Ember.run.later(function () {
          self.setFromNow();
        }, 1000);
      }
    },

    actions: {
      hideWelcomePage: function hideWelcomePage() {
        var _this = this;

        if (this.get("appData.accessCodeActive")) {
          this.get("api").post("/appCode/checkAccessCode", {
            "appId": this.get("appData.id"),
            "code": this.get("codeInput")
          }).then(function (response) {
            _this.sendAction('hideWelcomePage');
          }).fail(function (errorResponse) {
            // handle errors
            var status = errorResponse.status;

            if (status === 403) {
              _this.set("invalidCode", _this.get("codeInput"));
            }
          }).always(function () {
            _this.set("submitting", false);
          });
        } else {
          this.sendAction('hideWelcomePage');
        }
        return false;
      }
    }

  });
});