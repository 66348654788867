define("hc2-ui/components/apps/app-type-selector", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    config: Ember.inject.service(),
    session: Ember.inject.service(),
    i18n: Ember.inject.service(),

    selectedAppTypes: "",

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var selectedAppTypes = this.get("selectedAppTypes");
      this.set("selectedAppTypesArray", selectedAppTypes ? this.get("selectedAppTypes").split(",") : []);

      var modelNames = Ember.getOwner(this).lookup('data-adapter:main').getModelTypes().filter(function (type) {
        return type.name.startsWith("apps/");
      }).map(function (type) {
        return type.name.substring(5).camelize();
      }).filter(function (appType) {
        return !["app", "bodyChallenge"].includes(appType);
      }).sort();

      var roles = this.get('session.data.authenticated.userAuthorityInfo.authorities');
      if (roles && roles.indexOf('ROLE_ADMIN') == -1) {
        var availableAppsString = this.get("config").getPlatform().get("availableApps");
        modelNames = availableAppsString ? availableAppsString.split(',') : [];
      }

      var modelNameObjects = modelNames.map(function (m) {
        return {
          id: m,
          label: _this.get("i18n").t('app.' + m)
        };
      });

      this.set("appTypes", modelNameObjects.sortBy("label"));
    },


    actions: {
      didSelectAppTypes: function didSelectAppTypes(appTypes, event) {
        var _this2 = this;

        Ember.run.later(function () {
          _this2.set("selectedAppTypes", appTypes.join());
        }, 100);
      }
    }
  });
});