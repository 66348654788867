define('hc2-ui/components/controls/file-upload-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    config: Ember.inject.service(),
    session: Ember.inject.service(),
    extentionType: 'images',
    uploadTarget: null,

    uploadQueryString: Ember.computed("appId", "noResize", function () {
      var queryString = "";
      if (this.get("appId")) {
        queryString += "appId=" + this.get("appId");
      }
      if (this.get("noResize")) {
        queryString += queryString ? "&" : "";
        queryString += "noResize=true";
      }
      return queryString;
    }),
    appId: null,
    noResize: null,

    actions: {
      uploadFinished: function uploadFinished(url) {
        this.sendAction("uploadFinished", url, this.get("uploadTarget"));
      },
      uploadFailed: function uploadFailed(err) {
        this.sendAction("uploadFailed", err);
      }
    }

  });
});