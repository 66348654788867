define('hc2-ui/helpers/sub-url', ['exports', 'hc2-ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params, hash) {
    var hostPattern = _environment.default.hc2.hostPattern;
    var hostname = hostPattern.replace("{subdomain}", params ? params[0] : null);
    return hostname;
  });
});