define('hc2-ui/components/password-change-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    api: Ember.inject.service(),
    actions: {
      changePassword: function changePassword() {
        var _this = this;

        var token = this.get('token');
        var password = this.get("password");
        var passwordConfirm = this.get("passwordConfirm");

        if (password && passwordConfirm && password === passwordConfirm) {
          this.get("api").post("/user/changePassword/" + token, { password: this.get('password') }).then(function (data) {
            _this.set("response", data);
          });
        }
      }
    },
    passwordsDoNotMatch: Ember.computed("password", "passwordConfirm", function () {
      return this.get("passwordsOkChecked") || this.get("password") && this.get("passwordConfirm") && this.get("password") !== this.get("passwordConfirm");
    })
  });
});