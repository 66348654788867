define('hc2-ui/components/controls/add-to-any', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var elementId = this.elementId;
      var a2aElement = window.$('#' + elementId + ' .share-buttons').get(0);
      if (window.a2a !== undefined) {
        window.a2a.init('page', { target: a2aElement });
      }
    }
  });
});