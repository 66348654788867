define('hc2-ui/models/body-challenge-participation', ['exports', 'ember-data', 'hc2-ui/models/contest-participation'], function (exports, _emberData, _contestParticipation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _contestParticipation.default.extend({
    age: _emberData.default.attr("string"),
    hobbies: _emberData.default.attr("string"),
    participateQuestion: _emberData.default.attr("string"),
    winQuestion: _emberData.default.attr("string"),
    app: _emberData.default.belongsTo('apps/body-challenge')
  });
});