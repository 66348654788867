define("hc2-ui/components/apps/medium-view", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ["medium-view"],

    showOverlayImage: Ember.computed("preview", "appData.overlayImageUri", function () {
      return this.get("appData.overlayImageUri") && this.get("preview");
    })
  });
});