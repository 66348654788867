define("hc2-ui/components/controls/video-uploader", ["exports", "hc2-ui/lib/video/videouploader-tools"], function (exports, _videouploaderTools) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    value: null,

    actions: {
      vimeoUploadFinished: function vimeoUploadFinished(localFileUrl, vimeoUrl) {
        this.set("inputValue", vimeoUrl);
      }
    },

    didInsertElement: function didInsertElement() {
      _videouploaderTools.default.init('#usersImageUri', this, '#videoPlayerWrapper');
    }
  });
});