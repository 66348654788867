define("hc2-ui/components/apps/scratch-card/editor-form", ["exports", "hc2-ui/lib/make/init", "hc2-ui/helpers/has-html-content"], function (exports, _init, _hasHtmlContent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        appData: {},
        editorResources: {},

        appStates: {
            showWelcomePage: false
        },
        editableSubAppLabels: ["appdata_participate_nav_label", "scratchCard_rubFree", "scratchCard_participateToGoOn"],
        editableLabels: ["impressum", "overview", "sweepstake_participate", "appdata_participate_nav_label", "scratchCard_participateToGoOn", "scratchCard_rubFree", "winWheel_win", "close", "forward", "back", "yes", "no", "save", "salutation_male", "salutation_female", "salutation_diverse", "sweepstake_contact_firstName", "sweepstake_contact_lastName", "sweepstake_contact_name", "sweepstake_contact_email", "sweepstake_contact_salutation", "sweepstake_contact_phone", "sweepstake_contact_mobile", "sweepstake_contact_street", "sweepstake_contact_streetNr", "sweepstake_contact_zip", "sweepstake_contact_city", "sweepstake_contact_country", "sweepstake_contact_doiLabel", "conditionsAgree_before", "conditions", "conditionsAgree_middle", "privacyPolicy", "conditionsAgree_after", "mandetory_field_hint_checkbox", "mandetory_field_hint_text", "mandetory_field_hint_radio", "mandetory_field_hint_hidden", "mandetory_field", "constraint_unique_uniqueId_sweepstake_participation", "mandetory_field_patternHint_text"],

        selectedPrize: Ember.computed.alias("appStates.selectedPrize"),
        selectedExtraFromElement: 0,
        propabilities: Ember.computed("appData.prizes.@each.propability", function () {
            var prizes = this.get("appData.prizes");
            var propabilities = [];
            var totalPropabilities = 0;
            prizes.forEach(function (prize) {
                totalPropabilities += parseInt(prize.propability);
            });
            prizes.forEach(function (prize, i) {
                var propability = 100 / totalPropabilities * parseInt(prize.propability);
                propabilities[i] = propability.toFixed(1);
            });
            return propabilities;
        }),

        save: "save",
        actions: {
            save: function save() {
                this.sendAction('save', this.get('appData'));
            },
            setHeaderImageFromUpload: function setHeaderImageFromUpload(url) {
                this.set('appData.headerImageUri', url);
            },
            setScratchCardImageFromUpload: function setScratchCardImageFromUpload(url) {
                this.set('appData.scratchCardImageUrl', url);
            },
            setScratchCardBackgroundImageFromUpload: function setScratchCardBackgroundImageFromUpload(url) {
                this.set('appData.scratchCardBackgroundImageUrl', url);
            },
            setPrizeImageFromUpload: function setPrizeImageFromUpload(url, target) {
                Ember.set(target, 'image', url);
            },
            setWinWheelBackgroundImageFromUpload: function setWinWheelBackgroundImageFromUpload(url) {
                this.set('appData.winWheelBackgroundImageUrl', url);
            },
            handleError: function handleError(err) {
                alert("Error:\n" + err);
            },
            selectParticipationPage: function selectParticipationPage() {
                this.set("selectedPrize", this.get('appData.prizes.length'));
            },
            selectPrizesPage: function selectPrizesPage() {
                if (this.get('selectedPrize') === this.get('appData.prizes.length')) {
                    this.set("selectedPrize", 0);
                }
            },
            selectPrize: function selectPrize(index) {
                this.set("selectedPrize", index);
            },
            addPrize: function addPrize() {
                this.get('appData.prizes').pushObject({ text: "Preis " + (this.get('appData.prizes.length') + 1), propability: 1, maxCount: 0 });
                this.set("selectedPrize", this.get('appData.prizes.length') - 1);
            },
            removePrize: function removePrize(prizeIndex) {
                this.get('appData.prizes').removeAt(prizeIndex);
                this.set("selectedExtraFromElement", 0);
            },
            addAnswer: function addAnswer(prize) {
                prize.answers.pushObject({ label: "", correct: false });
            },
            removeAnswer: function removeAnswer(prize, answerIndex) {
                prize.answers.removeAt(answerIndex);
            },
            toggleWelcomePageIfActive: function toggleWelcomePageIfActive(display) {
                this.switchWelcomePageIfActive(display);
            }
        },

        observeWelcomePage: function () {
            this.switchWelcomePageIfActive(true);
        }.observes("appData.welcomePage"),
        switchWelcomePageIfActive: function switchWelcomePageIfActive(display) {
            this.set("appStates.showWelcomePage", this.get("appData.welcomePage") && display);
        },

        didRender: function didRender() {
            _init.default.material(this.elementId);
        },
        showHeaderContentEditor: Ember.computed("appData.headerContent", function () {
            return (0, _hasHtmlContent.hasHtmlContent)(this.get("appData.headerContent"));
        }),
        setupModel: function () {
            this.set("selectedPrize", 0);

            if (!this.get('appData.contactData')) {
                this.set('appData.contactData', {
                    salutationMandetory: true,
                    nameMandetory: true,
                    firstName: true,
                    firstNameMandetory: true,
                    lastName: true,
                    lastNameMandetory: true,
                    email: true,
                    emailMandetory: true,
                    extraFromElements: []
                });
            }
        }.on("init")

    });
});