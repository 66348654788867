define("hc2-ui/components/apps/move-to-user", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    config: Ember.inject.service(),
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),

    app: null,
    action: null,
    selectedUser: null,

    usersForSelection: Ember.computed("users", function () {
      var users = this.get("users");
      var selectableUsers = [];

      users.forEach(function (user) {
        user.set("label", user.get('username') + " @ " + user.get("platformName"));
        selectableUsers.pushObject(user);
      });
      return selectableUsers;
    }),

    actions: {
      moveAppToUser: function moveAppToUser() {
        var data = {
          user: { id: this.get('selectedUser') },
          appToCopy: this.get('app.id'),
          platform: this.get('config').setting('platform')
        };
        var action = this.get('store').createRecord("action", { action: "moveAppToUser", context: data });
        this.set("action", action);

        action.save();
      }
    }

  });
});