define('hc2-ui/routes/app/view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var RedirectAfterDidTransition = Ember.Mixin.create({
    redirectAfterDidTransition: function redirectAfterDidTransition() {
      var _this = this;

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this.router.one('didTransition', function () {
        var _router;

        (_router = _this.router).transitionTo.apply(_router, _toConsumableArray(args));
      });
    }
  });

  exports.default = Ember.Route.extend(RedirectAfterDidTransition, {
    i18n: Ember.inject.service(),
    config: Ember.inject.service(),
    tracking: Ember.inject.service(),
    queryParams: {
      locale: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var locale = params.locale || this.get("i18n").getUserLanguage();
      this.store.unloadAll('apps/' + params.app_type);
      return Ember.RSVP.hash({
        app: this.store.findRecord('apps/' + params.app_type, params.app_id + "_" + locale),
        locale: params.locale
      });
    },
    beforeModel: function beforeModel(transition) {
      var contentId = this.get('config').setting("contentid");
      if (contentId && !transition.queryParams.contentId) {
        this.redirectAfterDidTransition({ queryParams: { contentId: contentId } });
      }
    },

    afterModel: function afterModel(model) {
      var app = model.app;
      if (!app.get('active') && !app.get('parentApp.id')) {
        var message = this.get('config').platform.get('inactiveAppMessage');
        if (message) {
          message = message.replace(/\\n/g, "\n");
        }

        alert(message || this.get("i18n").t('app.not.paid'));
      }

      // setting up locales
      this.set("i18n.app", app);

      var appLocale = app.get("defaultLocale");

      // configure client Analytics Tracker
      this.set("tracking.clientGaTracker", app.get("gaTracker"));
      this.set("tracking.clientFacebookPixelId", app.get("facebookPixelId"));
      var optInNeeded = app.get("needsThirdPartyOptIn");
      this.set("tracking.optInNeeded", optInNeeded);

      var loadSocial = optInNeeded ? window["socialMediaOptedIn"] : true;
      var loadAddToAny = app.get('showSharingButtons') && loadSocial;
      var loadFacebookSDK = (app.get("fangateActive") || app.get("facebookComments")) && loadSocial;
      var loadTracking = app.get("noTracking") || this.get('config').platform.get('noTracking') ? false : true;

      if (!loadTracking && app.get("gaTracker")) {
        loadTracking = true;
        this.set("tracking.clientGaTracker", undefined);
        window.gaProperty = app.get("gaTracker");
      }

      this.get("tracking").initThirdPartyScripts(loadTracking, loadAddToAny, loadFacebookSDK, false);

      var userLocale = this.get("i18n").getUserLanguage();
      if (model.locale) {
        userLocale = model.locale;
      }
      // set user locale if available
      var availableLocales = app.get('locales');
      if (availableLocales.filterBy('value', userLocale).length > 0) {
        appLocale = userLocale;
      }

      // set the locales
      this.set("i18n.appLocale", appLocale);

      this.set("pageTitle", {
        title: this.get("i18n").tu('title', app.get("title")),
        replace: true,
        separator: " > "
      });
    },
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);
      controller.set('model', model.app);
      Ember.run.later(function () {
        controller.set('step', 1);
      });

      if (transition.queryParams.ad) {
        try {
          window.hc2_ad = JSON.parse(transition.queryParams.ad);
        } catch (e) {}
      }
    },

    reloadOnLocaleChange: function () {
      this.transitionTo({ queryParams: { locale: this.get("i18n.appLocale") } });
      this.set("locale", this.get("i18n.appLocale"));
    }.observes("i18n.appLocale")
  });
});