define('hc2-ui/components/apps/body-challenge/app-view', ['exports', 'hc2-ui/components/apps/contest/app-view'], function (exports, _appView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _appView.default.extend({
    layoutName: 'components/apps/contest/app-view',
    participationModel: 'body-challenge-participation',
    didInitAttrs: function didInitAttrs() {
      this._super();

      // as long as participations are running do not schow overview at all
      if (this.get('appData.participationEndDate') > new Date()) {
        // in participation timeframe hide overview and force participate as start page
        this.set('showOverview', false);
        if (this.get("appStates.selectedTab") === 'overview') {
          this.set('appStates.selectedTab', 'participate');
        }
      } else if (this.get('appData.votingStartDate') > new Date()) {
        // do not show overview as long voting has not started
        this.set('showOverview', false);
      } else if (this.get('appData.votingStartDate') < new Date()) {
        // if voting started force overview view
        this.set('appStates.selectedTab', 'overview');
      }
    }
  });
});