define("hc2-ui/models/user", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    username: _emberData.default.attr("string"),
    email: _emberData.default.attr("string"),
    phone: _emberData.default.attr("string"),
    password: _emberData.default.attr("string"),
    firstName: _emberData.default.attr("string"),
    lastName: _emberData.default.attr("string"),
    company: _emberData.default.attr("string"),
    platform: _emberData.default.belongsTo('platform'),
    platformName: _emberData.default.attr("string")
  });
});