define("hc2-ui/components/apps/poll/editor-form", ["exports", "hc2-ui/lib/make/init", "hc2-ui/helpers/has-html-content"], function (exports, _init, _hasHtmlContent) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    appData: {},
    editorResources: {},

    cssSettings: ['styleSettings.poll_answerCols', {
      type: 'radio',
      key: 'poll_answerCols_default',
      options: [{ label: '1', value: '1' }, { label: '2', value: undefined }, { label: '3', value: '3' }, { label: '4', value: '4' }]
    }, {
      type: 'radio',
      key: 'poll_answerCols_mobile',
      options: [{ label: 'styleSettings.unchanged', newline: true, value: undefined }, { label: '1', value: '1' }, { label: '2', value: '2' }, { label: '3', value: '3' }, { label: '4', value: '4' }]
    }, 'styleSettings.poll_results', 'color_poll_chartBar', 'color_poll_chartLabel'],

    appStates: {
      showWelcomePage: false
    },
    editableSubAppLabels: ["appdata_participate_nav_label"],
    editableLabels: ["impressum", "overview", "sweepstake_participate", "appdata_participate_nav_label", "test_toResult", "close", "forward", "back", "yes", "no", "test_answer_commit", "question_result_true", "question_result_false", "points_label", "salutation_male", "salutation_female", "salutation_diverse", "sweepstake_contact_firstName", "sweepstake_contact_lastName", "sweepstake_contact_name", "sweepstake_contact_email", "sweepstake_contact_salutation", "sweepstake_contact_phone", "sweepstake_contact_mobile", "sweepstake_contact_street", "sweepstake_contact_streetNr", "sweepstake_contact_zip", "sweepstake_contact_city", "sweepstake_contact_country", "sweepstake_contact_doiLabel", "conditionsAgree_before", "conditions", "conditionsAgree_middle", "privacyPolicy", "conditionsAgree_after", "mandetory_field_hint_checkbox", "mandetory_field_hint_text", "mandetory_field_hint_radio", "mandetory_field_hint_hidden", "mandetory_field", "participation_error_invalidEmail_sweepstake_participation", "constraint_unique_uniqueId_sweepstake_participation", "mandetory_field_patternHint_text"],

    selectedQuestion: Ember.computed.alias("appStates.selectedQuestion"),
    selectedResult: Ember.computed.alias("appStates.selectedResult"),
    selectedExtraFromElement: 0,

    save: "save",
    actions: {
      save: function save() {
        if (this.get('appData.noForm')) {
          this.set('appData.impressum', '-');
          this.set('appData.conditions', '-');
        }
        this.sendAction('save', this.get('appData'));
      },
      setHeaderImageFromUpload: function setHeaderImageFromUpload(url) {
        this.set('appData.headerImageUri', url);
      },
      setQuestionImageFromUpload: function setQuestionImageFromUpload(url, question) {
        Ember.set(question, 'headerImageUri', url);
      },
      handleError: function handleError(err) {
        alert("Error:\n" + err);
      },
      selectParticipationPage: function selectParticipationPage() {
        this.switchWelcomePageIfActive(false);
        this.set("selectedQuestion", this.get('appData.questions.length'));
      },
      selectQuestionsPage: function selectQuestionsPage() {
        this.switchWelcomePageIfActive(false);
        if (this.get('selectedQuestion') >= this.get('appData.questions.length')) {
          this.set("selectedQuestion", 0);
        }
      },
      selectQuestion: function selectQuestion(index) {
        this.set("selectedQuestion", index);
      },
      addQuestion: function addQuestion() {
        this.get('appData.questions').pushObject({ type: "radio", mandetory: true, answers: [] });
        this.set("selectedQuestion", this.get('appData.questions.length') - 1);
      },
      removeQuestion: function removeQuestion(questionIndex) {
        this.get('appData.questions').removeAt(questionIndex);
        this.set("selectedExtraFromElement", 0);
      },
      selectExtraFromElement: function selectExtraFromElement(index) {
        this.set("selectedExtraFromElement", index);
      },
      addExtraFromElement: function addExtraFromElement() {
        this.get('appData.contactData.extraFromElements').pushObject({ answers: [] });
        this.set("selectedExtraFromElement", this.get('appData.contactData.extraFromElements.length') - 1);
      },
      removeExtraFromElement: function removeExtraFromElement(questionIndex) {
        this.get('appData.contactData.extraFromElements').removeAt(questionIndex);
        this.set("selectedQuestion", 0);
      },
      addAnswer: function addAnswer(question) {
        question.answers.pushObject(Object.create({ label: "", correct: false }));
        this.get("appData").notifyPropertyChange("contactData");
      },
      setAnswerImageFromUpload: function setAnswerImageFromUpload(image, answer) {
        Ember.set(answer, "image", image);
      },
      refreshContactData: function refreshContactData() {
        this.get("appData").notifyPropertyChange("contactData");
      },

      removeAnswer: function removeAnswer(question, answerIndex) {
        question.answers.removeAt(answerIndex);
      },
      toggleWelcomePageIfActive: function toggleWelcomePageIfActive(display) {
        this.switchWelcomePageIfActive(display);
        return true;
      }
    },

    observeWelcomePage: function () {
      this.switchWelcomePageIfActive(true);
    }.observes("appData.welcomePage"),
    switchWelcomePageIfActive: function switchWelcomePageIfActive(display) {
      this.set("appStates.showWelcomePage", this.get("appData.welcomePage") && display);
    },

    didRender: function didRender() {
      _init.default.material(this.elementId);
    },
    showHeaderContentEditor: Ember.computed("appData.headerContent", function () {
      return (0, _hasHtmlContent.hasHtmlContent)(this.get("appData.headerContent"));
    }),
    setupModel: function () {
      this.set("selectedQuestion", 0);

      if (!this.get('appData.questions')) {
        this.set('appData.questions', Ember.A([]));
      }
      if (!this.get('appData.results')) {
        this.set('appData.results', Ember.A([]));
      }
      if (!this.get('appData.contactData')) {
        this.set('appData.contactData', {
          salutationMandetory: true,
          nameMandetory: true,
          firstName: true,
          firstNameMandetory: true,
          lastName: true,
          lastNameMandetory: true,
          email: true,
          emailMandetory: true,
          extraFromElements: []
        });
      }
    }.on("init")

  });
});