define("hc2-ui/components/apps/pack-your-bag/app-view", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    tracking: Ember.inject.service(),
    embed: Ember.inject.service(),

    appData: {},
    appStates: {
      showWelcomePage: false,
      selectedPage: 0
    },
    selectedPage: Ember.computed.alias("appStates.selectedPage"),
    showNextButton: Ember.computed.equal("selectedPage", 0),
    showParticipationForm: Ember.computed("selectedPage", "appData.noForm", function () {
      return this.get("selectedPage") === 1 && !this.get("appData.noForm");
    }),
    showThanks: Ember.computed.equal("selectedPage", 2),

    active: Ember.computed('appData.startDate', 'appData.endDate', function () {
      var now = new Date();
      return now > this.get('appData.startDate') && now < this.get('appData.endDate');
    }),

    editMode: false,
    didRender: function didRender() {
      this.get("tracking").initLinkTracking();

      if (this.get("editMode")) {
        this.initResizable('item-resizable');
        this.initResizable('drop-container');
        this.initDraggable('drop-container');
        window.$('.drop-container.ui-draggable').draggable('enable');
      } else {
        window.$('.drop-container.ui-draggable').draggable('disable');
      }
      this.initDraggable('item');
      this.initDroppable('drop-container', '.dragndrop-container .item');
    },
    init: function init() {
      this._super();
      this.set("appStates.showWelcomePage", this.get('appData.welcomePage') && !this.get('editMode'));

      this.setFromNow();

      if (!this.get('editMode') && this.get('appData.id') != null) {
        this.get('store').createRecord("view", { app: this.get('appData') }).save();
      }
    },

    setFromNow: function setFromNow() {
      var self = this;

      var startDate = (0, _moment.default)(this.get('appData.startDate'));
      var stringVal = (0, _moment.default)(this.get('appData.startDate')).fromNow();
      var now = (0, _moment.default)();
      var started = now.isAfter(startDate);
      var days = startDate.diff(now, 'days');
      now = now.add(days, 'days'); // hours
      var hours = startDate.diff(now, 'hours');
      now = now.add(hours, 'hours');
      var minutes = startDate.diff(now, 'minutes');
      now = now.add(minutes, 'minutes');
      var seconds = startDate.diff(now, 'seconds');
      if (!this.get("isDestroyed")) {
        this.set('fromNow', {
          started: started,
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
          string: stringVal
        });

        Ember.run.later(function () {
          self.setFromNow();
        }, 1000);
      }
    },
    actions: {
      dragContainerResized: function dragContainerResized() {
        this.calculateItemPositionVars();
        this.updateDroppedItemSizes();
        this.adjustToScreenSize();
      },

      hideWelcomePage: function hideWelcomePage() {
        this.get("embed").notifyStepChange();
        this.set("appStates.showWelcomePage", false);
      },
      gotoNext: function gotoNext() {
        var nextPage = this.get("appStates.selectedPage") + 1;
        this.set("appStates.selectedPage", nextPage);
        if (this.get("appData.noForm") && nextPage === 1) {
          this.send("submitForm");
        }
      },
      back: function back() {
        this.set("appStates.selectedPage", this.get("appStates.selectedPage") - 1);
      },
      reset: function reset() {
        this.set("appStates.selectedPage", 0);
        this.set('droppedItems', Ember.A());
      },

      submitForm: function submitForm() {
        var _this = this;

        if (this.isActiveTabValid() && !this.get("savingParticipation")) {
          if (!this.get('selectionDone')) {
            window.alert(this.get("i18n").t('packYourBag.selectionNotDoneError'));
            return;
          }
          this.set("savingParticipation", true);
          var o = {};
          var a = window.$('#' + this.get('elementId') + ' form').serializeArray();
          window.$.each(a, function () {
            if (o[this.name] !== undefined) {
              if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
              }
              o[this.name].push(this.value || '');
            } else {
              o[this.name] = this.value || '';
            }
          });

          o.locale = this.get("i18n.appLocale");
          o.source = this.get("session.source");
          if (window.hc2_ad && window.hc2_ad.form) Ember.$.extend(o, window.hc2_ad.form);

          this.get("droppedItemsIndexes").forEach(function (index) {
            if (o['answer-' + index] === undefined) {
              o['answer-' + index] = 1;
            } else {
              o['answer-' + index]++;
            }
          });

          var participation = this.get('store').createRecord("participation", {
            app: this.get('appData'), formData: o, domain: window.location.hostname
          });
          this.set('participation', participation);
          participation.save().then(function (participation) {
            _this.incrementProperty('selectedPage');
            _this.saveToLoacalStorage(o);
            _this.get("tracking").sendLeadEvent(_this.get("appData.title"));
          }).catch(function () {
            _this.set("savingParticipation", false);
          }).finally(function () {
            _this.set("savingParticipation", false);
          });
        }
      }
    },
    isActiveTabValid: function isActiveTabValid() {
      // check required fields on current page
      var requiredInputs = window.$('.participate-form input[required]');
      var requiredPatternInputs = window.$('.participate-form input[data-required-pattern]');
      var inputsToCheck = window.$.merge(requiredInputs, requiredPatternInputs);
      var allRequiredSet = true;
      for (var i = 0; i < inputsToCheck.length; i++) {
        var type = requiredInputs[i].type;
        var val = null;
        var field = window.$('.participate-form input[name="' + requiredInputs[i].name + '"]');
        if ('radio' === type || 'checkbox' === type) {
          val = window.$('.participate-form input[name="' + requiredInputs[i].name + '"]:checked').val();
        } else {
          val = field.val();
        }

        var required = field.prop("required");
        var requiredPattern = field.attr("data-required-pattern");

        var requiredValid = required ? val : true;
        var patternValid = true;
        if (requiredPattern && val) {
          patternValid = val.match(requiredPattern);
        }

        if (!requiredValid || !patternValid) {
          var fg = field.closest('.form-group');
          fg.addClass('has-error');
          allRequiredSet = false;
          var msgBox = fg.find('.mandetoryFieldMessage');
          if (msgBox.length === 0) {
            msgBox = window.$('<div class="mandetoryFieldMessage"><span class="text-danger"></span></div>');
            fg.append(msgBox);
          }
          var msg = !requiredValid ? this.get('i18n').ta('mandetory.field.hint.' + type) : this.get('i18n').ta('mandetory.field.patternHint.' + type);
          msgBox.find('span').text(msg);
          msgBox.show().delay(5000).fadeOut();
        } else {
          field.closest('.form-group').removeClass('has-error');
        }
      }
      var multiCheckboxes = window.$('.participate-form .multi-checkbox.mandetory-checkbox');
      if (multiCheckboxes.length == 1) {
        if (window.$('input:checked', multiCheckboxes).length == 0) {
          var fg = multiCheckboxes.closest('.form-group');
          fg.addClass('has-error');
          allRequiredSet = false;
          var msgBox = fg.find('.mandetoryFieldMessage');
          if (msgBox.length === 0) {
            msgBox = window.$('<div class="mandetoryFieldMessage"><span class="text-danger"></span></div>');
            fg.append(msgBox);
          }
          var _msg = this.get('i18n').ta('mandetory.field.hint.radio');
          msgBox.find('span').text(_msg);
          msgBox.show().delay(5000).fadeOut();
        }
      }
      return allRequiredSet;
    },
    calculateItemPositionVars: function calculateItemPositionVars() {

      var cssVars = "";
      var dragContainerWith = $('.drag-container').width();
      $(".drag-container .item").each(function (i, item) {
        var pos = $(item).position();
        var className = item.className.match("item-[0-9]+");
        cssVars += "--" + className + "-top:" + pos.top + "px;\n";
        cssVars += "--" + className + "-left:" + pos.left + "px;\n";
      });
      cssVars += "--dragContainer-width:" + dragContainerWith + "px;";

      this.set("itemCssVars", cssVars);
    },
    updateDroppedItemSizes: function updateDroppedItemSizes() {
      this.get("droppedItems").forEach(function (droppedItem) {
        var item = window.$('.drag-container .item.item-' + droppedItem.itemIndex);
        Ember.set(droppedItem, 'width', item.width() + 'px');
        Ember.set(droppedItem, 'height', item.height() + 'px');
      });
    },
    initResizable: function initResizable(className) {
      var _this2 = this;

      window.$('.dragndrop-container .' + className).resizable({
        stop: function stop(event) {
          var itemClass = event.target.className.match(new RegExp(className + '-\\d*'))[0];
          var itemClassPath = ".packYourBag-view .dragndrop-container";
          if (_this2.get("appData.portraitModeEnabled") && _this2.get("editOrientation") === 'portrait') {
            itemClassPath = ".orientation-" + _this2.get("editOrientation") + ".portrait-mode-enabled " + itemClassPath;
          }
          var item = window.$(itemClassPath + " ." + itemClass)[0];
          var itemWidth = window.$(item).width();
          var itemHeight = window.$(item).height();
          var itemContainer = window.$(itemClassPath);
          var itemContainerWidth = itemContainer.width();
          var itemContainerHeight = itemContainer.height();

          var itemWidthPercent = Math.round(itemWidth * 100 / itemContainerWidth * 100) / 100;
          var itemHeightPercent = Math.round(itemHeight * 100 / itemContainerHeight * 100) / 100;

          var customStyles = _this2.get("appData.customStyles") || "";

          var styleTemplate = "\n/*generated-size*/" + itemClassPath + " ." + className + "." + itemClass;

          // add item style
          var styleRegExp = new RegExp(styleTemplate.replace(/\./g, "\\.").replace(/\//g, "\\/").replace(/\*/g, "\\*") + " \{.*\}", "g");
          customStyles = customStyles.replace(styleRegExp, "");
          var newItemStyle = styleTemplate + " { width:" + itemWidthPercent + "%; height:" + itemHeightPercent + "%;}";
          customStyles += newItemStyle;

          // adjust item setting
          Ember.run.later(function () {
            window.$(item).css("width", "");
            window.$(item).css("height", "");
          });

          _this2.set("appData.customStyles", customStyles);
        }
      });
    },
    initDraggable: function initDraggable(className) {
      var _this3 = this;

      window.$('.dragndrop-container .' + className).draggable({
        containment: ".dragndrop-container",
        cursor: "move",
        start: function start(event, ui) {
          if (window.$(event.target).hasClass('item')) {
            // set current item label
            var itemIndexClass = event.target.className.match(new RegExp("item" + '-\\d*'))[0];
            var itemIndex = parseInt(itemIndexClass.split('-')[1]);
            _this3.set("currentItem", _this3.get("appData.questions." + itemIndex));
            _this3.set("currentItemIndex", itemIndex);
          }

          var revert = !_this3.get('editMode');
          if (window.$(event.target).hasClass('dropped')) {
            revert = _this3.get("appData.autoArrange");
          }
          $(event.target).draggable("option", "revert", revert);
        },
        stop: function stop(event, ui) {
          _this3.set("currentItem", null);
          _this3.set("currentItemIndex", null);

          var draggable = window.$(event.target);

          if (draggable.hasClass('dropped')) {
            // dropped items get checked for removal
            if (draggable.data("removeFromDroppedItems")) {
              var droppedIndexClass = draggable[0].className.match(new RegExp("dropped" + '-\\d*'))[0];
              var droppedIndex = parseInt(droppedIndexClass.split('-')[1]);
              _this3.get("droppedItems").removeAt(droppedIndex);
            }
          } else if (_this3.get('editMode')) {
            // regular items get position styles updated in edit mode
            var itemClass = event.target.className.match(new RegExp(className + '-\\d*'))[0];
            var itemClassPath = ".packYourBag-view .dragndrop-container";
            if (_this3.get("appData.portraitModeEnabled") && _this3.get("editOrientation") === 'portrait') {
              itemClassPath = ".orientation-" + _this3.get("editOrientation") + ".portrait-mode-enabled " + itemClassPath;
            }
            var item = draggable[0];
            var itemTop = item.offsetTop;
            var itemLeft = item.offsetLeft;
            var dragContainer = window.$(".packYourBag-view .drag-container");
            var dragContainerWidth = dragContainer.width();
            var dragContainerHeight = dragContainer.height();

            var dragLeftPercent = Math.round(itemLeft * 100 / dragContainerWidth * 100) / 100;
            var dragTopPercent = Math.round(itemTop * 100 / dragContainerHeight * 100) / 100;

            var customStyles = _this3.get("appData.customStyles") || "";

            var styleTemplate = "\n/*generated*/" + itemClassPath + " ." + className + "." + itemClass;

            // add item style
            var styleRegExp = new RegExp((styleTemplate + "." + itemClass).replace(/\./g, "\\.").replace(/\//g, "\\/").replace(/\*/g, "\\*") + " \{.*\}", "g");
            customStyles = customStyles.replace(styleRegExp, "");
            var newItemStyle = styleTemplate + "." + itemClass + " { left:" + dragLeftPercent + "%; top:" + dragTopPercent + "%; position:absolute; margin:0;}";
            customStyles += newItemStyle;

            // adjust item setting
            Ember.run.later(function () {
              window.$(item).css("top", "");
              window.$(item).css("left", "");
            });

            _this3.set("appData.customStyles", customStyles);
          }
        }
      });
    },
    initDroppable: function initDroppable(droppableClassName, acceptSelector) {
      var _this4 = this;

      var droppable = window.$('.' + droppableClassName).droppable({
        accept: acceptSelector,
        activeClass: droppableClassName + "-active",
        classes: {
          "ui-droppable-active": "ui-state-highlight"
        },
        drop: function drop(event, ui) {
          if (ui.draggable.hasClass("dropped")) {
            // we do not want to duplicate already dropped items
            return;
          }

          if (_this4.get("editMode")) {
            // we want original items in edit mode be pushed to droppedItems
            // when moved on drop container but revert the original so position
            // stays the same
            ui.draggable.draggable("option", "revert", true);
          }

          if (_this4.get('appData.maxItems') && _this4.get('appData.maxItems') <= _this4.get("droppedItems.length")) {
            // do not drop if maxItems is reached
            return;
          }

          var lastSelectorClass = acceptSelector.split(' ')[acceptSelector.split(' ').length - 1];
          var itemClass = ui.draggable[0].className.match(new RegExp(lastSelectorClass + '-\\d*'))[0];
          var itemIndex = parseInt(itemClass.split('-')[itemClass.split('-').length - 1]);

          if (!_this4.get('appData.multiSelect') && _this4.get('droppedItemsIndexes').includes(itemIndex)) {
            // do not drop if selected and multiSelect is disabled
            return;
          }

          var leftOfDropContainer = ui.draggable.position().left - window.$(event.target).position().left + parseInt(ui.draggable.css('marginLeft'));
          var topOfDropContainer = ui.draggable.position().top - window.$(event.target).position().top + parseInt(ui.draggable.css('marginTop'));
          var leftOfDropContainerPercent = 100 * leftOfDropContainer / event.target.offsetWidth;
          var topOfDropContainerPercent = 100 * topOfDropContainer / event.target.offsetHeight;

          // put it to droppedItems
          _this4.get("droppedItems").pushObject({
            itemIndex: itemIndex,
            item: _this4.get("appData.questions." + itemIndex),
            width: ui.draggable.width() + 'px',
            height: ui.draggable.height() + 'px',
            left: leftOfDropContainerPercent + '%',
            top: topOfDropContainerPercent + '%'
          });
        },
        out: function out(event, ui) {
          // mark dropped items for deletion on drag stop
          if (ui.draggable.hasClass('dropped')) {
            ui.draggable.draggable("option", "revert", false);
            ui.draggable.data("removeFromDroppedItems", true);
          }
        },
        over: function over(event, ui) {
          // unmark dropped items for deletion on drag stop
          if (ui.draggable.hasClass('dropped')) {
            ui.draggable.draggable("option", "revert", _this4.get("appData.autoArrange"));
            ui.draggable.data("removeFromDroppedItems", false);
          }
        }
      });
    },

    droppedItems: Ember.A(),
    droppedItemsIndexes: Ember.computed('droppedItems.[]', function () {
      return this.get('droppedItems').mapBy('itemIndex');
    }),
    currentItem: null,
    selectionDone: Ember.computed('appData.minItems', 'appData.maxItems', 'droppedItems.length', function () {
      return this.get('appData.minItems') <= this.get("droppedItems.length");
    }),
    adjustToScreenSize: function adjustToScreenSize() {
      if (this.get("appData.parentApp.id") || this.get("isDestroyed")) {
        return;
      }
      if (this.get("embed.isEmbedded")) {
        return;
      }
      var appView = Ember.$(".app-view");
      var windowHeight = window.hc2ParentWindowHeight || Ember.$(window).height();

      var calendar = Ember.$(".dragndrop-container");

      if (calendar.size()) {
        var offsetTop = calendar.offset().top;
        offsetTop -= Ember.$(".app-size-wrapper").offset().top;
        var availableHeight = windowHeight - offsetTop;

        var calendarBackgroundImage = $('.backgroundImage', calendar);
        var imgHeight = calendarBackgroundImage[0].naturalHeight;
        var imgWidth = calendarBackgroundImage[0].naturalWidth;
        var appViewWidth = appView.width();

        if (imgHeight) {

          var percentToAvailableHeight = 100 / imgHeight * availableHeight;
          var percentToAvailableWidth = 100 / imgWidth * appViewWidth;

          var fullSizeWidth = imgWidth * availableHeight / imgHeight;
          var maxWidth = fullSizeWidth > appViewWidth ? appViewWidth : fullSizeWidth;
          var maxWidthStyle = fullSizeWidth > appViewWidth ? "100%" : fullSizeWidth + "px";
          Ember.$(".app-size-wrapper").width(maxWidthStyle);
          this.set("viewSize", maxWidth < 768 ? 'xs' : '');
        }
      }
    },
    addResizeHandler: function () {
      var _this5 = this;

      if (!this.get("inEditor")) {
        window.$(window).on("resize", function () {
          return _this5.adjustToScreenSize();
        });
      }
    }.on("init")
  });
});