define('hc2-ui/components/ml/backend-locale-switcher', ['exports', 'hc2-ui/lib/make/init'], function (exports, _init) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['backend-locale-switcher'],

    i18n: Ember.inject.service(),

    locales: Ember.computed.alias("i18n.locales"),

    didRender: function didRender() {
      _init.default.material();
    },


    actions: {
      selectLocale: function selectLocale(locale) {
        this.set("i18n.locale", locale);
      }
    }

  });
});