define('hc2-ui/helpers/fb-app-id', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    switch (params[0]) {
      case 'christmasCalendar':
        return '344460659441084';
      case 'sweepstake':
        return '263640034499263';
      case 'contest':
        return '326699918142985';
      case 'bodyChallenge':
        return '528378560677584';
      case 'calendar':
        return '742198416146089';
      case 'test':
        return '515702752248653';
      case 'quiz':
        return '642708906125176';
    }
  });
});