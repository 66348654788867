define("hc2-ui/templates/components/games/memory-game", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 32
                },
                "end": {
                  "line": 14,
                  "column": 32
                }
              },
              "moduleName": "hc2-ui/templates/components/games/memory-game.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "card-id");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["inline", "add-val", [["get", "card.pairIndex", ["loc", [null, [13, 63], [13, 77]]], 0, 0, 0, 0], 1], [], ["loc", [null, [13, 53], [13, 81]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 15,
                  "column": 32
                },
                "end": {
                  "line": 17,
                  "column": 32
                }
              },
              "moduleName": "hc2-ui/templates/components/games/memory-game.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element1, 'src');
              morphs[1] = dom.createAttrMorph(element1, 'alt');
              return morphs;
            },
            statements: [["attribute", "src", ["concat", [["get", "backgroundImage", ["loc", [null, [16, 48], [16, 63]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "alt", ["concat", [["get", "card.pairIndex", ["loc", [null, [16, 74], [16, 88]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child2 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 20,
                  "column": 32
                },
                "end": {
                  "line": 22,
                  "column": 32
                }
              },
              "moduleName": "hc2-ui/templates/components/games/memory-game.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element0, 'src');
              morphs[1] = dom.createAttrMorph(element0, 'alt');
              return morphs;
            },
            statements: [["attribute", "src", ["concat", [["get", "card.image", ["loc", [null, [21, 44], [21, 54]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "alt", ["concat", [["get", "card.pairIndex", ["loc", [null, [21, 65], [21, 79]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child3 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 22,
                  "column": 32
                },
                "end": {
                  "line": 24,
                  "column": 32
                }
              },
              "moduleName": "hc2-ui/templates/components/games/memory-game.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "card-text");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "card.pairIndex", ["loc", [null, [23, 55], [23, 73]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 8
              },
              "end": {
                "line": 30,
                "column": 8
              }
            },
            "moduleName": "hc2-ui/templates/components/games/memory-game.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "card-wrapper");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n                        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "flip-card-inner");
            var el5 = dom.createTextNode("\n                            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5, "class", "card flip-card-front");
            var el6 = dom.createTextNode("\n");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("                            ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5, "class", "card flip-card-back");
            var el6 = dom.createTextNode("\n");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("                            ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                        ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var element3 = dom.childAt(element2, [1, 1]);
            var element4 = dom.childAt(element3, [1]);
            var element5 = dom.childAt(element4, [1]);
            var morphs = new Array(7);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createAttrMorph(element2, 'style');
            morphs[2] = dom.createAttrMorph(element2, 'onclick');
            morphs[3] = dom.createAttrMorph(element3, 'class');
            morphs[4] = dom.createMorphAt(element5, 1, 1);
            morphs[5] = dom.createMorphAt(element5, 2, 2);
            morphs[6] = dom.createMorphAt(dom.childAt(element4, [3]), 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["card-container ", ["subexpr", "if", [["get", "card.selected", ["loc", [null, [7, 44], [7, 57]]], 0, 0, 0, 0], "selected"], [], ["loc", [null, [7, 39], [7, 70]]], 0, 0], " ", ["subexpr", "if", [["get", "card.paired", ["loc", [null, [7, 76], [7, 87]]], 0, 0, 0, 0], "paired"], [], ["loc", [null, [7, 71], [7, 98]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "style", ["concat", ["width:", ["get", "cardsWidth", ["loc", [null, [7, 115], [7, 125]]], 0, 0, 0, 0], "%"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["selectCard", ["get", "card", ["loc", [null, [7, 160], [7, 164]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [7, 166]]], 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["flip-card ", ["subexpr", "if", [["subexpr", "is-or", [["get", "card.selected", ["loc", [null, [9, 54], [9, 67]]], 0, 0, 0, 0], ["get", "card.paired", ["loc", [null, [9, 68], [9, 79]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 47], [9, 80]]], 0, 0], "flipped"], [], ["loc", [null, [9, 42], [9, 92]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "editMode", ["loc", [null, [12, 38], [12, 46]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [12, 32], [14, 39]]]], ["block", "if", [["get", "backgroundImage", ["loc", [null, [15, 38], [15, 53]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [15, 32], [17, 39]]]], ["block", "if", [["get", "card.image", ["loc", [null, [20, 38], [20, 48]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [20, 32], [24, 39]]]]],
          locals: ["card"],
          templates: [child0, child1, child2, child3]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 4
            },
            "end": {
              "line": 32,
              "column": 4
            }
          },
          "moduleName": "hc2-ui/templates/components/games/memory-game.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "row", ["loc", [null, [6, 16], [6, 19]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [6, 8], [30, 17]]]]],
        locals: ["row"],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 33,
            "column": 6
          }
        },
        "moduleName": "hc2-ui/templates/components/games/memory-game.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "memory-board");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "apps/duration-timer", [], ["duration", ["subexpr", "@mut", [["get", "duration", ["loc", [null, [1, 31], [1, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "active", ["subexpr", "@mut", [["get", "gameRunning", ["loc", [null, [1, 47], [1, 58]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 0], [1, 60]]], 0, 0], ["block", "each", [["get", "cardsMatrix", ["loc", [null, [4, 12], [4, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 4], [32, 13]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});