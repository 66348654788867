define("hc2-ui/components/apps/integrations/emarsys-field-mapping", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    api: Ember.inject.service(),
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get("api").request("/emarsys/fields", { locale: this.get("i18n.locale") }).then(function (fields) {
        // only use fields that are of type text or choice
        var filteredFields = fields.filter(function (item, index, enumerable) {
          return ['singlechoice', 'multichoice', 'shorttext', 'longtext', 'largetext'].includes(item.application_type);
        });
        _this.set("emarsysFields", filteredFields.map(function (i) {
          return {
            id: i.id,
            name: i.name,
            description: "[" + i.id + "] " + i.application_type,
            fieldType: i.application_type
          };
        }));
      });
    },

    emarsysFields: [],

    appIntegrations: Ember.computed.alias("appData.appIntegrations"),
    mappings: Ember.computed.alias("appIntegrations.emarsys.mappings"),

    actions: {
      saveMapping: function saveMapping() {
        var _this2 = this;

        this.get("store").peekRecord("appIntegration", this.get("appIntegrations.id")).save().then(function () {
          window.alert(_this2.get("i18n").t("emarsys.mappingSavedSuccessfully"));
        });
      },
      addMapping: function addMapping() {
        if (!this.get("appIntegrations.emarsys.mappings")) {
          this.set("appIntegrations.emarsys.mappings", []);
        }
        this.get("appIntegrations.emarsys.mappings").pushObject({
          appField: null,
          emarsysField: null,
          emarsysValue: null
        });
      }
    },

    appFields: Ember.computed("appData.contactData.extraFromElements", "mappings.[]", "appData.questions", function () {
      var _this3 = this;

      var fields = [{
        id: "i",
        label: this.get("i18n").t("emarsys.fieldIndependent"),
        description: this.get("i18n").t("emarsys.fieldIndependentDescription")
      }];

      if (this.get("appData.questions")) {
        this.get("appData.questions").forEach(function (e, i) {
          if (e.type !== "freetext") {
            fields.addObject({
              id: "q" + i,
              label: _this3.get("i18n").t("poll.questions") + " " + (i + 1) + ": " + e.label,
              description: _this3.get("i18n").t("question.type." + e.type)
            });
          }
        });
      }

      if (this.get("appData.contactData.extraFromElements")) {
        this.get("appData.contactData.extraFromElements").forEach(function (e, i) {
          if (e.type !== "freetext") {
            fields.addObject({
              id: "c" + i,
              label: _this3.get("i18n").t("sweepstake.contact.form") + " " + (i + 1) + ": " + e.label,
              description: _this3.get("i18n").t("question.type." + e.type)
            });
          }
        });
      }
      return fields;
    }),
    rawAppFields: Ember.computed("appData.contactData.extraFromElements", "mappings.[]", "appData.questions", function () {
      var fields = [{
        id: "i",
        label: this.get("i18n").t("emarsys.fieldIndependent"),
        description: this.get("i18n").t("emarsys.fieldIndependentDescription")
      }];

      if (this.get("appData.questions")) {
        this.get("appData.questions").forEach(function (e, i) {
          if (e.type !== "freetext") {
            fields.addObject({
              id: "q" + i,
              field: e
            });
          }
        });
      }

      if (this.get("appData.contactData.extraFromElements")) {
        this.get("appData.contactData.extraFromElements").forEach(function (e, i) {
          if (e.type !== "freetext") {
            fields.addObject({
              id: "c" + i,
              field: e
            });
          }
        });
      }
      return fields;
    })

  });
});