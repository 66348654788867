define('hc2-ui/components/apps/contest/participation-judging', ['exports', 'hc2-ui/components/apps/contest/participation-administration'], function (exports, _participationAdministration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _participationAdministration.default.extend({

    mergedProperties: ['actions'],
    renderPreview: false,

    availableRanks: Ember.computed("appData.juryWinnerCount", "usedRanks.[]", function () {
      this.set("renderPreview", false);
      var totalRanks = this.get("appData.juryWinnerCount");
      var options = [];
      for (var i = 1; i <= totalRanks; i++) {
        var used = this.get("usedRanks").indexOf(i) >= 0;
        options.pushObject({ id: i, label: i, disabled: used });
      }
      return options;
    }),
    usedRanks: [],
    allWinnersSet: Ember.computed("appData.juryWinnerCount", "usedRanks.[]", function () {
      for (var i = 1; i <= this.get("appData.juryWinnerCount"); i++) {
        if (this.get("usedRanks").indexOf(i) < 0) {
          return false;
        }
      }
      return true;
    }),

    didInitAttrs: function didInitAttrs() {
      this.initRanks();
    },
    initRanks: function initRanks() {
      this.get("usedRanks").clear();
      var winners = this.get('appData.juryWinners');
      for (var i = 0; i < winners.length; i++) {
        if (winners[i].value > 0) {
          this.get("usedRanks").pushObject(i + 1);
        }
      }
    },

    actions: {
      saveApp: function saveApp() {
        var that = this;
        if (this.get('appData').get('hasDirtyAttributes')) {
          this.get('appData').save().then(function () {
            that.initRanks();
          });
        }
      },
      saveParticipation: function saveParticipation(participation) {
        this.saveParticipation(participation);
      },
      clearRank: function clearRank(participation) {
        var rank = participation.get("juryRank");
        this.get("usedRanks").removeObject(rank);
        participation.set("juryRank", undefined);
        var winners = this.get("appData.juryWinners");
        Ember.set(winners.objectAt(rank - 1), 'value', 0);
        this.saveParticipation(participation);
      },
      selectRank: function selectRank(participation) {
        var rank = participation.changedAttributes().juryRank;
        this.get("usedRanks").pushObject(rank[1]);
        var winners = this.get("appData.juryWinners");
        Ember.set(winners.objectAt(rank[1] - 1), 'value', participation.get("id"));
        // unset old rank if was set
        if (rank[0]) {
          this.get("usedRanks").removeObject(rank[0]);
          Ember.set(winners.objectAt(rank[0] - 1), 'value', 0);
        }
        this.saveParticipation(participation);
      },
      openPreview: function openPreview() {
        this.set("renderPreview", true);
        window.$('#' + this.elementId + ' #judging-preview').on('shown.bs.modal', function () {
          window.$(window).trigger('resize');
        });

        window.$('#' + this.elementId + ' #judging-preview').modal();
      },
      publishJudging: function publishJudging(value) {
        this.set('appData.judgingDone', value);
        this.get('appData').save();
      }
    },
    saveParticipation: function saveParticipation(participation) {
      if (participation.get('hasDirtyAttributes')) {
        participation.save();
      }
    }
  });
});