define("hc2-ui/templates/components/games/video-game", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 4
              },
              "end": {
                "line": 18,
                "column": 4
              }
            },
            "moduleName": "hc2-ui/templates/components/games/video-game.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "game-overlay");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "message");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "replay");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3, "class", "btn btn-default");
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "glyphicon glyphicon-refresh");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "proceed");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3, "class", "btn btn-primary");
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "glyphicon glyphicon-arrow-right");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [5, 1]);
            var element2 = dom.childAt(element0, [7, 1]);
            var morphs = new Array(5);
            morphs[0] = dom.createMorphAt(element0, 1, 1);
            morphs[1] = dom.createAttrMorph(element1, 'onclick');
            morphs[2] = dom.createMorphAt(element1, 1, 1);
            morphs[3] = dom.createAttrMorph(element2, 'onclick');
            morphs[4] = dom.createMorphAt(element2, 1, 1);
            return morphs;
          },
          statements: [["inline", "apps/game-results", [], ["gameResults", ["subexpr", "@mut", [["get", "gameResults", ["loc", [null, [7, 40], [7, 51]]], 0, 0, 0, 0]], [], [], 0, 0], "appData", ["subexpr", "@mut", [["get", "appData", ["loc", [null, [7, 60], [7, 67]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [7, 8], [7, 69]]], 0, 0], ["attribute", "onclick", ["subexpr", "action", ["repeat"], [], ["loc", [null, [null, null], [11, 66]]], 0, 0], 0, 0, 0, 0], ["inline", "ta", ["game.replay"], [], ["loc", [null, [11, 110], [11, 130]]], 0, 0], ["attribute", "onclick", ["subexpr", "action", ["proceed"], [], ["loc", [null, [null, null], [14, 67]]], 0, 0], 0, 0, 0, 0], ["inline", "ta", ["game.proceed"], [], ["loc", [null, [14, 115], [14, 136]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 20,
              "column": 0
            }
          },
          "moduleName": "hc2-ui/templates/components/games/video-game.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "video-game-ratio-wrapper");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("iframe");
          dom.setAttribute(el2, "class", "video-game-frame");
          dom.setAttribute(el2, "width", "100%");
          dom.setAttribute(el2, "height", "100%");
          dom.setAttribute(el2, "frameborder", "0");
          dom.setAttribute(el2, "scrolling", "no");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [0]);
          var element4 = dom.childAt(element3, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element3, 'style');
          morphs[1] = dom.createAttrMorph(element4, 'src');
          morphs[2] = dom.createMorphAt(element3, 3, 3);
          return morphs;
        },
        statements: [["attribute", "style", ["concat", ["padding-top: ", ["get", "aspectRatioPadding", ["loc", [null, [2, 60], [2, 78]]], 0, 0, 0, 0], "%; /* 1:1 Aspect Ratio = 100% */"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["get", "selectedGame.url", ["loc", [null, [4, 19], [4, 35]]], 0, 0, 0, 0], "?appId=", ["get", "appId", ["loc", [null, [4, 46], [4, 51]]], 0, 0, 0, 0], "&v=2&editMode=", ["get", "editMode", ["loc", [null, [4, 69], [4, 77]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "showGameOverlay", ["loc", [null, [5, 10], [5, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [5, 4], [18, 11]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 20,
            "column": 7
          }
        },
        "moduleName": "hc2-ui/templates/components/games/video-game.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "selectedGame.url", ["loc", [null, [1, 6], [1, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [20, 7]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});