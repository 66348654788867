define("hc2-ui/components/apps/app-overview", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['col-lg-4 col-sm-6 col-xs-12'],
        viewAppDetails: "viewAppDetails",
        actions: {
            viewAppDetails: function viewAppDetails(app) {
                this.get('router').transitionTo('backend.apps.details', app.appType || app.get("appType"), app.id || app.get("id"));
            }
        }
    });
});