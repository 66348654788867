define("hc2-ui/components/apps/direct-link/editor-form", ["exports", "hc2-ui/lib/make/init"], function (exports, _init) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    appData: {},
    editorResources: {},

    appStates: {
      showWelcomePage: false
    },
    didRender: function didRender() {
      _init.default.material(this.elementId);
    },
    save: "save",
    actions: {
      save: function save() {
        this.sendAction('save', this.get('appData'));
      }
    }
  });
});