define('hc2-ui/services/config', ['exports', 'hc2-ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    store: Ember.inject.service(),

    platform: null, // will be set by application route

    getPlatform: function getPlatform() {
      return this.get("platform");
    },

    /**
     * Returns the Ember cli Config ENV from environment.js
     * @returns {config|*}
     */
    getENV: function getENV() {
      return _environment.default;
    },

    /**
     * Return user specific settings configured via data attributes on the rootElement
     * @param settingName
     * @returns {*}
     */
    settings: function settings() {
      var rootEl = window.$(this.getENV().APP.rootElement);
      var settings = {};
      if (rootEl) {
        settings = rootEl.data();
      }
      settings = Object.assign(settings, this.getUrlDataParameters());

      if (!settings.target) {
        settings.target = this.get("platform.appId") ? '/app/view/' + this.get("platform.appType") + '/' + this.get("platform.appId") : "/backend/apps/mine";
        var parameterAppendChar = '?';
        var urlParameters = this.getUrlParameters();
        if (urlParameters.hasOwnProperty("d")) {
          settings.target += parameterAppendChar + "d";
          parameterAppendChar = '&';
        }
        if (urlParameters.hasOwnProperty("door")) {
          settings.target += parameterAppendChar + "door=" + urlParameters["door"];
        }
      }
      if (!settings.platform) {
        settings.platform = this.get("platform.id");
      }
      return settings;
    },
    setting: function setting(settingName) {
      return this.settings()[settingName];
    },
    getUrlDataParameters: function getUrlDataParameters() {
      var params = this.getUrlParameters();
      var dataParams = {};
      for (var key in params) {
        if (key.startsWith("data-")) {
          dataParams[key.substring(5).toLowerCase()] = params[key];
        }
      }
      return dataParams;
    },
    getUrlParameters: function getUrlParameters() {
      var sPageURL = decodeURIComponent(window.location.search.substring(1)),
          sURLVariables = sPageURL.split('&'),
          sParameterName = void 0,
          i = void 0;
      var params = {};

      for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        params[sParameterName[0]] = sParameterName[1];
      }
      return params;
    }
  });
});