define('hc2-ui/services/api', ['exports', 'hc2-ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    apiHost: _environment.default.oauth.host,
    session: Ember.inject.service(),

    request: function request(uri) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      data.access_token = this.get("session.data.authenticated.access_token");
      return Ember.$.get(this.get("apiHost") + uri, data);
    },
    requestJSON: function requestJSON(uri) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      data.access_token = this.get("session.data.authenticated.access_token");
      return Ember.$.getJSON(this.get("apiHost") + uri, data);
    },
    post: function post(uri) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (this.get("session.data.authenticated.access_token")) {
        data.access_token = this.get("session.data.authenticated.access_token");
      }
      return Ember.$.post(this.get("apiHost") + uri, data);
    },
    getApiUrl: function getApiUrl(uri) {
      return this.get("apiHost") + uri + "?access_token=" + this.get("session.data.authenticated.access_token");
    },
    checkUrlExists: function checkUrlExists(url) {
      var _this = this;

      return new Promise(function (resolve) {
        $.ajax({
          type: 'HEAD',
          url: url,
          headers: {
            "Cache-Control": "no-cache",
            "Authorization": "Bearer " + _this.get("session.data.authenticated.access_token")
          }
        }).done(function (data) {
          resolve(true);
        }).fail(function () {
          resolve(false);
        });
      });
    },
    waitForFile: function waitForFile(url) {
      var _this2 = this;

      var trys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      var max = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1800;
      // default max is 30 minutes
      return new Promise(function (resolve, reject) {
        if (_this2.get("abortWaitForFile") === url) {
          _this2.set("abortWaitForFile", false);
          return reject(false);
        }
        _this2.checkUrlExists(url).then(function (exists) {
          if (!exists) {

            if (trys > max) {
              reject(false);
            } else {
              Ember.run.later(function () {
                console.log("Waiting for file " + url + " [" + trys + "]");
                resolve(_this2.waitForFile(url, trys + 1, max));
              }, 1000);
            }
          } else {
            resolve(true);
          }
        });
      });
    },
    download: function download(url) {
      var andDelete = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      console.log("Downloading file " + url);
      var link = encodeURI(url) + "?access_token=" + this.get("session.data.authenticated.access_token") + (andDelete ? "&andDelete=true" : "");
      window.open(link, "_blank");
    },
    loadScript: function loadScript(url, callback) {
      var head = document.getElementsByTagName("head")[0];
      var script = document.createElement("script");
      script.src = url;

      // Attach handlers for all browsers
      var done = false;
      script.onload = script.onreadystatechange = function () {
        if (!done && (!this.readyState || this.readyState == "loaded" || this.readyState == "complete")) {
          done = true;

          // Continue your code
          callback && callback();

          // Handle memory leak in IE
          script.onload = script.onreadystatechange = null;
          head.removeChild(script);
        }
      };

      head.appendChild(script);
    }
  });
});