define("hc2-ui/components/apps/slot-machine/combination-stats", ["exports", "hc2-ui/lib/make/init", "hc2-ui/helpers/has-html-content"], function (exports, _init, _hasHtmlContent) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    showList: false,
    actions: {
      toggleShowList: function toggleShowList() {
        this.set('showList', !this.get('showList'));
      }
    },
    maxDisplay: 100,
    displayingCombinations: Ember.computed('comboAssignment.combinations', function () {
      var list = [];
      var max = this.get('maxDisplay');
      var max2 = this.get('comboAssignment.combinations.length');
      if (max2 < max) {
        max = max2;
      }
      for (var i = 0; i < max; i++) {
        list.push(this.get('comboAssignment.combinations').objectAt(i));
      }
      return list;
    })

  });
});