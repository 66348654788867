define('hc2-ui/components/controls/bs-touchspin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    _control: null,
    'on-change': null,
    value: null,
    classNames: ["bs-touchspin-wrapper"],

    touchspinOptions: { verticalbuttons: true },
    max: 100,
    min: 0,
    init: function init() {
      this._super.apply(this, arguments);
      this.min = 0;
    },


    valueChange: function () {
      this.sendAction('on-change', this.get('value'));
    }.observes('value'),

    updateMax: function () {
      this._control.trigger("touchspin.updatesettings", { max: this.get('max') });
    }.observes('max'),
    updateMin: function () {
      this._control.trigger("touchspin.updatesettings", { min: this.get('min') });
    }.observes('min'),

    didInsertElement: function didInsertElement() {
      var _this = this;

      var element = this.element.querySelector('input');
      var options = this.get("touchspinOptions");
      options.max = this.get("max");
      if (this.get("min")) {
        options.min = this.get("min");debugger;
      }
      var control = window.$(element).TouchSpin(options);
      var that = this;
      control.on('change', function () {
        that.set('value', that._control.val());
      });
      Ember.run.later(function () {
        _this._control = control;
      });
    }
  });
});