define("hc2-ui/components/ml/app-locale-selector", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    actions: {
      toggleLocale: function toggleLocale(locale, event) {
        var appLocales = this.get("app.locales");
        if (!appLocales) {
          appLocales = [];
        }
        if (event.currentTarget.checked) {
          appLocales.pushObject({ value: locale });
        } else {
          for (var i = 0; i < appLocales.length; i++) {
            if (appLocales[i].value === locale) {
              appLocales.removeObject(appLocales[i]);
            }
          }
        }

        var appLocale = this.get("i18n.appLocale");
        var defaultLocale = this.get("app.defaultLocale");
        for (var j = 0; j < appLocales.length; j++) {
          var foundAppLocale = false;
          var foundDefaultLocale = false;
          if (appLocales[j].value === appLocale) {
            foundAppLocale = true;
          }
          if (appLocales[j].value === defaultLocale) {
            foundDefaultLocale = true;
          }
          if (!foundAppLocale) {
            this.set("i18n.appLocale", appLocales[0] ? appLocales[0].value : "de");
          }
          if (!foundDefaultLocale) {
            this.set("app.defaultLocale", appLocales[0] ? appLocales[0].value : "de");
          }
        }
        if (appLocales.length === 0) {
          this.set("app.defaultLocale", "de");
          this.set("i18n.appLocale", "de");
        }

        this.set("app.locales", appLocales);
      }
    }
  });
});