define("hc2-ui/templates/components/ml/property-translation", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "hc2-ui/templates/components/ml/property-translation.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "input", [], ["value", ["subexpr", "mut", [["subexpr", "get", [["get", "property", ["loc", [null, [2, 26], [2, 34]]], 0, 0, 0, 0], ["get", "app.defaultLocaleParent", ["loc", [null, [2, 35], [2, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 21], [2, 59]]], 0, 0]], [], ["loc", [null, [2, 16], [2, 60]]], 0, 0], "placeholder", ["subexpr", "t", [["get", "label", ["loc", [null, [3, 25], [3, 30]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 22], [3, 31]]], 0, 0], "class", "form-control floating-label"], ["loc", [null, [2, 2], [4, 47]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 0
            },
            "end": {
              "line": 9,
              "column": 0
            }
          },
          "moduleName": "hc2-ui/templates/components/ml/property-translation.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(":");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", [["get", "label", ["loc", [null, [7, 12], [7, 17]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 8], [7, 19]]], 0, 0], ["inline", "controls/ck-editor", [], ["value", ["subexpr", "mut", [["subexpr", "get", [["get", "property", ["loc", [null, [8, 39], [8, 47]]], 0, 0, 0, 0], ["get", "app.defaultLocaleParent", ["loc", [null, [8, 48], [8, 71]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 34], [8, 72]]], 0, 0]], [], ["loc", [null, [8, 29], [8, 73]]], 0, 0]], ["loc", [null, [8, 2], [8, 75]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 0
            },
            "end": {
              "line": 17,
              "column": 0
            }
          },
          "moduleName": "hc2-ui/templates/components/ml/property-translation.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "class", "img-thumbnail");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element7, 'src');
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["attribute", "src", ["concat", [["subexpr", "get", [["get", "property", ["loc", [null, [12, 40], [12, 48]]], 0, 0, 0, 0], ["get", "app.defaultLocaleParent", ["loc", [null, [12, 49], [12, 72]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 34], [12, 74]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "controls/file-upload-button", [], ["uploadFinished", "setImageFromUpload", "appId", ["subexpr", "@mut", [["get", "app.id", ["loc", [null, [15, 10], [15, 16]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [13, 2], [16, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child3 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 12
                },
                "end": {
                  "line": 30,
                  "column": 12
                }
              },
              "moduleName": "hc2-ui/templates/components/ml/property-translation.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var element2 = dom.childAt(element1, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createAttrMorph(element1, 'class');
              morphs[1] = dom.createAttrMorph(element2, 'src');
              morphs[2] = dom.createAttrMorph(element2, 'title');
              morphs[3] = dom.createAttrMorph(element2, 'alt');
              morphs[4] = dom.createAttrMorph(element2, 'onclick');
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["locale ", ["subexpr", "if", [["subexpr", "is-eq", [["get", "locale.value", ["loc", [null, [26, 46], [26, 58]]], 0, 0, 0, 0], ["get", "selectedLocale", ["loc", [null, [26, 59], [26, 73]]], 0, 0, 0, 0]], [], ["loc", [null, [26, 39], [26, 74]]], 0, 0], "selected"], [], ["loc", [null, [26, 34], [26, 87]]], 0, 0], "\n                  ", ["subexpr", "if", [["subexpr", "get", [["get", "property", ["loc", [null, [27, 28], [27, 36]]], 0, 0, 0, 0], ["get", "locale.value", ["loc", [null, [27, 37], [27, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [27, 23], [27, 50]]], 0, 0], "translation-set"], [], ["loc", [null, [27, 18], [27, 70]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["subexpr", "flag", [["get", "locale.value", ["loc", [null, [28, 33], [28, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [28, 26], [28, 47]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "title", ["concat", [["subexpr", "t", [["subexpr", "concat", ["locales.", ["get", "locale.value", ["loc", [null, [28, 79], [28, 91]]], 0, 0, 0, 0], ".label"], [], ["loc", [null, [28, 60], [28, 101]]], 0, 0]], [], ["loc", [null, [28, 56], [28, 103]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "alt", ["concat", [["subexpr", "t", [["subexpr", "concat", ["locales.", ["get", "locale.value", ["loc", [null, [28, 133], [28, 145]]], 0, 0, 0, 0], ".label"], [], ["loc", [null, [28, 114], [28, 155]]], 0, 0]], [], ["loc", [null, [28, 110], [28, 157]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["selectLocale", ["get", "locale.value", ["loc", [null, [28, 191], [28, 203]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [28, 206]]], 0, 0], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 6
              },
              "end": {
                "line": 31,
                "column": 8
              }
            },
            "moduleName": "hc2-ui/templates/components/ml/property-translation.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "unless", [["subexpr", "is-eq", [["get", "locale.value", ["loc", [null, [25, 29], [25, 41]]], 0, 0, 0, 0], ["get", "app.defaultLocaleParent", ["loc", [null, [25, 42], [25, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [25, 22], [25, 66]]], 0, 0]], [], 0, null, ["loc", [null, [25, 12], [30, 23]]]]],
          locals: ["locale"],
          templates: [child0]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 36,
                  "column": 10
                },
                "end": {
                  "line": 40,
                  "column": 10
                }
              },
              "moduleName": "hc2-ui/templates/components/ml/property-translation.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "input", [], ["value", ["subexpr", "mut", [["subexpr", "get", [["get", "property", ["loc", [null, [37, 38], [37, 46]]], 0, 0, 0, 0], ["get", "selectedLocale", ["loc", [null, [37, 47], [37, 61]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 33], [37, 62]]], 0, 0]], [], ["loc", [null, [37, 28], [37, 63]]], 0, 0], "placeholder", ["subexpr", "t", [["subexpr", "concat", ["locales.", ["get", "selectedLocale", ["loc", [null, [38, 50], [38, 64]]], 0, 0, 0, 0], ".translation"], [], ["loc", [null, [38, 31], [38, 80]]], 0, 0]], [], ["loc", [null, [38, 28], [38, 81]]], 0, 0], "class", "form-control floating-label"], ["loc", [null, [37, 14], [39, 53]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 42,
                  "column": 10
                },
                "end": {
                  "line": 44,
                  "column": 10
                }
              },
              "moduleName": "hc2-ui/templates/components/ml/property-translation.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "controls/ck-editor", [], ["value", ["subexpr", "mut", [["subexpr", "get", [["get", "property", ["loc", [null, [43, 51], [43, 59]]], 0, 0, 0, 0], ["get", "selectedLocale", ["loc", [null, [43, 60], [43, 74]]], 0, 0, 0, 0]], [], ["loc", [null, [43, 46], [43, 75]]], 0, 0]], [], ["loc", [null, [43, 41], [43, 76]]], 0, 0]], ["loc", [null, [43, 14], [43, 78]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child2 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 46,
                  "column": 10
                },
                "end": {
                  "line": 52,
                  "column": 10
                }
              },
              "moduleName": "hc2-ui/templates/components/ml/property-translation.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1, "class", "img-thumbnail");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element0, 'src');
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["attribute", "src", ["concat", [["subexpr", "get", [["get", "property", ["loc", [null, [47, 52], [47, 60]]], 0, 0, 0, 0], ["get", "selectedLocale", ["loc", [null, [47, 61], [47, 75]]], 0, 0, 0, 0]], [], ["loc", [null, [47, 46], [47, 77]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "controls/file-upload-button", [], ["uploadFinished", "setImageFromUpload", "appId", ["subexpr", "@mut", [["get", "app.id", ["loc", [null, [50, 24], [50, 30]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [48, 14], [51, 16]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 35,
                "column": 6
              },
              "end": {
                "line": 53,
                "column": 6
              }
            },
            "moduleName": "hc2-ui/templates/components/ml/property-translation.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "is-eq", [["get", "type", ["loc", [null, [36, 23], [36, 27]]], 0, 0, 0, 0], "textfield"], [], ["loc", [null, [36, 16], [36, 40]]], 0, 0]], [], 0, null, ["loc", [null, [36, 10], [40, 17]]]], ["block", "if", [["subexpr", "is-eq", [["get", "type", ["loc", [null, [42, 23], [42, 27]]], 0, 0, 0, 0], "editor"], [], ["loc", [null, [42, 16], [42, 37]]], 0, 0]], [], 1, null, ["loc", [null, [42, 10], [44, 17]]]], ["block", "if", [["subexpr", "is-eq", [["get", "type", ["loc", [null, [46, 23], [46, 27]]], 0, 0, 0, 0], "image"], [], ["loc", [null, [46, 16], [46, 36]]], 0, 0]], [], 2, null, ["loc", [null, [46, 10], [52, 17]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 0
            },
            "end": {
              "line": 55,
              "column": 0
            }
          },
          "moduleName": "hc2-ui/templates/components/ml/property-translation.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "locales");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(element3, [1]);
          var element5 = dom.childAt(element4, [1]);
          var element6 = dom.childAt(fragment, [3]);
          var morphs = new Array(8);
          morphs[0] = dom.createAttrMorph(element4, 'class');
          morphs[1] = dom.createAttrMorph(element5, 'src');
          morphs[2] = dom.createAttrMorph(element5, 'title');
          morphs[3] = dom.createAttrMorph(element5, 'alt');
          morphs[4] = dom.createAttrMorph(element5, 'onclick');
          morphs[5] = dom.createMorphAt(element3, 3, 3);
          morphs[6] = dom.createAttrMorph(element6, 'class');
          morphs[7] = dom.createMorphAt(element6, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["locale ", ["subexpr", "unless", [["get", "selectedLocale", ["loc", [null, [20, 37], [20, 51]]], 0, 0, 0, 0], "selected"], [], ["loc", [null, [20, 28], [20, 64]]], 0, 0], "  translation-set"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["subexpr", "flag", [["get", "app.defaultLocaleParent", ["loc", [null, [21, 31], [21, 54]]], 0, 0, 0, 0]], [], ["loc", [null, [21, 24], [21, 56]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "title", ["concat", [["subexpr", "t", [["subexpr", "concat", ["locales.", ["get", "app.defaultLocaleParent", ["loc", [null, [21, 88], [21, 111]]], 0, 0, 0, 0], ".label"], [], ["loc", [null, [21, 69], [21, 121]]], 0, 0]], [], ["loc", [null, [21, 65], [21, 123]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "alt", ["concat", [["subexpr", "t", [["subexpr", "concat", ["locales.", ["get", "app.defaultLocaleParent", ["loc", [null, [21, 153], [21, 176]]], 0, 0, 0, 0], ".label"], [], ["loc", [null, [21, 134], [21, 186]]], 0, 0]], [], ["loc", [null, [21, 130], [21, 188]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["selectLocale", null], [], ["loc", [null, [null, null], [21, 229]]], 0, 0], 0, 0, 0, 0], ["block", "each", [["get", "app.localesParent", ["loc", [null, [24, 14], [24, 31]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [24, 6], [31, 17]]]], ["attribute", "class", ["concat", ["translation-input translation-input-", ["get", "type", ["loc", [null, [34, 54], [34, 58]]], 0, 0, 0, 0], " ", ["subexpr", "if", [["get", "selectedLocale", ["loc", [null, [34, 66], [34, 80]]], 0, 0, 0, 0], "open"], [], ["loc", [null, [34, 61], [34, 89]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "selectedLocale", ["loc", [null, [35, 12], [35, 26]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [35, 6], [53, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 56,
            "column": 0
          }
        },
        "moduleName": "hc2-ui/templates/components/ml/property-translation.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "is-eq", [["get", "type", ["loc", [null, [1, 13], [1, 17]]], 0, 0, 0, 0], "textfield"], [], ["loc", [null, [1, 6], [1, 30]]], 0, 0]], [], 0, null, ["loc", [null, [1, 0], [5, 7]]]], ["block", "if", [["subexpr", "is-eq", [["get", "type", ["loc", [null, [6, 13], [6, 17]]], 0, 0, 0, 0], "editor"], [], ["loc", [null, [6, 6], [6, 27]]], 0, 0]], [], 1, null, ["loc", [null, [6, 0], [9, 7]]]], ["block", "if", [["subexpr", "is-eq", [["get", "type", ["loc", [null, [11, 13], [11, 17]]], 0, 0, 0, 0], "image"], [], ["loc", [null, [11, 6], [11, 26]]], 0, 0]], [], 2, null, ["loc", [null, [11, 0], [17, 7]]]], ["block", "if", [["subexpr", "is-gt", [["get", "app.localesParent.length", ["loc", [null, [18, 13], [18, 37]]], 0, 0, 0, 0], 1], [], ["loc", [null, [18, 6], [18, 40]]], 0, 0]], [], 3, null, ["loc", [null, [18, 0], [55, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }());
});