define("hc2-ui/helpers/video-img-url", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({

    vimeoImages: {},
    config: Ember.inject.service(),

    compute: function compute(params /*, hash*/) {
      var _this = this;

      var videoUrl = params[0];
      if (videoUrl.indexOf("www.youtube.com") > -1) {
        var videoId = videoUrl.match(/.*\/(.*)\?.*/)[1];
        return "https://img.youtube.com/vi/" + videoId + "/sddefault.jpg";
      }
      if (videoUrl.indexOf("player.vimeo.com") > -1) {
        var _videoId = videoUrl.match(/.*\/(.*)/)[1];

        var imageUrl = this.get("vimeoImages." + _videoId);
        if (imageUrl) {
          return imageUrl;
        } else {
          var uploadHost = this.get('config').getENV().oauth.host;
          var apiUrl = uploadHost + "/upload/vimeoThumbnail/" + _videoId;
          Ember.$.get(apiUrl).then(function (data) {
            if (data[0].thumbnail_large) {
              _this.set("vimeoImages." + _videoId, data[0].thumbnail_large);
              _this.recompute();
            }
          });
        }
      }
    }
  });
});