define('hc2-ui/services/defaults', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    store: Ember.inject.service(),

    /**
     * Gets the default model for the corresponding app editors
     * @returns editor model
     */
    getDefaultAppEditorModel: function getDefaultAppEditorModel(appType) {

      var model = { appType: appType };
      switch (appType) {
        case "packYourBag":
          model.editorResources = this.getAppEditorResources(appType);
          model.appData = this.getAppModel(appType, {
            appType: 'packYourBag',
            showTitle: false,
            questions: [{ label: 'brush', imageUri: '/assets/images/samples/brush-2675544_640.png' }, { label: 'imp', imageUri: '/assets/images/samples/imp-3128700_640.png' }, { label: 'book', imageUri: '/assets/images/samples/life-2643192_640.png' }, { label: 'spectacles', imageUri: '/assets/images/samples/spectacles-3356460_1920.png' }],
            backgroundImageUri: '/assets/images/samples/luggage_bg.png',
            minItems: 1,
            maxItems: 3
          });
          break;
        case "christmasCalendar":
          model.editorResources = this.getAppEditorResources(appType);
          model.appData = this.getAppModel(appType, {
            appType: 'christmasCalendar',
            showTitle: false,
            startDate: new Date('12/01/' + new Date().getUTCFullYear()),
            endDate: new Date('12/24/' + new Date().getUTCFullYear() + ' 23:59:59'),
            backgroundImageUri: model.editorResources.availableBackgrounds[0].id
          });
          break;
        case "calendar":
          model.editorResources = this.getAppEditorResources(appType);
          model.appData = this.getAppModel(appType, {
            appType: 'calendar',
            showTitle: false,
            subAppCount: 10,
            autoCenterModals: true,
            dragEnabled: true
          });
          break;
        case "winWheel":
          model.appData = this.getAppModel(appType, {
            appType: 'winWheel',
            showTitle: false,
            drawText: true,
            showBorder: true,
            winWheelImageUrl: "/assets/images/winWheel/angle_guide.png",
            prizes: [{ text: "Preis 1", propability: 1, maxCount: 0 }, { text: "Preis 2", propability: 1, maxCount: 0 }, { text: "Preis 3", propability: 1, maxCount: 0 }, { text: "Preis 4", propability: 1, maxCount: 0 }]
          });
          break;
        case "slotMachine":
          model.appData = this.getAppModel(appType, {
            appType: 'slotMachine',
            showTitle: false,
            pairs: [{}, {}, {}],
            prizes: [{ text: "Preis 1", propability: 1, maxCount: 0 }, { text: "Preis 2", propability: 1, maxCount: 0 }, { text: "Preis 3", propability: 1, maxCount: 0 }, { text: "Preis 4", propability: 1, maxCount: 0 }]
          });
          break;
        case "codePromotion":
          model.appData = this.getAppModel(appType, {
            appType: 'codePromotion',
            showTitle: false,
            drawText: true,
            showBorder: true,
            winWheelImageUrl: "/assets/images/winWheel/angle_guide.png",
            prizes: [{ text: "Preis 1", propability: 1, maxCount: 0 }, { text: "Preis 2", propability: 1, maxCount: 0 }, { text: "Preis 3", propability: 1, maxCount: 0 }, { text: "Preis 4", propability: 1, maxCount: 0 }]
          });
          break;
        case "scratchCard":
          model.appData = this.getAppModel(appType, {
            appType: 'scratchCard',
            showTitle: false,
            scratchCardImageUrl: '/assets/images/samples/scratch-card-bg.png',
            scratchCardBackgroundImageUrl: '/assets/images/samples/lottery-loose-1422098_1280.jpg',
            prizes: [{ text: "Zahnbürste", propability: 1, maxCount: 0, image: '/assets/images/samples/brush-2675544_640.png' }, { text: "Puppe", propability: 1, maxCount: 0, image: '/assets/images/samples/imp-3128700_640.png' }]
          });
          break;
        case "contest":
          model.appData = this.getAppModel(appType, { appType: appType,
            acceptsPhotos: true,
            contactData: {
              salutationMandetory: true,
              nameMandetory: true,
              firstName: true,
              firstNameMandetory: true,
              lastName: true,
              lastNameMandetory: true,
              email: true,
              emailMandetory: true,
              extraFromElements: []
            },
            categories: []
          });
          break;
        case "bodyChallenge":
          model.appData = this.getAppModel(appType, {
            appType: appType,
            contactData: {
              "extraFromElements": [{
                "dontRemove": true,
                "mandetory": true,
                "answers": [],
                "label": "Alter",
                "type": "textfield"
              }, { "dontRemove": true,
                "mandetory": true, "answers": [], "label": "Hobbies", "type": "textfield" }, {
                "dontRemove": true,
                "mandetory": true,
                "answers": [],
                "label": "Ich habe mich für die Bodychallenge angemeldet, weil ...",
                "type": "textarea"
              }, {
                "dontRemove": true,
                "mandetory": true,
                "answers": [],
                "label": "Die BodyChallenge werde ich gewinnen, weil ...",
                "type": "textarea"
              }], "lastName": true, "name": false, "firstName": true, email: true
            },
            categories: []
          });
          break;
        case "test":
          model.appData = this.getAppModel(appType, {
            appType: 'test',
            questions: [{ type: "radio", mandetory: true, answers: [] }],
            results: [{ minPoints: 0 }],
            showHeader: false,
            showQuestionSteps: true,
            showSharingButtonOnResults: true
          });
          break;
        case "quiz":
          model.appData = this.getAppModel(appType, {
            appType: 'quiz',
            questions: [{ type: "radio", mandetory: true, answers: [] }],
            results: [],
            showHeader: false,
            showQuestionSteps: true,
            showSharingButtonOnResults: true
          });
          break;
        case "memory":
          model.appData = this.getAppModel(appType, {
            appType: 'memory',
            pairs: [{}, {}, {}, {}, {}, {}, {}, {}],
            showSharingButtonOnResults: true
          });
          break;
        case "pixelGraphic":
          model.appData = this.getAppModel(appType, {
            appType: 'pixelGraphic',
            pairs: [{}],
            showSharingButtonOnResults: true
          });
          break;
        case "game":
          model.appData = this.getAppModel(appType, {
            appType: 'game',
            config: { gameId: 1 },
            showSharingButtonOnResults: true
          });
          break;

        default:
          model.appData = this.getAppModel(appType, { appType: appType });
      }
      return model;
    },
    /**
     * Gets the default model for the corresponding app editors
     * @returns editor model
     */
    getAppEditorResources: function getAppEditorResources(appType) {

      switch (appType) {
        case "christmasCalendar":
          return {
            icon: 'wi wi-snowflake-cold',
            availableBackgrounds: Ember.A([
            /*{
                id: "/assets/uploads/platforms/gp/ONLINE-ADVENTSKALENDER-2015-stylisch-fin-810x540px3.jpg",
                text:"Online Adventskalender 2015"
            },*/
            {
              id: "/assets/christmas/background1.jpg",
              text: "Grün Hochkant"
            }, {
              id: "/assets/christmas/background1-2.jpg",
              text: "Grün Quer"
            }, {
              id: "/assets/christmas/background2.jpg",
              text: "Rot Hochkant"
            }, {
              id: "/assets/christmas/background2-2.jpg",
              text: "Rot Quer"
            }, {
              id: "/assets/christmas/background3.jpg",
              text: "Nacht Hochkant"
            }, {
              id: "/assets/christmas/background3-2.jpg",
              text: "Nacht Quer"
            }, {
              id: "/assets/christmas/background4.jpg",
              text: "Blau Hochkant"
            }, {
              id: "/assets/christmas/background4-2.jpg",
              text: "Blau Quer"
            }, {
              id: "/assets/christmas/background5.jpg",
              text: "Rot2 Hochkant"
            }, {
              id: "/assets/christmas/background5-2.jpg",
              text: "Rot2 Quer"
            }, {
              id: "/assets/christmas/background6.jpg",
              text: "Grau Hochkant"
            }, {
              id: "/assets/christmas/background6-2.jpg",
              text: "Grau Quer"
            }, {
              id: "/assets/christmas/background7.jpg",
              text: "Grün2 Hochkant"
            }, {
              id: "/assets/christmas/background7-2.jpg",
              text: "Grün2 Quer"
            }]) };
        case "calendar":
          return {
            availableBackgrounds: Ember.A()
          };
        case "packYourBag":
          return {
            availableBackgrounds: Ember.A()
          };
      }
    },
    /**
     * Creates a Datastore instance of an app initializes with the properties in initialData
     * @param appType
     * @param initialData
     * @returns {*}
     */
    getAppModel: function getAppModel(appType, initialData) {
      var model = this.get('store').createRecord("apps/" + appType, initialData);
      if (!model.get("startDate")) {
        model.set("startDate", new Date());
      }
      return model;
    }
  });
});