define('hc2-ui/adapters/authenticatedRestAdapter', ['exports', 'ember-data', 'hc2-ui/config/environment'], function (exports, _emberData, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTAdapter.extend({
        session: Ember.inject.service('session'),

        host: _environment.default.oauth.host,

        headers: Ember.computed('session.session.authenticated.access_token', function () {
            if (this.get('session.session.authenticated.access_token')) {
                return {
                    'Authorization': 'Bearer ' + this.get('session.session.authenticated.access_token')
                };
            } else {
                return {};
            }
        }),
        /**
         The ActiveModelAdapter overrides the `handleResponse` method
         to format errors passed to a DS.InvalidError for all
         422 Unprocessable Entity responses.
         A 422 HTTP response from the server generally implies that the request
         was well formed but the API was unable to process it because the
         content was not semantically correct or meaningful per the API.
         For more information on 422 HTTP Error code see 11.2 WebDAV RFC 4918
         https://tools.ietf.org/html/rfc4918#section-11.2
         @method ajaxError
         @param {Object} jqXHR
         @return error
         */
        handleResponse: function handleResponse(status, headers, payload) {
            if (this.isInvalid(status, headers, payload)) {
                // USING JSON API ERROR OBJECT FORMAT
                // http://emberjs.com/blog/2015/06/18/ember-data-1-13-released.html
                var errorsByField = [];
                if (payload.errors) {
                    for (var i = 0; i < payload.errors.length; i++) {
                        var error = payload.errors[i];
                        errorsByField.push({
                            source: { pointer: "data/attributes/" + error.field },
                            detail: error.message
                        });
                    }
                }
                return new _emberData.default.InvalidError(errorsByField);
            } else if (status === 401) {
                // unauthorized
                if (this.get('session.isAuthenticated')) {
                    this.get('session').invalidate();
                }
            } else if (status === 403) {
                alert("Forbidden!");
            } else {
                return this._super.apply(this, arguments);
            }
        }
    });
});