define("hc2-ui/components/apps/slot-machine/editor-form", ["exports", "hc2-ui/lib/make/init", "hc2-ui/helpers/has-html-content"], function (exports, _init, _hasHtmlContent) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    appData: {},
    editorResources: {},

    itemOptions: Ember.computed('appData.pairs.[]', function () {
      var _this = this;

      var items = Ember.A();
      var pairs = this.get('appData.pairs');
      if (pairs.length > 0) {
        for (var i = 0; i < pairs.length; i++) {
          items.pushObject({
            name: pairs[i].name,
            html: Ember.String.htmlSafe('' + '<div class="slot-combo-select-option">' + ' <img src="' + pairs[i].img + '"/>' + ' <span class="name">' + pairs[i].name + '</span>' + ' <span class="nr">' + (i + 1) + '</span>' + '</div>'),
            index: i,
            id: i
          });
        }
      } /*
        items.pushObject({
         name:'*',
         html:Ember.String.htmlSafe('' +
           '<div class="slot-combo-select-option slot-combo-select-option-any">' +
           ' <span class="name"><i class="fa fa-asterisk"/></span>' +
           ' <span class="nr">'+(this.get("i18n").t('any'))+'</span>' +
           '</div>'
         ),
         index:-1,
         id:-1
        });*/
      Ember.run.later(function () {
        _this.checkPrizes(items);
      });
      return items;
    }),
    slotCountArr: Ember.computed('appData.maxItems', function () {
      var count = parseInt(this.get('appData.maxItems'));
      return new Array(count).fill().map(function (v, i, arr) {
        return {
          index: i
        };
      });
    }),

    appStates: {
      showWelcomePage: false,
      selectedEditorPair: 0
    },
    selectedPair: Ember.computed.alias('appStates.selectedEditorPair'),
    editableSubAppLabels: ["appdata_participate_nav_label"],
    editableLabels: ["impressum", "overview", "sweepstake_participate", "appdata_participate_nav_label", "close", "forward", "back", "yes", "no", "save", "salutation_male", "salutation_female", "salutation_diverse", "sweepstake_contact_firstName", "sweepstake_contact_lastName", "sweepstake_contact_name", "sweepstake_contact_email", "sweepstake_contact_salutation", "sweepstake_contact_phone", "sweepstake_contact_mobile", "sweepstake_contact_street", "sweepstake_contact_streetNr", "sweepstake_contact_zip", "sweepstake_contact_city", "sweepstake_contact_country", "sweepstake_contact_doiLabel", "conditionsAgree_before", "conditions", "conditionsAgree_middle", "privacyPolicy", "conditionsAgree_after", "mandetory_field_hint_checkbox", "mandetory_field_hint_text", "mandetory_field_hint_radio", "mandetory_field_hint_hidden", "mandetory_field", "participation_error_invalidEmail_sweepstake_participation", "constraint_unique_uniqueId_sweepstake_participation", "mandetory_field_patternHint_text", "slotMachine_contactDataHeader", "winWheel_win", "slotMachine_stopNext", "start", "reset"],

    selectedPrize: Ember.computed.alias("appStates.selectedPrize"),
    selectedExtraFromElement: 0,
    showHeaderContentEditor: Ember.computed("appData.headerContent", function () {
      return (0, _hasHtmlContent.hasHtmlContent)(this.get("appData.headerContent"));
    }),

    propabilities: Ember.computed("appData.prizes.@each.propability", function () {
      var prizes = this.get("appData.prizes");
      var propabilities = [];
      var totalPropabilities = 0;
      prizes.forEach(function (prize) {
        totalPropabilities += parseInt(prize.propability);
      });
      prizes.forEach(function (prize, i) {
        var propability = 100 / totalPropabilities * parseInt(prize.propability);
        propabilities[i] = propability.toFixed(1);
      });
      return propabilities;
    }),

    save: "save",
    actions: {
      save: function save() {
        this.sendAction('save', this.get('appData'));
      },
      setHeaderImageFromUpload: function setHeaderImageFromUpload(url) {
        this.set('appData.headerImageUri', url);
      },
      setBackgroundFromUpload: function setBackgroundFromUpload(url) {
        this.set('appData.backgroundImageUri', url);
      },
      handleError: function handleError(err) {
        alert("Error:\n" + err);
      },
      selectParticipationPage: function selectParticipationPage() {
        this.set("appStates.status", "showForm");
      },
      selectPrizesPage: function selectPrizesPage() {
        if (this.get('selectedPrize') === this.get('appData.prizes.length')) {
          this.set("selectedPrize", 0);
        }
      },
      selectPair: function selectPair(index) {
        this.set("selectedPair", index);
      },
      addPair: function addPair() {
        this.get('appData.pairs').pushObject({});
        this.set("selectedPair", this.get('appData.pairs.length') - 1);
      },
      removePair: function removePair(index) {
        this.get('appData.pairs').removeAt(index);
        this.set("selectedPair", 0);
      },
      setPairImageFromUpload: function setPairImageFromUpload(url, pair) {
        Ember.set(pair, 'img', url);
      },
      selectPrize: function selectPrize(index) {
        this.set("selectedPrize", index);
      },
      addPrize: function addPrize() {
        this.get('appData.prizes').pushObject({ text: "Preis " + (this.get('appData.prizes.length') + 1), propability: 1, maxCount: 0 });
        this.set("selectedPrize", this.get('appData.prizes.length') - 1);
      },
      removePrize: function removePrize(prizeIndex) {
        this.get('appData.prizes').removeAt(prizeIndex);
        this.set("selectedExtraFromElement", 0);
      },
      addAnswer: function addAnswer(question) {
        question.answers.pushObject(Object.create({ label: "", correct: false }));
        this.get("appData").notifyPropertyChange("contactData");
      },
      refreshContactData: function refreshContactData() {
        this.get("appData").notifyPropertyChange("contactData");
      },

      removeAnswer: function removeAnswer(prize, answerIndex) {
        prize.answers.removeAt(answerIndex);
      },
      toggleWelcomePageIfActive: function toggleWelcomePageIfActive(display) {
        this.switchWelcomePageIfActive(display);
      }
    },

    observeWelcomePage: function () {
      this.switchWelcomePageIfActive(true);
    }.observes("appData.welcomePage"),
    switchWelcomePageIfActive: function switchWelcomePageIfActive(display) {
      this.set("appStates.showWelcomePage", this.get("appData.welcomePage") && display);
    },

    setupModel: function () {
      this.set("selectedPrize", 0);
      this.set("selectedPair", 0);

      if (!this.get('appData.minItems')) {
        this.set('appData.minItems', 1);
      }
      if (!this.get('appData.maxItems')) {
        this.set('appData.maxItems', 3);
      }

      if (!this.get('appData.questions')) {
        this.set('appData.questions', Ember.A());
      }
      if (!this.get('appData.pairs')) {
        this.set('appData.pairs', Ember.A());
      }

      if (!this.get('appData.contactData')) {
        this.set('appData.contactData', {
          salutationMandetory: true,
          nameMandetory: true,
          firstName: true,
          firstNameMandetory: true,
          lastName: true,
          lastNameMandetory: true,
          email: true,
          emailMandetory: true,
          extraFromElements: []
        });
      }
    }.on("init"),
    didInsertElement: function didInsertElement() {
      //this.$(".sweepstake-editor-form > .nav li:nth-child(4) a").click();
    },
    didRender: function didRender() {
      _init.default.material(this.elementId);
      this.initDrag();
    },
    checkPrizes: function checkPrizes(items) {
      var prizes = this.get("appData.prizes");
      var slotCount = this.get('appData.maxItems');
      prizes.forEach(function (prize) {
        for (var i = 0; i < slotCount; i++) {
          var itemIndex = Ember.get(prize, "s" + i);
          if (itemIndex < 0 || itemIndex > items.length - 1) {
            Ember.set(prize, "s" + i, 0);
          }
        }
      });
    },
    initDrag: function initDrag() {
      this.initDragObject('slot-wrapper');
      this.initDragObject('slot-trigger');
      this.initDragObject('slot-button');
      this.initDragObject('btn-start');
      this.initDragObject('btn-stop');
      this.initDragObject('btn-reset');
    },
    initDragObject: function initDragObject(itemClass) {
      var containment = ".background-container";
      var classPath = ".slot-machine-container";
      window.$('.' + itemClass).draggable({
        handle: ".drag-handle",
        containment: containment,
        stop: this.onDragStop(containment, classPath, itemClass, "-position")
      });
      window.$('.' + itemClass).resizable({
        stop: this.onResizeStop(containment, classPath, itemClass, "-size")
      });
    },
    onResizeStop: function onResizeStop(containment, classPath, itemClass, generatedId) {
      var _this2 = this;

      return function (event) {
        var itemIdRegex = new RegExp(itemClass + "-\\d*");
        var idClass = event.target.className.match(itemIdRegex);
        if (idClass) {
          idClass = idClass[0];
        }
        var item = event.target;
        var itemJQ = $(event.target);
        var width = itemJQ.width();
        var height = itemJQ.height();
        var container = window.$(containment);
        var containerWidth = container.outerWidth();
        var containerHeight = container.outerHeight();

        var widthPercent = Math.round(width * 100 / containerWidth * 100) / 100;
        var heightPercent = Math.round(height * 100 / containerHeight * 100) / 100;

        var customStyles = _this2.get("appData.customStyles") || "";

        var styleTemplate = "\n/*generated" + generatedId + "*/" + classPath + " ." + itemClass;
        if (idClass) {
          styleTemplate += "." + idClass;
        }

        // add door style
        var styleRegExp = new RegExp(styleTemplate.replace(/\./g, "\\.").replace(/\//g, "\\/").replace(/\*/g, "\\*") + " \{.*\}", "g");
        customStyles = customStyles.replace(styleRegExp, "");
        var newStyle = styleTemplate + " { width:" + widthPercent + "%; height:" + heightPercent + "%;}";
        customStyles += newStyle;

        _this2.set("appData.customStyles", customStyles);

        // adjust setting
        Ember.run.later(function () {
          window.$(item).css("width", "");
          window.$(item).css("height", "");
          window.$(item).css("position", "");
        });
      };
    },
    onDragStop: function onDragStop(containment, classPath, itemClass, generateId) {
      var _this3 = this;

      return function (event, ui) {
        var itemIdRegex = new RegExp(itemClass + "-\\d*");
        var idClass = event.target.className.match(itemIdRegex);
        if (idClass) {
          idClass = idClass[0];
        }
        var item = event.target;
        var top = item.offsetTop;
        var left = item.offsetLeft;
        var container = window.$(containment);
        var containerWidth = container.outerWidth();
        var containerHeight = container.outerHeight();

        var leftPercent = Math.round(left * 100 / containerWidth * 100) / 100;
        var topPercent = Math.round(top * 100 / containerHeight * 100) / 100;

        console.log(leftPercent);
        console.log(topPercent);

        var customStyles = _this3.get("appData.customStyles") || "";

        var styleTemplate = "\n/*generated" + generateId + "*/" + classPath + " ." + itemClass;
        if (idClass) {
          styleTemplate += "." + idClass;
        }
        // add style
        var styleRegExp = new RegExp(styleTemplate.replace(/\./g, "\\.").replace(/\//g, "\\/").replace(/\*/g, "\\*") + " \{.*\}", "g");
        customStyles = customStyles.replace(styleRegExp, "");
        var newDoorStyle = styleTemplate + " { left:" + leftPercent + "%; top:" + topPercent + "%; position:absolute;}";
        customStyles += newDoorStyle;

        _this3.set("appData.customStyles", customStyles);

        // adjust door setting
        Ember.run.later(function () {
          window.$(item).css("top", "");
          window.$(item).css("left", "");
          window.$(item).css("position", "");
        });
      };
    },

    styleProperties: ['switch_slotMachine_hideTriggerArm', 'switch_slotMachine_hideSingleButtons', 'switch_slotMachine_hideStopButton', 'switch_slotMachine_hideResetButton', 'switch_slotMachine_hideStartButton', 'switch_slotMachine_buttonsTransparent', 'color_slotMachine_buttons', 'color_slotMachine_buttonsActive', 'color_slotMachine_buttonsActiveHover']

  });
});