define('hc2-ui/controllers/backend/apps/allplatforms', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['page', 'size', 'sort', 'order', 'search', 'appTypes'],
    page: 1,
    size: 12,
    sort: 'id',
    order: 'desc',
    search: null,
    appTypes: "",

    actions: {
      performSearch: function performSearch(searchString) {
        if (!searchString) {
          searchString = "";
        }
        this.set("page", 1);
        this.set("search", searchString);
        return false;
      }
    },

    showPagination: Ember.computed("size", "totalElements", "search", function () {
      return this.get("size") < this.get("totalElements") || this.get("search");
    })
  });
});