define("hc2-ui/components/login-form-user", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    api: Ember.inject.service(),
    config: Ember.inject.service(),

    actions: {
      findUser: function findUser() {
        var _this = this;

        if (this.get("identification")) {
          this.get("api").requestJSON("/user/findUser/" + this.get('identification')).then(function (result) {
            var canLoginPlatform = result.additionalPlatformIds.contains(parseInt(_this.get("config.platform.id")));
            if (result.platformId == _this.get("config.platform.id") || result.isAdmin || canLoginPlatform) {
              _this.get('router').transitionTo('login-user', result.username);
            } else {
              window.location.href = result.platformUrl + '/login-user/' + result.username;
            }
          }).fail(function (reason) {
            _this.set('errorMessage', reason.error);
          });
        }
      }
    },
    didInsertElement: function didInsertElement() {
      window.$.material.init();
      window.$('input').focus();
    }
  });
});