define('hc2-ui/models/apps/christmas-calendar', ['exports', 'ember-data', 'hc2-ui/models/apps/app'], function (exports, _emberData, _app) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _app.default.extend({
    backgroundImageUri: _emberData.default.attr("string"),
    enableSnow: _emberData.default.attr("boolean"),
    subAppMails: _emberData.default.attr("boolean"),

    allowSubAppSelection: Ember.computed("subAppTypes.[]", function () {
      return this.get("subAppTypes.length") > 1;
    }),
    subAppTypes: _emberData.default.attr('csv', { defaultValue: function defaultValue() {
        return [{ value: 'sweepstake' }];
      } }),

    toEarlyText: _emberData.default.attr('string'),
    toLateText: _emberData.default.attr('string'),
    autoCenterModals: _emberData.default.attr('boolean'),
    portraitModeEnabled: _emberData.default.attr('boolean'),
    backgroundImagePortraitUri: _emberData.default.attr("string")
  });
});