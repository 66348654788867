define('hc2-ui/models/contest-participation', ['exports', 'ember-data', 'hc2-ui/models/participation'], function (exports, _emberData, _participation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _participation.default.extend({
    mediaType: _emberData.default.attr("string"),
    mediaStyles: _emberData.default.attr("string"),
    title: _emberData.default.attr("string"),
    description: _emberData.default.attr("string"),
    category: _emberData.default.attr("string"),
    username: _emberData.default.attr("string"),
    email: _emberData.default.attr("string"),
    rating: _emberData.default.attr("number", { defaultValue: 0 }),
    juryRating: _emberData.default.attr("number", { defaultValue: 0 }),
    juryNote: _emberData.default.attr("string"),
    juryRank: _emberData.default.attr("number"),
    views: _emberData.default.attr("number", { defaultValue: 0 }),
    app: _emberData.default.belongsTo('apps/contest'),
    active: _emberData.default.attr('boolean'),
    publicFormData: _emberData.default.attr(),
    extraMedia: _emberData.default.attr(),
    highlightIndex: _emberData.default.attr('number')
  });
});