define("hc2-ui/templates/components/apps/sub-app-type-selector", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 7,
              "column": 0
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/sub-app-type-selector.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2, "type", "checkbox");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          if (this.cachedFragment) {
            dom.repairClonedNode(element1, [], true);
          }
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element1, 'checked');
          morphs[1] = dom.createAttrMorph(element1, 'onclick');
          morphs[2] = dom.createMorphAt(element0, 3, 3);
          return morphs;
        },
        statements: [["attribute", "checked", ["subexpr", "contains-obj-with-prop", [["get", "app.subAppTypes", ["loc", [null, [4, 58], [4, 73]]], 0, 0, 0, 0], "value", ["get", "appType", ["loc", [null, [4, 82], [4, 89]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [4, 91]]], 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["toggleAppType", ["get", "appType", ["loc", [null, [4, 125], [4, 132]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [4, 135]]], 0, 0], 0, 0, 0, 0], ["inline", "t", [["subexpr", "concat", ["app.", ["get", "appType", ["loc", [null, [5, 21], [5, 28]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 6], [5, 29]]], 0, 0]], [], ["loc", [null, [5, 2], [5, 31]]], 0, 0]],
        locals: ["appType"],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 0
          }
        },
        "moduleName": "hc2-ui/templates/components/apps/sub-app-type-selector.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "sub-app-type-selector");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        return morphs;
      },
      statements: [["block", "each", [["get", "appTypes", ["loc", [null, [2, 8], [2, 16]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 0], [7, 9]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});