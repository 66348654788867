define('hc2-ui/components/register-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),
    config: Ember.inject.service(),

    passwordConfirm: "",
    passwordsDoNotMatch: Ember.computed("newUser.password", "passwordConfirm", function () {
      return this.get("passwordConfirm") !== this.get("newUser.password") && this.get('newUser.password');
    }),

    newUser: null,
    setupUser: function () {
      this.set("newUser", this.get('store').createRecord("user"));
      this.set("newUser.platform", this.get('config.platform'));
    }.on("init"),

    actions: {
      register: function register() {
        var _this = this;

        var that = this;
        if (!this.get('passwordsDoNotMatch')) {
          that.get('newUser').save().then(function (user) {
            _this.get("router").transitionTo("login-user", user.get("username"), { queryParams: { newUser: true } });
          });
        }
      }
    }
  });
});