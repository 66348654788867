define('hc2-ui/models/apps/slot-machine', ['exports', 'ember-data', 'hc2-ui/models/apps/app'], function (exports, _emberData, _app) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _app.default.extend({
        noForm: _emberData.default.attr('boolean'),
        prizes: _emberData.default.attr(),
        pairs: _emberData.default.attr(),
        questions: _emberData.default.attr(),
        contactData: _emberData.default.attr(),
        contactFormText: _emberData.default.attr('string'),
        headerImageUri: _emberData.default.attr("string"),
        backgroundImageUri: _emberData.default.attr("string"),
        thanksText: _emberData.default.attr("string"),
        resultMails: _emberData.default.attr('boolean'),
        allowMultipleParticipations: _emberData.default.attr('boolean', { defaultValue: false }),
        headerContent: _emberData.default.attr('string'),
        minItems: _emberData.default.attr('number', { defaultValue: 1 }), // slots stoppped to display form
        maxItems: _emberData.default.attr('number', { defaultValue: 3 }) // slotCount
    });
});