define('hc2-ui/utils/intl/missing-message', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    exports.default = function (key) /*, locales, options*/{
        return 'mt.' + key;
    };
});