define("hc2-ui/helpers/replace", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var regex = new RegExp(params[1], "g");
    return params[0].replace(regex, params[2]);
  });
});